import { useDispatch } from "app/hooks";
import { createTeamAsync, patchTeamAsync } from "features/users/slice";
import { ITeamRequest } from "packages/shared/validate/types/team";
import { Checkbox, Input, Select, Option, Typography } from "@material-tailwind/react";
import { FormError } from "common/components/FormError";
import { IGenericDialogWrapperProps } from "common/components/GenericDialog";
import { KnisperAges } from "packages/shared/entity/audioconversion";
import { DialogForm } from "common/components/DialogForm";

export const TeamsForm = ({data, setDialogOpen}:IGenericDialogWrapperProps<ITeamRequest>) => {

    const dispatch = useDispatch();

    const enableArchived = data === undefined || data?.members === 0;

    return (
        <DialogForm
            data={data}
            onCreate={(data, setValidationErrors) => {
                dispatch(createTeamAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => { 
                        setValidationErrors(error);
                    });
            }} 
            onUpdate={(data, setValidationErrors) => {
                dispatch(patchTeamAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => {
                        setValidationErrors(error);
                    });
            }}
        >
        {(register, setValue, errors) => {

            const { onChange, ...knisperAge } = register("knisperAge", {
                valueAsNumber: true,
            });

            return (
                <>
                    <Input
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="name"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="name"
                        crossOrigin={undefined}
                        {...register("name")} 
                    />
                    <FormError error={errors.name} />
                    
                    <Select 
                        label="knisper age"
                        value={data?.knisperAge.toString() ?? ""}
                        onPointerEnterCapture={() => { } }
                        onPointerLeaveCapture={() => { } }
                        placeholder={undefined}
                        {...knisperAge}
                        onChange={(event) => {
                            setValue(knisperAge.name, parseInt(event!) as KnisperAges);
                        }}
                    >
                        <Option value="30">30</Option>
                        <Option value="40">40</Option>
                        <Option value="50">50</Option>
                        <Option value="60">60</Option>
                        <Option value="70">70</Option>
                    </Select>
                    <FormError error={errors.knisperAge} />

                    <Input
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="separation"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="separation"
                        crossOrigin={undefined}
                        {...register("knisperSeparation", {
                            valueAsNumber: true
                        })} 
                    />
                    <FormError error={errors.knisperSeparation} />

                    <Checkbox
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="remember"
                        disabled={!enableArchived}
                        label={<Typography
                                variant="small"
                                color="gray"
                                className="flex items-center font-normal" 
                                placeholder={undefined} 
                                onPointerEnterCapture={() => {}} 
                                onPointerLeaveCapture={() => {}}
                            >
                            Archived
                            </Typography>
                        }
                        crossOrigin={undefined}
                        autoComplete="true"
                        {...register('archived')} 
                    />

                    <FormError error={errors.root} />
                </>
            )
        }}
        </DialogForm>
    );
};
