import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import { useDispatch } from 'app/hooks';
import { signInAsync } from 'features/users/authenticate/slice';
import { Typography, Checkbox, Button, Input, IconButton } from '@material-tailwind/react';
import { useState, MouseEvent } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { IAuthenticateRequest } from 'packages/shared/validate/request/users/authentication/signin';
import { FormErrors } from 'common/components/FormError';
import { useTranslation } from 'react-i18next';

export const SignInForm = () => {

    const { register, handleSubmit, reset, setError, formState: { errors } } = useForm<IAuthenticateRequest>({});

	const { t } = useTranslation();

    const dispatch = useDispatch();

	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);

	const onSubmit:SubmitHandler<IAuthenticateRequest> = (data, event) => {
		event?.preventDefault();

		const requestData:IAuthenticateRequest = {
			...data, 
			...{
				hasCookies: navigator.cookieEnabled
			}
		};

		dispatch(signInAsync(requestData))
			.unwrap()
			.then((_: any) => {
				reset();

				navigate("/");
			})
			.catch(error => {

				setError("root", {
					type: "api",
					message: "Invalid sign in"
				});
			});
	};

	const onError:SubmitErrorHandler<IAuthenticateRequest> = (errors, event) => {
		Object.entries(errors).map(([name, error]) => {

			if (error.type === 'required') {

				setError(name as keyof IAuthenticateRequest, {
					type: 'required',
					message: t('required', { ns: 'form', name })
				});

				return;
			}
		});
	};

	const handleViewPassword = (_:MouseEvent<HTMLButtonElement>) => {
		setShowPassword(!showPassword);
	};

    return (
		<form autoComplete="off" className="m-auto mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit(onSubmit, onError)}>
			<div className="mb-1 flex flex-col gap-4">
				<Typography variant="h6" color="blue-gray" className="-mb-3" placeholder={undefined} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
					E-mail
				</Typography>
				<div className="relative flex w-full max-w-[24rem]">
					<Input
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}} 
						id="email"
						error={!!errors.email}
						size="lg"
						className="focus:outline-none focus:ring-0"
						label="e-mail"
						crossOrigin={undefined}
						{...register("email")} 
					/>
				</div>

				<Typography variant="h6" color="blue-gray" className="-mb-3" placeholder={undefined} onPointerEnterCapture={()=>{}} onPointerLeaveCapture={()=>{}}>
					Password
				</Typography>
				<div className="relative flex w-full max-w-[24rem]">
					<Input
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}} 
						id="password"
						error={!!errors.password}
						type={showPassword ? "text" : "password"}
						size="lg"
						className="focus:outline-none focus:ring-0"
						label="password"
						crossOrigin={undefined}
						containerProps={{
							className: "min-w-0",
						}}

						{...register('password')} 
					/>
					<IconButton 
						onClick={handleViewPassword}
						className="!absolute right-1.5 top-1.5 rounded"
						size="sm" 
						placeholder={undefined} 
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}}
					>
					{showPassword 
						? <EyeSlashIcon className="stroke-white w-full h-full" />
						: <EyeIcon className="stroke-white w-full h-full" />
					}
    				</IconButton>
				</div>
			</div>
			<Checkbox
				onPointerEnterCapture={()=>{}} 
				onPointerLeaveCapture={()=>{}} 
				id="remember"
				label={<Typography
					variant="small"
					color="gray"
					className="flex items-center font-normal" 
					placeholder={undefined} 
					onPointerEnterCapture={() => {}} 
					onPointerLeaveCapture={() => {}}>
					Stay logged in
				</Typography>}
				crossOrigin={undefined}
				{...register('rememberMe')}
			/>
			
			<FormErrors errors={errors} />

			<Button className="mt-6" 
				type="submit" 
				fullWidth 
				placeholder={undefined} 
				onPointerEnterCapture={()=>{}} 
				onPointerLeaveCapture={()=>{}}
			>
				Sign in
			</Button>

		</form>
    );
};
