import { Hero, HeroTitle } from 'common/components/Hero';
import { ResetPasswordForm } from 'features/users/authenticate/components/ResetPasswordForm';
import { useTranslation } from 'react-i18next';

export const ResetScreen = () => {

    const { t } = useTranslation();

    return (
			<Hero>
				<HeroTitle>{t("Reset Password")}</HeroTitle>

                <ResetPasswordForm />
            </Hero>
        )
};

export default ResetScreen;
