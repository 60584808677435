import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, IconButtonProps } from "@material-tailwind/react";
import { useEffect, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

export interface IPageNavProps {
    itemsTotal: number;
    itemsPerPage: number;
    itemsDisplayed: number;
    pageNr?: number;
    onPageChange: (pageNr:number) => void;
};

export const PageNav = (props: IPageNavProps) => {

    const [activePage, setActivePage] = useState(props.pageNr ?? 1);

    const [pageRange, setPageRange] = useState<number[]>([]);

    const [pageCount, setPageCount] = useState(Math.ceil(props.itemsTotal/props.itemsPerPage));

    useEffect(() => {

        setPageCount(Math.ceil(props.itemsTotal/props.itemsPerPage));

    }, [props.itemsTotal]);

    useEffect(() => {

        if (pageCount === 0) {
            return;
        }

        const pageOffset = Math.max(1, Math.floor(props.itemsDisplayed/2));
        const pageMin = Math.max(1, activePage - pageOffset);
        const pageMax = Math.min(pageCount, activePage + pageOffset);

        const pageRange:number[] = [];
        for (let i = 0; i <= (pageMax - pageMin); i++) {
            pageRange.push(pageMin + i);
        };

        setPageRange(pageRange);

    }, [activePage, pageCount]);

    const setPage = (pageNr:number) => {

        setActivePage(pageNr);

        props.onPageChange(pageNr);
    };
 
    const onNext = (_:MouseEvent<HTMLButtonElement>) => {
        if (activePage >= pageCount) 
            return;

        setPage(activePage + 1);
    };
 
    const onPrev = (_:MouseEvent<HTMLButtonElement>) => {
        if (activePage === 1) 
            return;
 
        setPage(activePage - 1);
    };

    const getPageItemProps = (pageNr:number) => {
        return {
            variant: activePage === pageNr 
                ? "filled" 
                : "text",
            onClick: (_:MouseEvent<HTMLButtonElement>) => {
                if (pageNr === activePage) {
                    return;
                }

                setPage(pageNr);
            },
        } as Partial<IconButtonProps>
    };

    const { t } = useTranslation();

    return (
        pageCount > 1 && (
            <div className="flex items-center justify-center gap-4 p-2">
                <Button
                    variant="text"
                    className="flex items-center gap-2"
                    onClick={onPrev}
                    disabled={activePage === 1} 
                    placeholder={undefined} 
                    onPointerEnterCapture={()=>{}} 
                    onPointerLeaveCapture={()=>{}}
                >
                    <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                    {t("Prev")}
                </Button>
                <div className="flex items-center gap-2">
                    {pageRange.map(pageNr => {
                        return (
                            <IconButton 
                                placeholder={undefined} 
                                onPointerEnterCapture={()=>{}} 
                                onPointerLeaveCapture={()=>{}}
                                {...getPageItemProps(pageNr)}
                                key={`pagenav-${pageNr}`}
                            >
                                {pageNr}
                            </IconButton>
                        );
                    })}
                </div>
                <Button
                    variant="text"
                    className="flex items-center gap-2"
                    onClick={onNext}
                    disabled={activePage >= pageCount} 
                    placeholder={undefined} 
                    onPointerEnterCapture={()=>{}} 
                    onPointerLeaveCapture={()=>{}}
                >
                    {t("Next")}
                    <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </Button>
            </div>
        )
    );
}
