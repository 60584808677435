import { Suspense, useEffect, useState, MouseEvent } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { broadcastEvents } from 'common/event/broadcast';
import { setLocale } from 'app/slice';
import { useDispatch } from 'app/hooks';
import { HeaderNav } from 'app/components/HeaderNav';
import { updateBroadcast } from 'features/users/authenticate/slice';
import { StickyFooter } from 'common/components/StickyFooter';
import { BroadcastChannelNames } from 'store';
import { AdminTeams } from 'features/users/authenticate/components/admin/Teams';
import { Sidebar } from 'app/components/SideBar';
import { AdminUsers } from 'features/users/authenticate/components/admin/Users';
import { AdminRoles } from 'features/users/authenticate/components/admin/Roles';
import NotFound from 'common/components/NotFound';
import SignInScreen from 'common/screens/SignInScreen';
import ProtectedRoute from 'features/users/authenticate/components/ProtectedRoute';
import FilesScreen from 'features/files/components/FilesScreen';
import ResetScreen from 'common/screens/ResetScreen';
import ForgotScreen from 'common/screens/ForgotScreen';
import SettingsScreen from 'common/screens/SettingsScreen';

/**
 * @see https://www.material-tailwind.com/
 * @see https://tailwindui.com/
 * @see https://github.com/moment/luxon/blob/master/docs/formatting.md
 */

export const App = () => {

    const dispatch = useDispatch();

    const onBroadcastLanguage = (event:MessageEvent) => {    
        dispatch(setLocale(event.data));
    };

    const onBroadcastAuthenticate = (_:Event) => {
        dispatch(updateBroadcast());
    };

    useEffect(() => {
        broadcastEvents.addEventListener(BroadcastChannelNames.Locale, onBroadcastLanguage);
        broadcastEvents.addEventListener(BroadcastChannelNames.Authentication, onBroadcastAuthenticate);

        return () => {
            broadcastEvents.removeEventListener(BroadcastChannelNames.Locale, onBroadcastLanguage);
            broadcastEvents.removeEventListener(BroadcastChannelNames.Authentication, onBroadcastAuthenticate);
        }
    }, []);

    const [sidePanelVisible, setSidePanelVisible] = useState(false);

    const toggleSidePanel = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setSidePanelVisible(!sidePanelVisible);
    };

    return (
        <Router>
            <div className="flex min-h-screen min-w-screen flex-col">
                <header className="fixed flex flex-grow w-full z-[2]">
                    <HeaderNav toggleSidePanel={toggleSidePanel} />
                </header>
                <main className="flex grow min-h-full">
                    <div className={"flex flex-row justify-start w-[240px] bg-curious-blue-50 fixed min-h-full pt-[90px] z-[1] transition-all duration-500 ease-out " + (sidePanelVisible ? " left-0": " left-[-240px] lg:left-0")}>
                        <Sidebar />
                    </div>
                    <div className="flex flex-row grow justify-center pt-[90px]">
                        <Suspense>
                            <Routes>
                                <Route path="*" element={<NotFound />} />
                                <Route path="/signin" element={<SignInScreen />} />
                                <Route path="/settings" element={
                                    <ProtectedRoute
                                        deny={
                                            <NotFound />
                                        }
                                        allow={<SettingsScreen />}
                                    />
                                }
                                />
                                <Route path="/forgot" element={<ForgotScreen />} />
                                <Route path="/reset/:resetToken?" element={<ResetScreen />} />
                                <Route path="/admin" element={
                                    <ProtectedRoute 
                                        deny={
                                            <Navigate to="/signin" />
                                        }
                                        allow={'admin'} 
                                    />
                                }>
                                    <Route index element={<AdminTeams />} />
                                    <Route path="/admin/teams/:pageNr?" element={<AdminTeams />} />
                                    <Route path="/admin/users/:pageNr?" element={<AdminUsers />} />
                                    <Route path="/admin/roles/:pageNr?" element={<AdminRoles />} />
                                </Route>
                                <Route path="/files/:pageNr?" element={
                                    <ProtectedRoute 
                                        deny={
                                            <Navigate to="/signin" />
                                        }
                                    />
                                }>
                                    <Route index element={<FilesScreen />} />
                                </Route>
                                <Route path="/" element={
                                    <ProtectedRoute
                                        deny={<Navigate to="/signin" />} 
                                        allow={userRoles => 
                                            userRoles?.includes('admin')
                                                ? <Navigate to="/admin/teams" />
                                                : <Navigate to="/files" />
                                        }
                                    />
                                } />
                            </Routes>
                        </Suspense>
                    </div>
                </main>
                <StickyFooter />
            </div>
        </Router>
    );
};
