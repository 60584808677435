import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "app/hooks";
import { Hero, HeroTitle } from "common/components/Hero";
import { clearRoles, fetchAllRolesAsync, selectRolesCount, selectRolesLimitOffsetSort } from "features/users/slice";
import { createRef, useEffect } from "react";
import { SortableTableHead, TableHead } from "common/components/TableHead";
import { Button, Switch } from "@material-tailwind/react";
import { ArchiveBoxIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { DataTable, DataTableCell, DataTableCellTypography } from "common/components/DataTable";
import { toNumber } from "packages/shared/utility/convert";
import { useNavigate, useParams } from "react-router-dom";
import { PageNav } from "common/components/PageNav";
import { GenericDialog, IGenericDialogRef } from "common/components/GenericDialog";
import { IOrderBy } from "common/redux/orderby";
import { IRoleRequest } from "packages/shared/validate/types/role";
import { RolesForm } from "./RolesForm";
import { DateTime } from "luxon";
import { DateTimeLocalize } from "../DateTimeLocalize";
import { useTranslation } from "react-i18next";

export const AdminRoles = () => {

	const { pageNr:pageNrParam } = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [pageNr, setPageNr] = useState(toNumber(pageNrParam, 1));

    const limit = toNumber(import.meta.env.VITE_PAGENAV_ADMIN_ROLES_LIMIT);

    const [orderBy, setOrderBy] = useState<IOrderBy>({
        column: 'createdDate', 
        direction: 'desc'
    });

    const [includeArchived, setIncludeArchived] = useState(false);

	const roles = useSelector(state => selectRolesLimitOffsetSort(state, limit, (pageNr-1) * limit, orderBy, includeArchived));

    const rolesCount = useSelector(state => selectRolesCount(state, includeArchived));

    const dialogRef = createRef<IGenericDialogRef<IRoleRequest>>();

	useEffect(() => {

        const action = dispatch(fetchAllRolesAsync());

        return () => {
            action.abort();

            dispatch(clearRoles());
        }
    }, []);

    const onSort = (event: MouseEvent, column:string) => {

        event.preventDefault();

        setOrderBy({
            column,
            direction: orderBy.column !== column 
                ? 'desc'
                : orderBy.direction === 'desc'  
                ? 'asc'
                : 'desc'
        });
    };

    const onOpenDialog = (event:MouseEvent<HTMLButtonElement>, title:string, data?:IRoleRequest) => {
        event.preventDefault();

        dialogRef.current?.initialize(title, data);
    };

    const { t } = useTranslation();

    return (
        <Hero>
            <HeroTitle>Roles</HeroTitle>

            <GenericDialog
                ref={dialogRef}
            >
                {(setOpen, data) => <RolesForm data={data} setDialogOpen={setOpen} />}
            </GenericDialog>

            <div className="flex p-2">
                <div className="flex flex-row grow justify-start space-x-1 px-1">
                    {t("Manage Roles")}
                </div>
                <div className="flex flex-row justify-end space-x-2 px-2">
                    <Button
                        className="flex items-center gap-2"
                        onClick={event => onOpenDialog(
                            event, 
                            t("Create Role"),
                        )}
                        placeholder={undefined} 
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}}
                    >
                        <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                        {t("Create Role")}
                    </Button>
                </div>
            </div>

            <div className="flex items-center space-x-2 justify-end p-3">
                <div>{t("Show Archived")}</div>
                <Switch
                    ripple={false}
                    containerProps={{
                        className: "w-11 h-6",
                    }}
                    circleProps={{
                        className: "before:hidden left-0.5 border-none",
                    }} 
                    onPointerEnterCapture={undefined} 
                    onPointerLeaveCapture={undefined} 
                    crossOrigin={undefined}
                    defaultChecked={includeArchived}
                    onChange={e => {

                        navigate(`/admin/roles`); 

                        setIncludeArchived(e.currentTarget.checked);
                    }}
                />
            </div>

            <DataTable
                data={roles} 
                header={_ => (
                    <tr>
                        {/* Name */}
                        <SortableTableHead  
                            onClick={e => onSort(e, "name")}
                            orderByDirection={orderBy.column === "name" 
                                ? orderBy.direction 
                                : undefined
                            }
                        >
                            {t("Name")}
                        </SortableTableHead>

                        {/* Created Date */}
                        <SortableTableHead 
                            onClick={e => onSort(e, "createdDate")}
                            orderByDirection={orderBy.column === "createdDate" 
                                ? orderBy.direction 
                                : undefined
                            }
                        >
                            {t("Created Date")}
                        </SortableTableHead>

                        {/* Archived */}
                        <TableHead className="w-[32px]"></TableHead>
                        
                        {/* Button */}
                        <TableHead></TableHead>
                    </tr>
                )}
            >
                {records => (
                    <tbody>
                    {records.map((record, index) => (
                        <tr key={`admin-roles-row-${index}`} className={`${record.archived ? 'bg-curious-blue-100/50' : ''}`}>
                            <DataTableCellTypography>
                                {record.name}
                            </DataTableCellTypography>
                            <DataTableCellTypography>
                                <DateTimeLocalize 
                                    dateTime={DateTime.fromISO(record.createdDate)} 
                                    format={"dd-MM-yyyy HH:mm:ss"}
                                />                                    
                            </DataTableCellTypography>
                            <DataTableCell>
                                {record.archived && (
                                    <ArchiveBoxIcon className="w-[20px] h-[20px] -mx-2" />
                                )}
                            </DataTableCell>
                            <DataTableCell>
                                <Button
                                    className="flex items-center gap-2"
                                    onClick={event => onOpenDialog(
                                            event, 
                                            t("Update Role"), 
                                            record
                                        )
                                    }
                                    placeholder={undefined} 
                                    onPointerEnterCapture={()=>{}} 
                                    onPointerLeaveCapture={()=>{}}
                                >
                                    <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                                    {t("Edit")}
                                </Button>
                            </DataTableCell>
                        </tr>
                    ))}
                    </tbody>
                )}
            </DataTable>
            <PageNav 
                pageNr={pageNr}
                itemsPerPage={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_ROLES_LIMIT)} 
                itemsDisplayed={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_ROLES_RANGE)}
                itemsTotal={rolesCount}
                onPageChange={(pageNr) => {

                    navigate(`/admin/roles/${pageNr}`);

                    setPageNr(pageNr);
                }}
            />
        </Hero>
    );
};
