import { useEffect, useState, MouseEvent, createRef } from "react";
import { useDispatch, useSelector } from "app/hooks";
import { fetchAudioProjectsAsync, clearProjects, selectProjectsLimitOffset, IAudioFileUploadArgs, uploadAudioFileAsync, selectPrepareConversions, fetchAudioConversionsAsync, archiveAudioProjectAsync, patchAudioFileAsync, archiveAudioFileAsync, selectProjectsCount, patchAudioProjectAsync, addUploadError } from "features/files/slice";
import { Hero } from "common/components/Hero";
import { useNavigate, useParams } from "react-router-dom";
import { toNumber } from "packages/shared/utility/convert";
import { PageNav } from "common/components/PageNav";
import { AudioProjectTableRowComponent } from "./AudioProjectTableRow";
import { IAudioProject } from "packages/shared/entity/audioproject";
import { SortableTableHead, TableHead } from "common/components/TableHead";
import { shallowEqual } from "react-redux";
import { DataTable } from "common/components/DataTable";
import { IOrderBy } from "common/redux/orderby";
import { GenericDialog, IGenericDialogRef } from "common/components/GenericDialog";
import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { IAudioFileRequest } from "packages/shared/validate/request/files/audiofile";
import { AudioFileUploadForm } from "./forms/AudioFileUpload";
import { AudioProjectForm } from "./forms/AudioProjectForm";
import { ArchiveDialog } from "common/components/forms/ArchiveDialog";
import { useTranslation } from "react-i18next";
import { Switch } from "@material-tailwind/react";
import { FormErrors } from "common/components/FormError";

export const FilesScreen = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const authenticatedUser = useSelector(store => store.authenticate);

    const { pageNr:pageNrParam } = useParams();

    const [pageNr, setPageNr] = useState(toNumber(pageNrParam, 1));

    const limit = toNumber(import.meta.env.VITE_PAGENAV_PROJECTS_LIMIT);

    const offset = (pageNr-1) * limit;

    const [includeArchived, setIncludeArchived] = useState(false);

    const projects = useSelector(state => selectProjectsLimitOffset(state, limit, offset, includeArchived));

    const projectsCount = useSelector(state => selectProjectsCount(state, includeArchived));
    
    const prepareConversions = useSelector(state => selectPrepareConversions(state), shallowEqual);
    
    const uploadErrors = useSelector(state => state.files.uploadErrors);

    useEffect(() => {

        const fetchProjects = dispatch(fetchAudioProjectsAsync());

        return () => {

            fetchProjects.abort();

            dispatch(clearProjects());
        }
    }, [pageNr]);

    useEffect(() => {

        const conversionIds = prepareConversions
            .filter(p => projects.some(x => x.audioprojectId === p.audioprojectId))
            .map(c => c.audiofileId);

        const intervalId = window.setInterval(() => {
            
            if(conversionIds.length > 0) {
                dispatch(fetchAudioConversionsAsync(conversionIds));
            }
            
        }, toNumber(import.meta.env.VITE_POLL_CONVERSIONS_INTERVAL_MS));

        return () => {
            window.clearInterval(intervalId);
        }
    }, [prepareConversions, pageNr]);

    const onUpload = (args:IAudioFileUploadArgs) => {

        dispatch(uploadAudioFileAsync({...args}))
            .unwrap()
            .catch(error => { 
                dispatch(addUploadError(error));
            });
    }

    const [orderBy, setOrderBy] = useState<IOrderBy>({
        column: 'createdDate', 
        direction: 'desc'
    });

    const onSort = (event: MouseEvent, column:string) => {

        event.preventDefault();

        setOrderBy({
            column,
            direction: orderBy.column !== column 
                ? 'desc'
                : orderBy.direction === 'desc'  
                ? 'asc'
                : 'desc'
        });
    };

    const projectDialogRef = createRef<IGenericDialogRef<IAudioProject>>();

    const archiveProjectDialogRef = createRef<IGenericDialogRef<IAudioProject>>();

    const archiveFileDialogRef = createRef<IGenericDialogRef<IAudioFileRequest>>();

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        //useSensor(TouchSensor, {}),
    );

    const handleDragEnd = (event:DragEndEvent) => {
        const {active, over} = event;

        const fromAudioProjectId = active.data.current?.audioprojectId;
        const fromAudioFileId = active.data.current?.audiofileId;
        const toAudioProjectId = over?.data.current?.audioprojectId;

        if (fromAudioProjectId === undefined || fromAudioFileId === undefined || toAudioProjectId === undefined || fromAudioProjectId === toAudioProjectId) {
            return;
        }

        dispatch(patchAudioFileAsync({
            audiofileId: fromAudioFileId,
            audioprojectId: toAudioProjectId
        }));
    };

    const { t } = useTranslation();

    const isAdmin = useSelector(state => state.authenticate.roles?.includes(import.meta.env.VITE_ADMIN_ROLE));

    return (
        <>
            <Hero>
                <GenericDialog
                    ref={projectDialogRef}
                    size="sm"
                >
                    {(setOpen, data) => <AudioProjectForm 
                        data={data} 
                        setDialogOpen={setOpen} 
                    />}
                </GenericDialog>

                <GenericDialog
                    ref={archiveProjectDialogRef}
                    size="xs"
                >
                    {(setOpen, data) => 
                        <ArchiveDialog 
                            data={data} 
                            setDialogOpen={setOpen} 
                            registerId="audioprojectId"
                            onUpdate={(data) => {
                                dispatch(archiveAudioProjectAsync({audioprojectId: data.audioprojectId}));
                
                                setOpen(false);
                            }}
                        />
                    }
                </GenericDialog>

                <GenericDialog
                    ref={archiveFileDialogRef}
                    size="xs"
                >
                    {(setOpen, data) => 
                        <ArchiveDialog 
                            data={data} 
                            setDialogOpen={setOpen} 
                            registerId="audiofileId"
                            onUpdate={(data) => {

                                dispatch(archiveAudioFileAsync({
                                    audiofileId: data.audiofileId,
                                    audioprojectId: data.audioprojectId
                                }));

                                setOpen(false);
                            }}
                        />
                    }
                </GenericDialog>
                
                <AudioFileUploadForm 
                    projectDialogRef={projectDialogRef}
                />

                {uploadErrors.length > 0 && (
                    <FormErrors errors={uploadErrors} />
                )}

                {authenticatedUser.roles?.includes("admin") && (
                    <div className="flex items-center space-x-2 justify-end p-3">
                        <div>{t("Show Archived")}</div>
                        <Switch
                            ripple={false}
                            containerProps={{
                                className: "w-11 h-6",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none",
                            }} 
                            onPointerEnterCapture={undefined} 
                            onPointerLeaveCapture={undefined} 
                            crossOrigin={undefined}
                            defaultChecked={includeArchived}
                            onChange={e => {

                                navigate(`/files`); 

                                setIncludeArchived(e.currentTarget.checked);
                            }}
                        />
                    </div>
                )}
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <DataTable
                        data={projects}
                        fixed={true}
                        header={_ => (
                            <tr>
                                <SortableTableHead 
                                    className="min-w-[250px]"
                                    onClick={e => onSort(e, 'filenameOriginal')}
                                    orderByDirection={orderBy.column === 'filenameOriginal' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("File")}
                                </SortableTableHead>
                                {/* Archived */}
                                {isAdmin && (            
                                    <TableHead className="w-[32px]"></TableHead>
                                )}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'userName')}
                                    orderByDirection={orderBy.column === 'userName' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("User")}
                                </SortableTableHead>
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'createdDate')}
                                    orderByDirection={orderBy.column === 'createdDate' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("Date")}
                                </SortableTableHead>
                                <TableHead></TableHead>
                            </tr>
                        )}
                    >
                        {records => (
                            <>
                            {records.map((record, index) => 
                                <AudioProjectTableRowComponent 
                                    key={`audioprojectrow-${index}`} 
                                    audioprojectId={record.audioprojectId} 
                                    orderBy={orderBy}
                                    archiveFileRef={archiveFileDialogRef}
                                    includeArchived={includeArchived}
                                    onUpload={onUpload}
                                    onArchive={(event) => {
                                        event.preventDefault();
                                        
                                        if (isAdmin) {
                                            dispatch(patchAudioProjectAsync({
                                                audioprojectId: record.audioprojectId,
                                                archived: !record.archived
                                            }));

                                            return;
                                        }

                                        archiveProjectDialogRef.current?.initialize(t('Archive'), record);
                                    }}
                                    onEdit={(event) => {
                                        event.preventDefault();

                                        projectDialogRef.current?.initialize(t('Update Project'), record);
                                    }}
                                />
                            )}
                            </>
                        )}
                    </DataTable>
                </DndContext>
                <PageNav 
                    pageNr={pageNr}
                    itemsPerPage={toNumber(import.meta.env.VITE_PAGENAV_PROJECTS_LIMIT)} 
                    itemsDisplayed={toNumber(import.meta.env.VITE_PAGENAV_PROJECTS_RANGE)}
                    itemsTotal={projectsCount}
                    onPageChange={(pageNr) => {

                        navigate(`/files/${pageNr}`);

                        setPageNr(pageNr);
                    }}
                />
            </Hero>
        </>
    );
};

export default FilesScreen;
