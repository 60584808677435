
import { RefObject } from "react";
import { AudioFileDragAndDrop } from "../AudioFileDragAndDrop";
import { toNumber } from "packages/shared/utility/convert";
import { IGenericDialogRef } from "common/components/GenericDialog";
import { IAudioProject } from "packages/shared/entity/audioproject";

interface IAudioFileUploadForm {
    projectDialogRef: RefObject<IGenericDialogRef<IAudioProject>>
};

export const AudioFileUploadForm = (props:IAudioFileUploadForm) => 
    <AudioFileDragAndDrop 
        inputProps={{
            multiple: true,
        }} 
        accept={{
            "audio/wav": [".wav"],
            "audio/mp3": [".mp3"]
        }} 
        maxBytes={toNumber(import.meta.env.VITE_MAX_UPLOAD_BYTES)} 
        projectDialogRef={props.projectDialogRef}
    />
