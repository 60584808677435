import { Dispatch, ForwardedRef, MouseEvent, ReactElement, ReactNode, SetStateAction } from "react";
import { forwardRef,  useImperativeHandle, useState } from "react";
import { Dialog, DialogHeader, DialogBody, IconButton } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { size as dialogSize } from "@material-tailwind/react/types/components/dialog";

export interface IGenericDialogWrapperProps<T> {
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    data?: T
};

export interface IGenericDialogProps<T> {
    onCloseCallback?: (event:MouseEvent, data?:T) => void;
    children: (
        setOpen: Dispatch<SetStateAction<boolean>>,
        data?: T,
    ) => ReactElement<HTMLElement>;
    title?: string;
    ref?: ForwardedRef<IGenericDialogRef<T>>;
    size?: dialogSize
};

export interface IGenericDialogRef<T> {
    initialize: (title?:string, data?:T) => void;
};

const GenericDialogRender = <T = any>(
    props: IGenericDialogProps<T>, 
    ref: ForwardedRef<IGenericDialogRef<T>>
): ReactNode => {

    const [open, setOpen] = useState(false);

    const [title, setTitle] = useState<string|undefined>();

    const [data, setData] = useState<T>();

    useImperativeHandle(ref, () => {
        return {
            initialize
        }
    });

    const initialize = (title?:string, data?:T) => {

        setTitle(title);
        
        setData(data);

        setOpen(true);
    };

    return (
        <Dialog
            open={open}
            size={props.size ?? "md"}
            handler={() => setOpen(false)} 
            placeholder={undefined} 
            onPointerEnterCapture={undefined} 
            onPointerLeaveCapture={undefined}
        >
            <DialogHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className="flex flex-row grow justify-start space-x-1 px-1">
                    {title ?? ""}
				</div>
			    <div className="flex flex-row justify-end space-x-2 px-2">
                    <IconButton 
                        onClick={() => setOpen(false)}
                        size="sm"
                        placeholder={undefined} 
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}}
                    >
                        <XMarkIcon className="stroke-white stroke-2 w-full h-full" />
                    </IconButton>
                </div>
            </DialogHeader>
            <DialogBody placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {props.children(setOpen, data)}
            </DialogBody>
        </Dialog>
    );
};

export const GenericDialog = forwardRef(GenericDialogRender) as <T>(
    props: IGenericDialogProps<T>,
) => ReturnType<typeof GenericDialogRender<T>>;
