import { ReactElement, MouseEvent } from "react";
import { Button } from "@material-tailwind/react";
import { DefaultValues, FieldErrors, FieldValues, Path, SubmitHandler, UseFormRegister, UseFormSetValue, useForm } from "react-hook-form";
import { isValidateErrors } from "packages/shared/validate/types/validate";
import { useTranslation } from "react-i18next";

export interface IDialogFormProps<T extends FieldValues> {
    data?: T,
    buttonText?: string;
    buttonDisabled?: boolean;
    onCreate?: (
        data:T, 
        setValidationErrors:(errors:Error[]) => void
    ) => void,
    onUpdate?: (
        data:T, 
        setValidationErrors:(errors:Error[]) => void
    ) => void,
    children: (
        register:UseFormRegister<T>, 
        setValue: UseFormSetValue<T>,
        errors: FieldErrors<T>
    ) => ReactElement<HTMLElement>;
    onCancel?: (
        event: MouseEvent
    ) => void;
};

export const DialogForm = <T extends FieldValues>(props:IDialogFormProps<T>) => {

	const { t } = useTranslation();

    const isCreate = props.data === undefined;

    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm<T>({
        defaultValues: props.data as DefaultValues<T>
    });

    const onSubmit:SubmitHandler<T> = (data, event) => {
        
        event?.preventDefault();

        if (isCreate) {
            props.onCreate?.(data, setValidationErrors);
        }
        else {
            props.onUpdate?.(data, setValidationErrors);
        }
    };

    const setValidationErrors = (error:Error|Error[]) => {
        
        if (Array.isArray(error) && isValidateErrors(error)) {
            error.map(e => setError(e.name as Path<T>, {
                type: 'api',
                message: t('invalid', { ns: 'form', name: e.name })
            }));
        }

        let message = (Array.isArray(error) 
                ? error.shift()
                : error
            )?.message ?? t("Unknown");

        if (message?.toLowerCase()?.startsWith('duplicate key')) {
            message = t('duplicate', { ns: 'form' })
        }

        setError("root", {
            type: 'api',
            message, 
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-1 flex flex-col gap-6">
                {props.children(register, setValue, errors)}
            </div>
            <div className="flex space-x-2">      
                <Button 
                    className="" 
                    type="submit" 
                    placeholder={undefined} 
                    onPointerEnterCapture={()=>{}} 
                    onPointerLeaveCapture={()=>{}}
                    disabled={props.buttonDisabled}
                >
                    {props.buttonText ?? (isCreate ? t("Create"): t("Update"))}
                </Button>

                {!!props.onCancel && (
                    <Button 
                        className="" 
                        type="button"
                        onClick={props.onCancel} 
                        placeholder={undefined} 
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}}
                    >
                        {t("Cancel")}
                    </Button>
                )}
            </div>
        </form>
    );
};
