import typia from 'typia';
import { IsJwt } from "../../tags/jwt";
export interface IJwtToken {
    readonly token: string & IsJwt;
}
;
export const validateJwt = (input: any): typia.IValidation<IJwtToken> => {
    const errors = [] as any[];
    const __is = (input: any): input is IJwtToken => {
        return "object" === typeof input && null !== input && ("string" === typeof (input as any).token && /^([A-Za-z0-9\-_]+(?:\.|$)){3}$/.test((input as any).token));
    };
    if (false === __is(input)) {
        const $report = (typia.createValidate as any).report(errors);
        ((input: any, _path: string, _exceptionable: boolean = true): input is IJwtToken => {
            const $vo0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ["string" === typeof input.token && (/^([A-Za-z0-9\-_]+(?:\.|$)){3}$/.test(input.token) || $report(_exceptionable, {
                    path: _path + ".token",
                    expected: "string & IsJwt",
                    value: input.token
                })) || $report(_exceptionable, {
                    path: _path + ".token",
                    expected: "(string & IsJwt)",
                    value: input.token
                })].every((flag: boolean) => flag);
            return ("object" === typeof input && null !== input || $report(true, {
                path: _path + "",
                expected: "IJwtToken",
                value: input
            })) && $vo0(input, _path + "", true) || $report(true, {
                path: _path + "",
                expected: "IJwtToken",
                value: input
            });
        })(input, "$input", true);
    }
    const success = 0 === errors.length;
    return {
        success,
        errors,
        data: success ? input : undefined
    } as any;
};
export interface IJwtPayload {
    exp: number; // expires
    nbf: number; // not before
    iat: number; // issued at
}
;
export const validateJwtPayLoad = (input: any): typia.IValidation<IJwtPayload> => {
    const errors = [] as any[];
    const __is = (input: any): input is IJwtPayload => {
        return "object" === typeof input && null !== input && ("number" === typeof (input as any).exp && "number" === typeof (input as any).nbf && "number" === typeof (input as any).iat);
    };
    if (false === __is(input)) {
        const $report = (typia.createValidate as any).report(errors);
        ((input: any, _path: string, _exceptionable: boolean = true): input is IJwtPayload => {
            const $vo0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ["number" === typeof input.exp || $report(_exceptionable, {
                    path: _path + ".exp",
                    expected: "number",
                    value: input.exp
                }), "number" === typeof input.nbf || $report(_exceptionable, {
                    path: _path + ".nbf",
                    expected: "number",
                    value: input.nbf
                }), "number" === typeof input.iat || $report(_exceptionable, {
                    path: _path + ".iat",
                    expected: "number",
                    value: input.iat
                })].every((flag: boolean) => flag);
            return ("object" === typeof input && null !== input || $report(true, {
                path: _path + "",
                expected: "IJwtPayload",
                value: input
            })) && $vo0(input, _path + "", true) || $report(true, {
                path: _path + "",
                expected: "IJwtPayload",
                value: input
            });
        })(input, "$input", true);
    }
    const success = 0 === errors.length;
    return {
        success,
        errors,
        data: success ? input : undefined
    } as any;
};
