/**
 * Wrapper for common DOM events;
 * 
 * type NavigationTimingType = "back_forward" | "navigate" | "prerender" | "reload";
 * type DocumentVisibilityState = "hidden" | "visible";
 */
export const domEvents = new EventTarget();

(() => {

    const observer = new PerformanceObserver((list, _) => {

        const entry = list
            .getEntries()
            .find(e => e instanceof PerformanceNavigationTiming) as PerformanceNavigationTiming|undefined;

        if (entry?.type === undefined) {
            return;
        }

        domEvents.dispatchEvent(new Event(entry.type));
    });

    observer.observe({
        type: "navigation",
        buffered: false
    });

    document.addEventListener("visibilitychange", () => {
        domEvents.dispatchEvent(new Event(document.visibilityState));
    });

})();
