import { Button, Navbar, Typography } from '@material-tailwind/react';
import { KnisperLogo } from './Logo';
import { useState, MouseEvent } from 'react';

interface IHeaderNavProps {
	toggleSidePanel?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const HeaderNav = (props:IHeaderNavProps) => {
	
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Navbar 
			className="mx-auto h-max max-w-full rounded-none"
			placeholder={undefined} 
			onPointerEnterCapture={()=>{}} 
			onPointerLeaveCapture={()=>{}}
		>
			<div className="relative mx-auto flex items-center justify-between text-gray-900 gap-2">
				<Typography
					key="home"
					as="a"
					href="/"
					variant="small"
					color="gray"
					className="w-28"
					placeholder={undefined} 
					onPointerEnterCapture={()=>{}} 
					onPointerLeaveCapture={()=>{}}
				>
					<div className="w-40">
						<KnisperLogo />
					</div>
				</Typography>

				<Button 
					onClick={(e) => {

						setIsOpen(!isOpen);

						props.toggleSidePanel?.(e);
					}} 
					placeholder={undefined} 
					onPointerEnterCapture={undefined} 
					onPointerLeaveCapture={undefined}
					className={"flex flex-col justify-center items-center w-[40px] visible lg:hidden"}
				>
					    <span className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${isOpen 
							? 'rotate-45 translate-y-1' 
							: '-translate-y-0.5'
						}`}></span>
						
						<span className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${isOpen 
							? 'opacity-0' 
							: 'opacity-100'
						}`}></span>
    
						<span className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${isOpen 
							? '-rotate-45 -translate-y-1' 
							: 'translate-y-0.5'
						}`}></span>
   
				</Button>
			</div>
		</Navbar>
	)
};
