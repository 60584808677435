import { Typography } from "@material-tailwind/react";
import { FieldValues, Path } from "react-hook-form";
import { IGenericDialogWrapperProps } from "common/components/GenericDialog";
import { DialogForm, IDialogFormProps } from "common/components/DialogForm";
import { useTranslation } from "react-i18next";

export interface IArchiveDialogProps<T extends FieldValues> extends IGenericDialogWrapperProps<T> {
    registerId: Path<T>;
    onUpdate: IDialogFormProps<T>['onUpdate'];
};

export const ArchiveDialog = <T extends FieldValues,>(props : IArchiveDialogProps<T>) => {

    const { t } = useTranslation();

    return (
        <DialogForm
            data={props.data}
            buttonText={t("Confirm")}
            onUpdate={props.onUpdate}
            onCancel={(event) => {
                event.preventDefault();

                props.setDialogOpen(false);
            }}
        >
        {(register) => {

            return (
                <>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal opacity-70 truncate pb-2" 
                        placeholder={undefined} 
                        onPointerEnterCapture={undefined} 
                        onPointerLeaveCapture={undefined}
                    >
                        {t("Are you sure you want to archive this item? This can't be undone!")}
                    </Typography>
                    <input
                        id={props.registerId}
                        type="hidden"
                        {...register(props.registerId)} 
                    />
                </>
            )
        }}
        </DialogForm>
    )
};
