import { CaseReducer, PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { IAsyncState, PromiseState } from "common";
import { DateTime, Duration, DurationLike } from "luxon";
import { StoreState } from "store";
import { backendApiAuthorize } from "features/users/authenticate/backendapi";
import { IAudioFile } from "packages/shared/entity/audiofile";
import { IAudioFileRequest, IAudioFileUpload, isAudioFileRequest, isAudioFileUpload } from "packages/shared/validate/request/files/audiofile";
import { IAudioProject } from "packages/shared/entity/audioproject";
import { IAudioProjectRequest } from "packages/shared/validate/request/files/audioproject";
import { IAudioConversionRequest } from "packages/shared/validate/request/files/audioconversion";
import { isAxiosError } from "axios";
import { IUser } from "packages/shared/entity/user";
import { toNumber } from "packages/shared/utility/convert";
import { IOrderBy, orderByKey } from "common/redux/orderby";
import { countArchivedSelector, countHandle, limitSelector, offsetSelector } from "app/slice";

interface IDownloading {
	id: string;
	progress?: number;
};

interface IFilesInitialState extends IAsyncState {
	duration: string;
	projects: IAudioProject[];
	projectFiles: Record<
		IAudioProject['audioprojectId'],
		Array<IAudioFileUpload|IAudioFileRequest>
	>;
	activeProjectId: number;
	downloading: Array<IDownloading>;
	uploadErrors: Error[];
};

export const fetchDurationAsync = createAsyncThunk<
	DurationLike
>(
	"duration/fetch",
	async () => {

		const { data } = await backendApiAuthorize.get("/api/v1/audioconversions/duration");
		
		return data;
	}
);

export const fetchAudioProjectsAsync = createAsyncThunk<
	IAudioProject[]
>( 
    "audioprojects/fetch",
    async () => {

		const { data } = await backendApiAuthorize.get<IAudioProject[]>(`/api/v1/audioprojects`);
		
		return data;
    }
);

export const fetchAudioProjectByIdAsync = createAsyncThunk<
	IAudioProject, 
	Pick<IAudioProject, 'audioprojectId'>
>( 
    "audioprojects/fetch/audioprojectId",
    async (args) => {

		const { data } = await backendApiAuthorize.get<IAudioProject>(`/api/v1/audioprojects/${args.audioprojectId}`);

		return data;
    }
);

export const fetchAudioProjectByNameAsync = createAsyncThunk<
	IAudioProject, 
	Pick<IAudioProject, 'name'>
>( 
    "audioprojects/fetch/name",
    async (args) => {

		const { data } = await backendApiAuthorize.get<IAudioProject>(`/api/v1/audioprojects/${args.name}`);

		return data;
    }
);

export const fetchAudioProjectFilesAsync = createAsyncThunk<
	IAudioFileRequest[], 
	Pick<IAudioProject, 'audioprojectId'>
>( 
    "audiofiles/fetch",
    async (args) => {

		const { data } = await backendApiAuthorize.get<IAudioFileRequest[]>(`/api/v1/audioprojects/${args.audioprojectId}/files`);

		return data;
    }
);

export interface IAudioFileUploadArgs {
	uploadId: IAudioFileUpload['uploadId'];
	audioprojectId: IAudioProject['audioprojectId'];
	file: File;
	userId: IUser['userId'],
	userName: IUser['name'],
	batchId: string;
};

export const uploadAudioFileAsync = createAsyncThunk<
	IAudioFileRequest, 
	IAudioFileUploadArgs, 
	{ 
		state: StoreState 
	}
>(
    "audiofiles/upload",
    async ({uploadId, file, audioprojectId, userId, userName, batchId}, { dispatch, fulfillWithValue, rejectWithValue }) => {

		const uploadFile:IAudioFileUpload = {
			uploadId,
			filenameOriginal: file.name,
			size: file.size, 
			type: file.type, 
			createdDate: DateTime.now().toUTC().toISO(),
			modifiedDate: DateTime.fromMillis(file.lastModified).toUTC().toISO()!,
			progress: 0,
			loaded: 0,
			userId,
			userName
		};

		dispatch(slice.actions.addUploadFile({
			file: uploadFile,
			audioprojectId
		}));

        const formData = new FormData();
		formData.append("file", file, file.name);
		formData.append("lastModified", uploadFile.modifiedDate.toString());
		formData.append("projectId", String(audioprojectId));
		formData.append("batchId", batchId);

		const abortController = new AbortController();

		try {
			const { data } = await backendApiAuthorize.post<IAudioFileRequest>(
				"/api/v1/audiofiles",
				formData,
				{
					signal: abortController.signal,
					onUploadProgress: (event) => {

						if (abortController.signal.aborted) {
							abortController.signal.throwIfAborted();
						}

						dispatch(slice.actions.setUploadProgress({
							uploadId, 
							progress: Math.round((event.loaded/event.total!) * 100),
							loaded: event.loaded,
							audioprojectId
						}));
					},
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);

			return fulfillWithValue(data);
		}
		catch (error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
		}
		finally {
			dispatch(slice.actions.removeUploadFile({
				file:uploadFile,
				audioprojectId
			}));
		}
    }
);

export const createAudioProjectAsync = createAsyncThunk<
	IAudioProject, 
	IAudioProjectRequest
>(
	"audioprojects/create",
	async(args, {rejectWithValue, fulfillWithValue}) => {
        try{
			const response = await backendApiAuthorize.post<IAudioProject>(
				"/api/v1/audioprojects",
				args
			);

            return fulfillWithValue(response.data);
        }
		catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const archiveAudioProjectAsync = createAsyncThunk<
	IAudioProject['audioprojectId'], 
	Pick<IAudioProject, 'audioprojectId'>
>(
	"audioprojects/archive",
    async ({ audioprojectId }) => {
		await backendApiAuthorize.delete<IAudioProject>(
			`/api/v1/audioprojects/${audioprojectId}`
		);

		return audioprojectId;
	}
);

export const patchAudioProjectAsync = createAsyncThunk<
	IAudioProject, 
	Pick<IAudioProject, 'audioprojectId'> & Partial<IAudioProjectRequest>
>(
	"audioproject/patch",
    async (args, { fulfillWithValue, rejectWithValue }) => {

		const { audioprojectId, ...patch} = args;

		try {
			const { data } = await backendApiAuthorize.patch<IAudioProject>(
				`/api/v1/audioprojects/${audioprojectId}`, 
				patch
			);

			return fulfillWithValue(data);
		}
        catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
	}
);

interface IDownloadFile {
	fileName: string;
	objectUrl: string;
};

export const downloadFile = (response:IDownloadFile) => {
	const link = document.createElement('a');
	
	link.href = response.objectUrl;
	link.download = response.fileName;
	link.click();
};

export const archiveAudioFileAsync = createAsyncThunk<
	Pick<IAudioFile, 'audiofileId'|'audioprojectId'>,
	Pick<IAudioFile, 'audiofileId'|'audioprojectId'>
>(
	"audiofile/archive",
    async ({ audiofileId, audioprojectId }) => {
		await backendApiAuthorize.delete<IAudioProject>(
			`/api/v1/audiofiles/${audiofileId}`
		);

		return {
			audiofileId,
			audioprojectId
		};
	}
);

export const patchAudioFileAsync = createAsyncThunk<
	IAudioFileRequest,
	Partial<IAudioFile>
>(
	"audiofile/patch",
    async (args) => {

		const { audiofileId, ...patch} = args;

		const response = await backendApiAuthorize.patch<IAudioFileRequest>(
			`/api/v1/audiofiles/${audiofileId}`, 
			patch
		);

		return response.data;
	}
);

export const fetchAudioConversionsAsync = createAsyncThunk<
	IAudioConversionRequest[],
	number[]|undefined
>(
	"conversions/fetch",
	async (ids) => {

		const { data } = await backendApiAuthorize.get<IAudioConversionRequest[]>(`/api/v1/audioconversions?ids=${ids?.join(',')}`);

		return data;
	}
);

export const downloadAudioConversionAsync = createAsyncThunk<
	IDownloadFile,
	IAudioConversionRequest
>(
	"audioconversion/download",
	async (conversion, { dispatch, rejectWithValue }) => {

		const fileUri = conversion.resultUri;
		if (!fileUri) {
			throw rejectWithValue(`audioconverion does not have a "result uri"`);
		}

		const fileName = fileUri.substring(fileUri.lastIndexOf("/") + 1);

		dispatch(slice.actions.addDownloading({
			id: `conversion-${conversion.audioconversionId}`,
			progress: 0,
		}));

		const response = await backendApiAuthorize.get<Blob>(
			`/api/v1/audioconversions/${fileName}`,
			{
				responseType: 'blob',
				onDownloadProgress: (event) => {

					const progress = Math.max(0, Math.min((event.loaded/event.total!) * 100, 100));

					dispatch(slice.actions.updateDownloading({
						id: `conversion-${conversion.audioconversionId}`,
						progress,
					}));
				}
			}
		);

		dispatch(slice.actions.removeDownloading({
			id: `conversion-${conversion.audioconversionId}`
		}));

		return {
			fileName: response.headers['x-suggested-filename'],
			objectUrl: window.URL.createObjectURL(response.data),
		};
	}
);

export const downloadProjectConversionsAsync = createAsyncThunk<
	IDownloadFile, 
	Pick<IAudioProject, 'audioprojectId'>
>(
	"audioprojects/conversions",
    async ({ audioprojectId }, { dispatch }) => {

		dispatch(slice.actions.addDownloading({
			id: `project-${audioprojectId}`,
			progress: 0,
		}));

		const response = await backendApiAuthorize.get<Blob>(
			`/api/v1/audioprojects/${audioprojectId}/conversions`,
			{
				responseType: 'blob',
				onDownloadProgress: (event) => {
					
					const progress = Math.max(0, Math.min((event.loaded/event.total!) * 100, 100));

					dispatch(slice.actions.updateDownloading({
						id: `project-${audioprojectId}`,
						progress,
					}));
				}
			}
		);

		dispatch(slice.actions.removeDownloading({
			id: `project-${audioprojectId}`,
		}));

		return {
			fileName: response.headers['x-suggested-filename'],
			objectUrl: window.URL.createObjectURL(response.data),
		};
	}
);

const setActiveProjectIdCase: CaseReducer<IFilesInitialState, PayloadAction<number|undefined>> = (state, action) => {

	const activeProjectId = action.payload === undefined
		? state.projects
			.find(p => p.audioprojectId === state.activeProjectId && p.archived === false)
				?.audioprojectId 
				?? 0
		: action.payload;

	state.activeProjectId = activeProjectId;
};

const durationDefault = "00:00:00.000";

const defaultState: IFilesInitialState = {
	duration: durationDefault,
	projects:[],
	projectFiles: {},
	activeProjectId: 0,
	downloading: [],
	uploadErrors: []
};

const slice = createSlice({
	name: "files",
	initialState: defaultState,
	reducers: {
		setActiveProjectIdCase,
		addDownloading: (state, action:PayloadAction<IDownloading>) => {
			state.downloading.push(action.payload);
		},
		updateDownloading: (state, action:PayloadAction<IDownloading>) => {
			state.downloading = state.downloading.map(downloading => 
				downloading.id !== action.payload.id 
					? downloading
					: action.payload
				);

			state.downloading = state.downloading.filter(x => x !== action.payload);
		},
		removeDownloading: (state, action:PayloadAction<IDownloading>) => {
			state.downloading = state.downloading.filter(x => x.id !== action.payload.id);
		},
		addUploadFile: (state, action:PayloadAction<{file:IAudioFileUpload, audioprojectId:number}>) => {

			const audioprojectId = action.payload.audioprojectId;

			if (!Array.isArray(state.projectFiles[audioprojectId])) {
				state.projectFiles[audioprojectId] = [];
			}

			state.projectFiles[audioprojectId].push(action.payload.file);
		},
		removeUploadFile: (state, action:PayloadAction<{file:IAudioFileUpload, audioprojectId:number}>) => {

			const audioprojectId = action.payload.audioprojectId;

			const uploadId = action.payload.file.uploadId

			state.projectFiles[audioprojectId] = state.projectFiles[audioprojectId]?.filter(file => {
				if (!isAudioFileUpload(file)) {
					return true;
				}

				return file.uploadId !== uploadId;
			});
		},
		setUploadProgress: (state, action:PayloadAction<Pick<IAudioFileUpload, 'uploadId'|'progress'|'loaded'> & { audioprojectId:number}>) => {

			const audioprojectId = toNumber(action.payload.audioprojectId);

			state.projectFiles[audioprojectId] = state.projectFiles[audioprojectId]?.map(file => {
				if (!isAudioFileUpload(file)) {
					return file;
				}

				if (file.uploadId !== action.payload.uploadId) {
					return file;
				}

				return {
					...file,
					...{
						progress: action.payload.progress,
						loaded: action.payload.loaded
					}
				};
			});
		},
		addAudioConversion: (state, action:PayloadAction<IAudioConversionRequest>) => {
			
			const audioprojectId = action.payload.audioprojectId;

			if (!Array.isArray(state.projectFiles[audioprojectId])) {
				state.projectFiles[audioprojectId] = [];
			}

			state.projectFiles[audioprojectId] = state.projectFiles[audioprojectId]?.map(file => {
				if (!isAudioFileRequest(file)) {
					return file;
				}

				if (file.audiofileId !== action.payload.audiofileId) {
					return file;
				}

				return {
					...file,
					...{
						conversions: [
							//...[],
							...file.conversions,
							// append
							action.payload
						]  // @todo order? 
					}
				};
			});
		},
		updateAudioConversion: (state, action:PayloadAction<IAudioConversionRequest>) => {

			state.duration = Duration
				.fromISOTime(state.duration)
				.plus(
					Duration.fromISOTime(action.payload.duration)
				)
				.toISOTime() ?? durationDefault; 

			state.projectFiles[action.payload.audioprojectId] = state.projectFiles[action.payload.audioprojectId]?.map(file => {
				if (!isAudioFileRequest(file)) {
					return file;
				}
				
				if (file.audiofileId !== action.payload.audiofileId) {
					return file;
				}

				return {
					...file,
					...{
						conversions: [
							// remove prepare (if any)
							...file.conversions.filter(conversion => {
								conversion.audioconversionId !== action.payload.audioconversionId && conversion.knisperState === 'prepare'
							}),
							// append
							action.payload
						]
					}
				};
			});
		},
		clearProjects: (state) => {
			state.projects = [];

			state.activeProjectId = 0;
		},
		setActiveProjectId: (state, action:PayloadAction<number>) => {
			slice.caseReducers.setActiveProjectIdCase(state, {
				type: "activeproject/set",
				payload: action.payload
			});
		},
		clearUploadErrors: (state) => {
			state.uploadErrors = [];
		},
		addUploadError: (state, action:PayloadAction<Error>) => {
			state.uploadErrors.push(action.payload);
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDurationAsync.fulfilled, (state, action) => {
				state.duration = Duration.fromDurationLike(action.payload).toISOTime();
			});

        builder
            .addCase(fetchAudioProjectFilesAsync.pending, (state) => {
				state.promiseState = PromiseState.Pending;
            })
            .addCase(fetchAudioProjectFilesAsync.fulfilled, (state, action) => {
				state.promiseState = PromiseState.Fulfilled;

				const projectFiles = {...state.projectFiles};

				action.payload.forEach(audiofile => {

					const audioprojectId = audiofile.audioprojectId;

					if (!Array.isArray(projectFiles[audioprojectId])) {
						projectFiles[audioprojectId] = [];
					}

					const fileExists = projectFiles[audioprojectId].find(file => isAudioFileRequest(file) && file.audiofileId === audiofile.audiofileId) !== undefined;
					if (fileExists) {
						return;
					}

					projectFiles[audioprojectId].push(audiofile);
				});

				state.projectFiles = projectFiles;
            })
            .addCase(fetchAudioProjectFilesAsync.rejected, (state) => {
				state.promiseState = PromiseState.Rejected;
			});

		builder
			.addCase(fetchAudioProjectsAsync.fulfilled, (state, action) => {

				action.payload.forEach(audioproject => {

					if (state.projects.find(p => p.audioprojectId === audioproject.audioprojectId)) {
						return;
					}

					state.projects.push(audioproject);
				});

				slice.caseReducers.setActiveProjectIdCase(state, {
					type: "activeproject/fetch",
					payload: state.projects.find(p => p.archived === false && p.name.endsWith(DateTime.now().toFormat('dd-MM-yyyy')))?.audioprojectId
				});
			});

		builder
			.addCase(fetchAudioProjectByIdAsync.fulfilled, (state, action) => {

				if (!state.projects.find(p => p.audioprojectId === action.payload.audioprojectId)) {
					// prepend
					state.projects.unshift(action.payload);
				}

				slice.caseReducers.setActiveProjectIdCase(state, {
					type: "activeproject/fetchcreate",
					payload: undefined
				});
			});

		builder.addCase(uploadAudioFileAsync.fulfilled, (state, action) => {
			state.projectFiles[action.payload.audioprojectId].push(action.payload);
		});

		builder.addCase(createAudioProjectAsync.fulfilled, (state, action) => {

			state.projects.unshift(action.payload); 

			slice.caseReducers.setActiveProjectIdCase(state, {
				type: "activeproject/create",
				payload: undefined
			});
		});

		builder.addCase(archiveAudioProjectAsync.fulfilled, (state, action) => {
			
			state.projects = state.projects.filter(p => p.audioprojectId !== action.payload);
			
			slice.caseReducers.setActiveProjectIdCase(state, {
				type: "activeproject/archive",
				payload: undefined
			});
		});

		builder.addCase(archiveAudioFileAsync.fulfilled, (state, action) => {
			const audioprojectId = Number(action.payload.audioprojectId);

			state.projectFiles[audioprojectId] = state.projectFiles[audioprojectId].filter(f => 
				isAudioFileRequest(f) && f.audiofileId !== action.payload.audiofileId
			);
		});

		builder.addCase(fetchAudioConversionsAsync.fulfilled, (state, action) => {
			action.payload.map(conversion => {
				
				state.projectFiles[conversion.audioprojectId] = state.projectFiles[conversion.audioprojectId]?.map(file => {
					if (!isAudioFileRequest(file) || file.audiofileId !== conversion.audiofileId) {
						return file;
					}

					return {
						...file,
						...{
							conversions: [
								// remove prepare (if any)
								...file.conversions.filter(c => {
									c.audioconversionId !== conversion.audioconversionId
								}),
								// append
								conversion
							]
						}
					};
				});
			});
		});

		builder.addCase(patchAudioFileAsync.fulfilled, (state, action) => {

			const audioFile = action.payload;

			if (audioFile.audioprojectId === undefined) {
				return;
			}

			const currentFile = Object
				.values(state.projectFiles)
				.reduce((a, v) => a.concat(v), [])
				.filter(isAudioFileRequest)
				.find(f => f.audiofileId === audioFile.audiofileId);

			// remove
			state.projectFiles[currentFile?.audioprojectId!] = state.projectFiles[currentFile?.audioprojectId!]?.filter(file => {
				return isAudioFileRequest(file) && file.audiofileId !== audioFile.audiofileId;
			});

			// add
			if (!Array.isArray(state.projectFiles[audioFile.audioprojectId])) {
				state.projectFiles[audioFile.audioprojectId] = [audioFile];
			}
			else {
				state.projectFiles[audioFile.audioprojectId].push(audioFile);
			}
		});

		builder
			.addCase(patchAudioProjectAsync.fulfilled, (state, action) => {

				state.projects = state.projects.map(project => {
					if (project.audioprojectId !== action.payload.audioprojectId) {
						return project;
					}
					return action.payload;

				});

				slice.caseReducers.setActiveProjectIdCase(state, {
					type: "activeproject/patch",
					payload: undefined
				});
			});
	}
});

export const { 
	clearProjects,
	setActiveProjectId,
	clearUploadErrors,
	addUploadError,
} = slice.actions;

export const { reducer } = slice;

export const selectProjectFiles = createSelector(
	[
		(state: StoreState) => state.files.projectFiles,
		(state: StoreState, projectId: number) => projectId,
		(state: StoreState, projectId: number, orderBy:IOrderBy) => orderBy,
		(state: StoreState, projectId: number, orderBy:IOrderBy, includeArchived:boolean) => includeArchived
	],
	(projectFiles, projectId, orderBy, includeArchived) => {
		if (!Array.isArray(projectFiles[projectId])) {
			return [];
		}

		let files = projectFiles[projectId];

		if (!includeArchived) {
			
			files = [...files].filter(f => {
				if (isAudioFileRequest(f)) {
					return f.archived === false;
				}

				return true;
			});
		}

		return [...files].sort((current, next) => orderByKey(
			current, 
			next, 
			orderBy.column as keyof typeof current, 
			orderBy.direction === 'desc'
		));
	}
);

export const selectProjectsLimitOffset = createSelector(
    [
    	(state: StoreState) => state.files.projects,
        limitSelector,
    	offsetSelector,
		(state: StoreState, limit:number, offset:number, includeArchived:boolean) => includeArchived
    ],
    (projects, limit, offset, includeArchived) => {
        
		if (projects === undefined) {
			return [];
		}

		const length = projects.length;
		if (projects.length === 0) {
			return [];
		}

		const start = Math.min(length - 1, offset);
		const end = Math.min(length, offset + limit);

		if (!includeArchived) {
			projects = projects.filter(x => x.archived === false);
		}

		return projects
			.slice(start, end);
    }
);

export const selectProjectsCount = createSelector(
    [
        (state: StoreState) => state.files.projects,
        countArchivedSelector,
    ],
    countHandle
);

export const selectPrepareConversions = createSelector(
	[
		(state: StoreState) => state.files.projectFiles,
	],
	(projectFiles) => {

		return Object
			.entries(projectFiles)
			.reduce((a, [k, v]) => {
				if (!Array.isArray(v)) {
					return a;
				}
				const r = v.filter(isAudioFileRequest);
				if(!Array.isArray(r)) {
					return a;
				}
				return a.concat(r);
			}, 
			<IAudioFileRequest[]>[])
			.reduce((a, f) => 
				a.concat(f.conversions.filter(c => c.knisperState === 'prepare')), 
				<IAudioConversionRequest[]>[]);
	}
);

export const selectUploading = createSelector(
	[
		(state: StoreState) => state.files.projectFiles,
		(state: StoreState, projectId:number) => projectId,
	],
	(projectFiles, projectId) => {

		if (!projectFiles[projectId]) {
			return [];
		}

		return Object
			.entries(projectFiles[projectId])
			.reduce((a, [_, v]) => {
				if (!Array.isArray(v)) {
					return a;
				}
				const r = v.filter(isAudioFileUpload);
				if(!Array.isArray(r)) {
					return a;
				}
				return a.concat(r);
			}, 
			<IAudioFileUpload[]>[]);
	}
);
