import cookie from 'cookie';
import { CaseReducer, PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { broadcastEvents } from 'common/event/broadcast';
import { BroadcastChannelNames, StoreState, thunkDispatch } from 'store';
import { backendApi, backendApiAuthorize } from './backendapi';
import { IAuthenticateRequest } from 'packages/shared/validate/request/users/authentication/signin';
import { domEvents } from 'common/event/client';
import { validateJwt } from 'packages/shared/validate/request/tokens';
import { TypedEventTarget } from 'common/typedeventtarget';
import { navigatorLocale } from 'app/slice';
import { KnisperAges } from 'packages/shared/entity/audioconversion';
import { isAxiosError } from 'axios';
import { DateTime } from 'luxon';
import { IUserRequest } from 'packages/shared/validate/types/user';

interface AuthenticateEventsMap {
    signin: MessageEvent<IUserRequest>;
    refresh: Event;
    signout: Event;
};

export const authenticateEvents = new TypedEventTarget<AuthenticateEventsMap>();

const loadStoredState = () : IUserRequest => {
    return JSON.parse(localStorage.getItem("user") ?? "null");
};

const saveStoredState = (state:IUserRequest) : void => {
    localStorage.setItem("user", JSON.stringify(state));
};

export const signInAsync = createAsyncThunk<IUserRequest, IAuthenticateRequest>(
    "authenticate/signin",
    async(args, {rejectWithValue, fulfillWithValue}) => {
        try {
			const response = await backendApi.post<IUserRequest>(
                '/api/v1/users/authenticate/signin',
                args
            );

            localStorage.setItem('access-token', response.headers?.["x-access-token"]);
            localStorage.setItem('refresh-token', response.headers?.["x-refresh-token"]);

            return fulfillWithValue(response.data);
        }
		catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const signOutAsync = createAsyncThunk(
    "authenticate/signout",
    async () => { 
        
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');

        await backendApiAuthorize.post('/api/v1/users/authenticate/signout', undefined, {
            // no response interceptor
            validateStatus: undefined 
        });
    }
);

interface IRefreshResponse {
    accessToken: string;
    refreshToken: string;
};

export const refreshAccessTokenAsync = createAsyncThunk<IRefreshResponse>(
    "authenticate/refreshtoken",
    async () => {

        const cookies = cookie.parse(document.cookie);

        const refreshToken = cookies?.['refresh-token'] 
            ?? localStorage.getItem('refresh-token')
            ?? '';

        const validate = validateJwt({ token: refreshToken });
        if (!validate.success) { 
            throw new Error("Invalid refresh token"); 
        }

        const { headers } = await backendApi.post<IUserRequest>('/api/v1/users/authenticate/refresh', undefined, {
            headers: {
                "X-Refresh-Token": localStorage.getItem('refresh-token')
            }
        });

        return {
            accessToken: headers?.['x-access-token'] ?? '',
            refreshToken: headers?.['x-refresh-token'] ?? ''
        };
    }
);

export const verifyAccessTokenAsync = createAsyncThunk<
    IUserRequest, 
    string
>(
    "authenticate/verify",
    async (_) => {
        const response = await backendApiAuthorize.get<IUserRequest>('/api/v1/users/authenticate/verify');

        return response.data;
    }
);

const removeAuthenticationCase: CaseReducer<IUserRequest, PayloadAction> = (state, _) => {
 
    if (state.userId === 0) {
        return;
    }

    state.archived = defaultState.archived;
    state.email = defaultState.email;
    state.locale = defaultState.locale;
    state.name = defaultState.name;
    state.roles = defaultState.roles;
    state.teamId = defaultState.teamId;
    state.team = defaultState.team;
    state.timezone = defaultState.timezone;
    state.userId = defaultState.userId;

    console.log("[IsAuthenticated] removeAuthenticationCase ", state.userId !== 0);

    saveStoredState(state);

    authenticateEvents.dispatchEvent(
        new Event("signout")
    );

    broadcastEvents.dispatchEvent(
        new Event(BroadcastChannelNames.Authentication)
    );
};

const storeAuthenticationCase: CaseReducer<IUserRequest, PayloadAction<IUserRequest>> = (state, action) => {

    if (state.userId === action.payload.userId) {
        return;
    }

    slice.caseReducers.updateAuthenticationCase(state, {
        type: "authenticate/store",
        payload: action.payload
    });

    authenticateEvents.dispatchEvent(
        new MessageEvent("signin", { 
            data: action.payload
        })
    );

    broadcastEvents.dispatchEvent(
        new Event(BroadcastChannelNames.Authentication)
    );
};

const updateAuthenticationCase: CaseReducer<IUserRequest, PayloadAction<IUserRequest>> = (state, action) => {

    state.archived = action.payload.archived;
    state.email = action.payload.email;
    state.locale = action.payload.locale;
    state.name = action.payload.name;
    state.roles = action.payload.roles;
    state.teamId = action.payload.teamId;
    state.team = action.payload.team;
    state.timezone = action.payload.timezone;
    state.userId = action.payload.userId;

    saveStoredState(state);
};

let initializedState:IUserRequest|undefined;

const defaultState:IUserRequest = {
    userId: 0,
    name: '',
    email: '',
    archived: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: navigatorLocale(),
    team:{
        teamId: 0,
        name: '', 
        knisperAge: 0 as KnisperAges, 
        knisperSeparation: 0,
        createdDate: DateTime.now().toUTC().toISO(),
        archived: false,
        members: 0
    }, 
    roles:[],
    createdDate: DateTime.now().toUTC().toISO(),
    // 
    teamId: 0
};

const slice = createSlice({
    name: "authenticate",
    initialState: (): IUserRequest => {

        if (initializedState !== undefined) {
            return initializedState;
        }

        const storedState = loadStoredState();

        initializedState = {
            ...defaultState,
            ...storedState
        };

        if (initializedState.userId !== 0) {
            authenticateEvents.dispatchEvent(new MessageEvent<IUserRequest>("signin", { 
                data: initializedState 
            }));
        }

        domEvents.addEventListener('back_forward', (event) => { 
            thunkDispatch(verifyAccessTokenAsync(event.type));
        });
        domEvents.addEventListener('navigate', (event) => { 
            thunkDispatch(verifyAccessTokenAsync(event.type));
        });
        //domEvents.addEventListener('prerender', (event) => { 
        //    thunkDispatch(verifyAccessTokenAsync(event.type));
        //});
        //domEvents.addEventListener('reload', (event) => { 
        //    thunkDispatch(verifyAccessTokenAsync(event.type));
        //});

        return initializedState;
    },
    reducers: {
        removeAuthenticationCase,
        storeAuthenticationCase,
        updateAuthenticationCase,
        updateBroadcast: (state) => {

            const loadedState = loadStoredState();

            if (state === loadedState) {
                return;
            }
            else if (loadedState === null) {
                slice.caseReducers.removeAuthenticationCase(state, { 
                    type: "authenticate/broadcast/signout", 
                    payload: undefined
                });
            }
            else {
                slice.caseReducers.storeAuthenticationCase(state, {
                    type: "authenticate/verify/fulfilled",
                    payload: loadedState
                });
            }

            authenticateEvents.dispatchEvent(
                state.userId !== 0 
                    ? new MessageEvent<IUserRequest>("signin", { 
                        data: {...state} 
                    })
                    : new Event("signout")
            );

            //console.log("[IsAuthenticated] updateBroadcast ", state.userId !== 0);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInAsync.fulfilled, (state, action) => {

                slice.caseReducers.storeAuthenticationCase(state, {
                    type: "authenticate/verify/fulfilled",
                    payload: action.payload 
                });
            });

        builder
            .addCase(signOutAsync.fulfilled, (state) => {

                slice.caseReducers.removeAuthenticationCase(state, { 
                    type: "authenticate/signout", 
                    payload: undefined
                });
            });

        builder
            .addCase(refreshAccessTokenAsync.fulfilled, (_, action) => {

                localStorage.setItem('access-token', action.payload.accessToken);

                const refreshToken = action.payload.refreshToken;

                const validate = validateJwt({
                    token: refreshToken
                });

                if (validate.success) {
                    localStorage.setItem('refresh-token', refreshToken);
                }

                authenticateEvents.dispatchEvent(new Event("refresh"));
            })
            .addCase(refreshAccessTokenAsync.rejected, (state) => {

                slice.caseReducers.removeAuthenticationCase(state, { 
                    type: "authenticate/refresh/rejected", 
                    payload: undefined
                });
            });

        builder
            .addCase(verifyAccessTokenAsync.fulfilled, (state, action) => {
        
                slice.caseReducers.updateAuthenticationCase(state, {
                    type: "authenticate/verify",
                    payload: action.payload
                });
            })
            .addCase(verifyAccessTokenAsync.rejected, (state) => {

                const cookies = cookie.parse(document.cookie);

                const refreshToken = cookies?.['refresh-token'] 
                    ?? localStorage.getItem('refresh-token')
                    ?? '';

                const validateRefreshToken = validateJwt({
                    token: refreshToken
                });

                if (validateRefreshToken.success) {
                    return; 
                }
                
                slice.caseReducers.removeAuthenticationCase(state, { 
                    type: "authenticate/verify/rejected", 
                    payload: undefined
                });
            });
    }
});

export const { 
    updateBroadcast, 
    updateAuthenticationCase:updateAuthentication 
} = slice.actions;

export const { reducer } = slice;

export const selectIsAuthenticated = createSelector(
    (state: StoreState) => state,
    (state) => state.authenticate.userId !== 0
);
