import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "app/hooks";
import { Hero, HeroTitle } from "common/components/Hero";
import { clearUsers, fetchAllUsersAsync, selectUsersCount, selectUsersLimitOffsetSort } from "features/users/slice";
import { createRef, useEffect } from "react";
import { SortableTableHead, TableHead } from "common/components/TableHead";
import { Button, Switch } from "@material-tailwind/react";
import { ArchiveBoxIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { DataTable, DataTableCell, DataTableCellTypography } from "common/components/DataTable";
import { toNumber } from "packages/shared/utility/convert";
import { useNavigate, useParams } from "react-router-dom";
import { PageNav } from "common/components/PageNav";
import { GenericDialog, IGenericDialogRef } from "common/components/GenericDialog";
import { IOrderBy } from "common/redux/orderby";
import { IUserRequest } from "packages/shared/validate/types/user";
import { UsersForm } from "./UsersForm";
import { useTranslation } from "react-i18next";

export const AdminUsers = () => {

	const { pageNr:pageNrParam } = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [pageNr, setPageNr] = useState(toNumber(pageNrParam, 1));

    const limit = toNumber(import.meta.env.VITE_PAGENAV_ADMIN_USERS_LIMIT);

    const [orderBy, setOrderBy] = useState<IOrderBy>({
        column: 'createdDate', 
        direction: 'desc'
    });

    const [includeArchived, setIncludeArchived] = useState(false);

	const users = useSelector(state => selectUsersLimitOffsetSort(state, limit, (pageNr-1) * limit, orderBy, includeArchived));  

    const usersCount = useSelector(state => selectUsersCount(state, includeArchived));

    const dialogRef = createRef<IGenericDialogRef<IUserRequest>>();

	useEffect(() => {

        const action = dispatch(fetchAllUsersAsync());

        return () => {
            action.abort();

            dispatch(clearUsers());
        }
    }, []);

    const onSort = (event: MouseEvent, column:string) => {

        event.preventDefault();

        setOrderBy({
            column,
            direction: orderBy.column !== column 
                ? 'desc'
                : orderBy.direction === 'desc'  
                ? 'asc'
                : 'desc'
        });
    };

    const onOpenDialog = (event:MouseEvent<HTMLButtonElement>, title:string, data?:IUserRequest) => {
        event.preventDefault();

        dialogRef.current?.initialize(title, data);
    };

    const { t } = useTranslation();

	const authenticatedUser = useSelector(store => store.authenticate);

    return (
        <>
            <Hero>
                <HeroTitle>Users</HeroTitle>

                <GenericDialog
                    ref={dialogRef}
                >
                    {(setOpen, data) => <UsersForm data={data} setDialogOpen={setOpen} />}
                </GenericDialog>

                <div className="flex p-2">
                    <div className="flex flex-row grow justify-start space-x-1 px-1">
                        {t("Manage users")}
                    </div>
                    <div className="flex flex-row justify-end space-x-2 px-2">
                        <Button
                            className="flex items-center gap-2"
                            onClick={event => onOpenDialog(
                                event, 
                                t("Create User"),
                            )}
                            placeholder={undefined} 
                            onPointerEnterCapture={()=>{}} 
                            onPointerLeaveCapture={()=>{}}
                        >
                            <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                            {t("Create User")}
                        </Button>
                    </div>
                </div>

                <div className="flex items-center space-x-2 justify-end p-3">
                    <div>{t("Show Archived")}</div>
                    <Switch
                        ripple={false}
                        containerProps={{
                            className: "w-11 h-6",
                        }}
                        circleProps={{
                            className: "before:hidden left-0.5 border-none",
                        }} 
                        onPointerEnterCapture={undefined} 
                        onPointerLeaveCapture={undefined} 
                        crossOrigin={undefined}
                        defaultChecked={includeArchived}
                        onChange={e => {

                            navigate(`/admin/users`);

                            setIncludeArchived(e.currentTarget.checked);
                        }}
                    />
                </div>

                <DataTable
                    data={users} 
                    fixed={true}
                    header={_ => {
                        return (
                            <tr>
                                {/* Email */}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'email')}
                                    orderByDirection={orderBy.column === 'email' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("E-mail")}
                                </SortableTableHead>

                                {/* Name */}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'name')}
                                    orderByDirection={orderBy.column === 'name' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("Name")}
                                </SortableTableHead>

                                {/* Timezone */}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'timezone')}
                                    orderByDirection={orderBy.column === 'timezone' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("Timezone")}
                                </SortableTableHead>

                                {/* Roles */}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'roles')}
                                    orderByDirection={orderBy.column === 'roles' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("Roles")}
                                </SortableTableHead>

                                {/* Team */}
                                <SortableTableHead 
                                    onClick={e => onSort(e, 'team')}
                                    orderByDirection={orderBy.column === 'team' 
                                        ? orderBy.direction 
                                        : undefined
                                    }
                                >
                                    {t("Team")}
                                </SortableTableHead>

                                {/* Archived */}
                                <TableHead className="w-[32px]"></TableHead>
                                                            
                                {/* Buttons */}
                                <TableHead></TableHead>
                            </tr>
                        );
                    }}
                >
                    {records => (
                        <tbody>
                        {records.map((record, index) => (
                            <tr key={`admin-users-row-${index}`} className={`${record.archived ? 'bg-curious-blue-100/50' : ''}`}>
                                <DataTableCellTypography>
                                    {record.email}
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    <span className={authenticatedUser.userId === record.userId ? "font-bold" : "font-normal"}>{record.name}</span>
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    {record.timezone}
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    {record.roles?.join(', ')}                                  
                                </DataTableCellTypography>
                                <DataTableCell>
                                    {record.team.name}
                                </DataTableCell>
                                <DataTableCell>
                                    {record.archived && (
                                        <ArchiveBoxIcon className="w-[20px] h-[20px] -mx-2" />
                                    )}
                                </DataTableCell>
                                <DataTableCell>
                                    <Button
                                        className="flex items-center gap-2"
                                        onClick={event => onOpenDialog(
                                                event, 
                                                t("Update User"), 
                                                record
                                            )
                                        }
                                        placeholder={undefined} 
                                        onPointerEnterCapture={()=>{}} 
                                        onPointerLeaveCapture={()=>{}}
                                    >
                                        <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                                        {t("Edit")}
                                    </Button>
                                </DataTableCell>
                            </tr>
                        ))}
                        </tbody>
                    )}
                </DataTable>
                <PageNav 
                    pageNr={pageNr}
                    itemsPerPage={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_USERS_LIMIT)} 
                    itemsDisplayed={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_USERS_RANGE)}
                    itemsTotal={usersCount}
                    onPageChange={(pageNr) => {

                        navigate(`/admin/users/${pageNr}`);

                        setPageNr(pageNr);
                    }}
                />
            </Hero>
        </>
    );
};
