import { useDispatch } from "app/hooks";
import { Checkbox, Input, Typography } from "@material-tailwind/react";
import { FormError } from "common/components/FormError";
import { IGenericDialogWrapperProps } from "common/components/GenericDialog";
import { DialogForm } from "common/components/DialogForm";
import { IRoleRequest } from "packages/shared/validate/types/role";
import { createRoleAsync, patchRoleAsync } from "features/users/slice";
import { useTranslation } from "react-i18next";

export const RolesForm = ({data, setDialogOpen}:IGenericDialogWrapperProps<IRoleRequest>) => {

    const dispatch = useDispatch();

    const { t } = useTranslation();

    return (
        <DialogForm
            data={data}
            onCreate={(data, setValidationErrors) => {

                dispatch(createRoleAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => { 
                        setValidationErrors(error);
                    });
            }} 
            onUpdate={(data, setValidationErrors) => {

                dispatch(patchRoleAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => {
                        setValidationErrors(error);
                    });
            }}
        >
        {(register, _, errors) => {

            return (
                <>
                    <Input
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="name"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="name"
                        crossOrigin={undefined}
                        {...register("name")} 
                    />
                    <FormError error={errors.name} />

                    <Checkbox
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="remember"
                        label={<Typography
                                variant="small"
                                color="gray"
                                className="flex items-center font-normal" 
                                placeholder={undefined} 
                                onPointerEnterCapture={() => {}} 
                                onPointerLeaveCapture={() => {}}
                            >
                            {t("Archived")}
                            </Typography>
                        }
                        crossOrigin={undefined}
                        autoComplete="true"
                        {...register('archived')} 
                    />

                    <FormError error={errors.root} />
                </>
            )
        }}
        </DialogForm>
    );
};
