import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { backendApiAuthorize } from './authenticate/backendapi';
import { ITeamPatchRequest, ITeamRequest } from 'packages/shared/validate/types/team';
import { StoreState } from 'store';
import { IOrderBy, orderByKey } from 'common/redux/orderby';
import { isAxiosError } from 'axios';
import { IUserEmail, IUserPatchRequest, IUserRequest, IUserResetPassword } from 'packages/shared/validate/types/user';
import { IRolePatchRequest, IRoleRequest } from 'packages/shared/validate/types/role';
import { DateTime } from 'luxon';
import { archivedSelector, countArchivedSelector, countHandle, limitOffsetOrderHandle, limitSelector, offsetSelector, orderSelector } from 'app/slice';

export const fetchAllTeamsAsync = createAsyncThunk<
    ITeamRequest[]
>(
    "user/teams/all",
    async (_) => {
        const { data } = await backendApiAuthorize.get('/api/v1/teams');

        return data;
    }
);

export const patchTeamAsync = createAsyncThunk<
    ITeamRequest, 
    Pick<ITeamRequest, 'teamId'> & ITeamPatchRequest
>(
    "user/team/patch", 
    async (args, { fulfillWithValue, rejectWithValue }
) => {

        const { teamId, ...patch } = args;

        try {
            const { data } = await backendApiAuthorize.patch(
                `/api/v1/teams/${teamId}`, 
                patch
            );

            return fulfillWithValue(data);
        } 
        catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const createTeamAsync = createAsyncThunk<
    ITeamRequest, 
    Pick<ITeamRequest, 'teamId'> & ITeamPatchRequest
>(
    "user/team/create", 
    async (args, { fulfillWithValue, rejectWithValue }) => {

        try {
            const { data } = await backendApiAuthorize.post(`/api/v1/teams`, args);

            return fulfillWithValue(data);
        } 
        catch(error) {

			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const fetchAllUsersAsync = createAsyncThunk<
    IUserRequest[]
>(
    "user/all",
    async () => {
        const { data } = await backendApiAuthorize.get('/api/v1/users');

        return data;
    }
);

export const patchUserAsync = createAsyncThunk<
    IUserRequest, 
    IUserPatchRequest
>(
    "users/patch", 
    async (args, { fulfillWithValue, rejectWithValue, dispatch }
) => {

        const { userId, ...patch } = args;

        try {
            const { data } = await backendApiAuthorize.patch(
                `/api/v1/users/${userId}`, 
                patch
            );

            if (args.reset) {
                dispatch(requestResetPasswordAsync({ 
                    email: 
                    data.email 
                }));
            }

            return fulfillWithValue(data);
        } 
        catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const createUserAsync = createAsyncThunk<
    IUserRequest, 
    IUserPatchRequest
>(
    "user/create", 
    async (args, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await backendApiAuthorize.post<IUserRequest>(`/api/v1/users`, args);

            return fulfillWithValue(data);
        } 
        catch(error) {

			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const requestResetPasswordAsync = createAsyncThunk<
    void, 
    IUserEmail
>(
    "user/reset/request",
    async (email) => {
        await backendApiAuthorize.post(`/api/v1/users/reset`, email);
    }
);

export const resetPasswordAsync = createAsyncThunk<
    string, 
    IUserResetPassword & { resetToken?: string }
>(
    "user/reset/password",
    async (args, { fulfillWithValue, rejectWithValue }) => { 

        const { resetToken, ...data } = args;

        try {
            await backendApiAuthorize.patch(`/api/v1/users/reset/${resetToken}`, data);

            return fulfillWithValue("password saved");
        }
        catch(error) {
            
            throw rejectWithValue(
                isAxiosError(error) 
                    ? error.response?.data
                    : error
            );
        }
    }
);

export const fetchAllRolesAsync = createAsyncThunk<
    IRoleRequest[]
>(
    "roles/all",
    async () => {
        const { data } = await backendApiAuthorize.get(`/api/v1/roles`);

        return data;
    }
);

export const createRoleAsync = createAsyncThunk<
    IRoleRequest, 
    IRoleRequest
>(
    "roles/create", 
    async (args, { fulfillWithValue, rejectWithValue }) => {

        args.createdDate = DateTime.now().toUTC().toISODate();

        try {
            const { data } = await backendApiAuthorize.post(`/api/v1/roles`, args);

            return fulfillWithValue(data);
        } 
        catch(error) {

			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

export const patchRoleAsync = createAsyncThunk<
    IRoleRequest, 
    Pick<IRoleRequest, 'roleId'> & IRolePatchRequest
>(
    "role/patch", 
    async (args, { fulfillWithValue, rejectWithValue }
) => {

        const { roleId, ...patch } = args;

        try {
            const { data } = await backendApiAuthorize.patch(
                `/api/v1/roles/${roleId}`, 
                patch
            );

            return fulfillWithValue(data);
        } 
        catch(error) {
			throw rejectWithValue(
				isAxiosError(error) 
					? error.response?.data
					: error);
        }
    }
);

interface ITeamsInitialState {
    users: IUserRequest[];
    teams: ITeamRequest[];
    roles: IRoleRequest[];
};

const defaultState: ITeamsInitialState = {
    users: [],
    teams: [],
    roles: []
};

const slice = createSlice({
    name: "users",
    initialState: (): ITeamsInitialState => {
        return defaultState;
    },
    reducers: {
        clearUsers: (state) => {
            state.users = [];
        },
        clearTeams: (state) => {
            state.teams = [];
        },
        clearRoles: (state) => {
            state.roles = [];
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(fetchAllTeamsAsync.fulfilled, (state, action) => {
                state.teams = [...action.payload];
            });

        builder
            .addCase(createTeamAsync.fulfilled, (state, action) => {
                state.teams = [
                    action.payload,
                    ...state.teams
                ];
            });

        builder
            .addCase(patchTeamAsync.fulfilled, (state, action) => {
                state.teams = state.teams.map(team => {
                    if (team.teamId !== action.payload.teamId) {
                        return team;
                    }

                    return action.payload;
                });
            });

        builder
            .addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
                state.users = [...action.payload];
            });

        builder
            .addCase(createUserAsync.fulfilled, (state, action) => {
                state.users = [
                    action.payload,
                    ...state.users
                ];
            });

        builder
            .addCase(patchUserAsync.fulfilled, (state, action) => {

                state.users = state.users.map(user => {
                    if (user.userId !== action.payload.userId) {
                        return user;
                    }

                    return action.payload;
                });
            });
        
        builder
            .addCase(fetchAllRolesAsync.fulfilled, (state, action) => {
                state.roles = [...action.payload];
            });

        builder
            .addCase(createRoleAsync.fulfilled, (state, action) => {
                state.roles = [
                    action.payload,
                    ...state.roles
                ];
            });

        builder
            .addCase(patchRoleAsync.fulfilled, (state, action) => {
                state.roles = state.roles.map(role => {
                    if (role.roleId !== action.payload.roleId) {
                        return role;
                    }

                    return action.payload;
                });
            });
    }
});

export const { reducer } = slice;

export const { clearUsers, clearTeams, clearRoles } = slice.actions;

export const selectTeamsLimitOffsetSort = createSelector(
    [
    	(state: StoreState) => state.users.teams,
        limitSelector,
    	offsetSelector,
        orderSelector,
        archivedSelector,   
    ],
    limitOffsetOrderHandle
);

export const selectUsersLimitOffsetSort = createSelector(
    [
    	(state: StoreState) => state.users.users,
        limitSelector,
    	offsetSelector,
        orderSelector,
        archivedSelector, 
    ],
    limitOffsetOrderHandle
);

export const selectRolesLimitOffsetSort = createSelector(
    [
    	(state: StoreState) => state.users.roles,
        limitSelector,
    	offsetSelector,
        orderSelector,
        archivedSelector,
    ],
    limitOffsetOrderHandle
);

export const selectTeamsCount = createSelector(
    [
        (state: StoreState) => state.users.teams,
        countArchivedSelector,
    ],
    countHandle
);

export const selectUsersCount = createSelector(
    [
        (state: StoreState) => state.users.users,
        countArchivedSelector,
    ],
    countHandle
);

export const selectRolesCount = createSelector(
    [
        (state: StoreState) => state.users.roles,
        countArchivedSelector,
    ],
    countHandle
);
