import { useDispatch, useSelector } from "app/hooks";
import { createUserAsync, fetchAllRolesAsync, fetchAllTeamsAsync, patchUserAsync } from "features/users/slice";
import { Input, Checkbox, Typography, Select, Option } from "@material-tailwind/react";
import { FormError } from "common/components/FormError";
import { IGenericDialogWrapperProps } from "common/components/GenericDialog";
import { DialogForm } from "common/components/DialogForm";
import { IUserRequest } from "packages/shared/validate/types/user";
import { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";

interface IUserFormProps extends IGenericDialogWrapperProps<IUserRequest>{};

export const UsersForm = ({data, setDialogOpen} : IUserFormProps) => {

    const dispatch = useDispatch();

    const roles = useSelector(state => 
        state.users.roles
            .filter(x => x.archived === false), 
        shallowEqual
    );

    const teams = useSelector(state => 
        state.users.teams,
        shallowEqual
    );

    const timeZones = Intl.supportedValuesOf('timeZone');

    useEffect(() => {

        dispatch(fetchAllTeamsAsync());

        dispatch(fetchAllRolesAsync());

    }, []);

    const { t } = useTranslation();

    const timeZone = data?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <DialogForm
            data={data === undefined 
                ? undefined 
                : {
                    ...data, 
                    ...{
                        reset: false
                    }
                }
            }
            onCreate={(data, setValidationErrors) => {
                dispatch(createUserAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => { 
                        setValidationErrors(error);
                    });
            }} 
            onUpdate={(data, setValidationErrors) => {
                dispatch(patchUserAsync(data))
                    .unwrap()
                    .then(_ => { 
                        setDialogOpen(false);
                    })
                    .catch(error => {
                        setValidationErrors(error);
                    });
            }}
        >
        {(register, setValue, errors) => {

            const { onChange:onTimezoneChange, ...timezoneRegistration } = register("timezone");

            const { onChange:onTeamIdChange, ...teamIdRegistration } = register("teamId");

            if (data?.timezone === undefined) {
                setValue(timezoneRegistration.name, timeZone);
            }

            return (
                <>
                    <Input
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="name"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="name"
                        crossOrigin={undefined}
                        {...register("name")} 
                    />
                    <FormError error={errors.name} />

                    <Input
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        id="e-mail"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="e-mail"
                        crossOrigin={undefined}
                        {...register("email")} 
                    />
                    <FormError error={errors.email} />

                    <Select 
                        label="timezone"
                        onPointerEnterCapture={() => { } }
                        onPointerLeaveCapture={() => { } }
                        placeholder={undefined}
                        value={timeZone}
                        onChange={(event) => {
                            setValue(timezoneRegistration.name, event!);
                        }}
                        {...timezoneRegistration}
                    >
                        {timeZones.map(timezone => (
                            <Option key={`user-timezone-${timezone}`} value={timezone}>{timezone}</Option>
                        ))}
                    </Select>

                    <Checkbox
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        label={<Typography
                                variant="small"
                                color="gray"
                                className="flex items-center font-normal" 
                                placeholder={undefined} 
                                onPointerEnterCapture={() => {}} 
                                onPointerLeaveCapture={() => {}}
                            >
                            {t("Archived")}
                            </Typography>
                        }
                        crossOrigin={undefined}
                        autoComplete="true"
                        {...register('archived')} 
                    />

                    <div className="flex flex-col">
                        <div>
                            <Typography
                                variant="small"
                                color="gray"
                                className="flex font-small"
                                placeholder={undefined}
                                onPointerEnterCapture={() => { } }
                                onPointerLeaveCapture={() => { } }
                            >
                                {t("Roles")}
                            </Typography>
                        </div>
                        <div>
                        {roles.map((role, index) => (
                            <Checkbox 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined} 
                                crossOrigin={undefined}
                                label={
                                    <Typography
                                        variant="small"
                                        color="gray"
                                        className="flex items-center font-normal" 
                                        placeholder={undefined} 
                                        onPointerEnterCapture={() => {}} 
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        {role.name}
                                    </Typography>
                                }
                                defaultChecked={false}
                                key={`user-role-${index}`}
                                value={role.name}
                                {...register(`roles`)}
                            />
                        ))}
                        </div>
                    </div>
                    <FormError error={errors.roles} />

                    <Select 
                        label="team"
                        onPointerEnterCapture={() => { } }
                        onPointerLeaveCapture={() => { } }
                        placeholder={undefined}
                        value={data?.teamId?.toString()}
                        onChange={(event) => {
                            setValue(teamIdRegistration.name, parseInt(event!));
                        }}
                        {...teamIdRegistration}
                    >
                        {teams.map(team => {
                            return (
                                <Option 
                                    key={`user-team-${team.teamId}`} 
                                    value={team.teamId.toString()}
                                    disabled={team.archived}
                                >
                                    {team.name}
                                </Option>
                            );
                        })}
                    </Select>

                    <Checkbox
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}} 
                        label={<Typography
                                variant="small"
                                color="gray"
                                className="flex items-center font-normal" 
                                placeholder={undefined} 
                                onPointerEnterCapture={() => {}} 
                                onPointerLeaveCapture={() => {}}
                            >
                                {t("Reset password")}
                            </Typography>
                        }
                        crossOrigin={undefined}
                        autoComplete="true"
                        {...register('reset')} 
                    />

                    <FormError error={errors.root} />
                </>
            )
        }}
        </DialogForm>
    );
};
