
export type OrderDirection = 'asc'|'desc';

export interface IOrderBy {
    column: string;
    direction: OrderDirection
};

export const orderByKey = <
    C, 
    N extends C, 
    K extends keyof C
>(current:C, next:N, key:K, desc: boolean): number => {

    const a = current[key];
    const b = next[key];

    let result = 0; 

    if (typeof a === 'string' && typeof b === 'string') {
        const compare = a.localeCompare(b);
        result = (compare < 0) 
            ? -1 
            : (compare > 0) 
                ? 1 
                : 0;
    }

    result = ((a > b) ? -1 : (a < b) ? 1 : 0);

    return desc 
        ? (result === 1 
            ? -1 
            : result === -1 
            ? 1 
            : 0
        )
        : result;
};
