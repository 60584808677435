import { DateTime } from "luxon";

export const toNumber = (number:unknown, min?:number) : number => {

    const nr = Number(number);

    return Number.isNaN(nr)
        ? (min !== undefined)
            ? min
            : 0
        : nr;
};

export const toDate = (date?:string) : Date|undefined => {

    return (!!date) 
        ? DateTime.fromISO(date).toJSDate()
        : undefined;
};
