type TypedEvents<TKeys> = {
    [key in keyof TKeys]: Event;
};

type TypedEventListener<TMapped, TKey extends keyof TMapped> = (
    evt: TMapped[TKey]
) => void | Promise<void>;

interface ITypedEventListenerObject<TMapped, TKey extends keyof TMapped> {
    handleEvent: (evt: TMapped[TKey]) => void | Promise<void>;
};

export type TypedEventListenerOrEventListenerObject<TMapped, TKey extends keyof TMapped> =
    | TypedEventListener<TMapped, TKey>
    | ITypedEventListenerObject<TMapped, TKey>;

export interface TypedEventTarget<TMapped extends TypedEvents<TMapped>> {

    addEventListener: <T extends keyof TMapped & string>(
        type: T,
        listener: TypedEventListenerOrEventListenerObject<TMapped, T> | null,
        options?: boolean | AddEventListenerOptions
    ) => void;

    removeEventListener: <T extends keyof TMapped & string>(
        type: T,
        callback: TypedEventListenerOrEventListenerObject<TMapped, T> | null,
        options?: EventListenerOptions | boolean
    ) => void;
};

interface IEventConstructor<T extends Event = Event> {
    new (type:string, eventInitDict?: EventInit): T;
}

export class TypedEventTarget<TMapped extends TypedEvents<TMapped>> extends EventTarget {

    public dispatchEvent(event:Event): boolean {

        const constructor = event.constructor as IEventConstructor<typeof event>;
        const clonedEvent = new constructor(event.type, event);

        return super.dispatchEvent(clonedEvent);
    }
};

/*
interface EventsMap {
    event: Event;
    message: MessageEvent<{}>;
    custom: CustomEvent<{}>;
};

const eventTarget = new TypedEventTarget<EventsMap>();

eventTarget.addEventListener('event', (e) => {
    debugger;
});

eventTarget.addEventListener('message', (e) => {
    debugger;
});

eventTarget.addEventListener('custom', (e) => {
    debugger;
});

eventTarget.dispatchEvent(new CustomEvent('custom', { detail: 123 }));
eventTarget.dispatchEvent(new MessageEvent('message', { data: 123 }));
eventTarget.dispatchEvent(new Event('event'));
*/
