import { useSelector } from 'app/hooks';
import { SignInForm } from '../../features/users/authenticate/components/SignInForm';
import { Navigate } from 'react-router-dom';
import { Hero, HeroTitle } from 'common/components/Hero';
import { useTranslation } from 'react-i18next';
import { selectIsAuthenticated } from 'features/users/authenticate/slice';
import { Typography } from '@material-tailwind/react';

export const SignInScreen = () => {

	const isAuthenticated = useSelector((store) => selectIsAuthenticated(store));

    const { t } = useTranslation();

    return isAuthenticated 
        ? <Navigate to="/" />
        : (
			<Hero>
				<HeroTitle>{t("Login")}</HeroTitle>

                <SignInForm />

                <Typography
                    key="home"
                    as="a"
                    href="/forgot"
                    variant="small"
                    color="gray"
                    className=""
                    placeholder={undefined} 
                    onPointerEnterCapture={()=>{}} 
                    onPointerLeaveCapture={()=>{}}
                >
                    {t("I don't remember my password")}
                </Typography>
            </Hero>
        )
};

export default SignInScreen;
