import { Hero, HeroTitle } from 'common/components/Hero';
import { ForgotPasswordForm } from 'features/users/authenticate/components/ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

export const ForgotScreen = () => {

    const { t } = useTranslation();

    return (
			<Hero>
				<HeroTitle>{t('Forgot Password')}</HeroTitle>

                <ForgotPasswordForm />
            </Hero>
        )
};

export default ForgotScreen;
