import typia from "typia";
export interface IValidateError {
    name: string;
    value?: string;
    expected?: string;
}
;
export const isValidateErrors = (input: any): input is IValidateError[] => {
    const $io0 = (input: any): boolean => "string" === typeof input.name && (undefined === input.value || "string" === typeof input.value) && (undefined === input.expected || "string" === typeof input.expected);
    return Array.isArray(input) && input.every((elem: any) => "object" === typeof elem && null !== elem && $io0(elem));
};
