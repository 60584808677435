import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'app/hooks';
import { selectIsAuthenticated, signOutAsync } from 'features/users/authenticate/slice';
import { List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { PowerIcon, SpeakerWaveIcon, UserGroupIcon, UsersIcon, UserCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const Sidebar = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

	const { t } = useTranslation();

    const handleLogout = (e:MouseEvent) => {
        dispatch(signOutAsync());

        navigate('/');
    };

    const isAuthenticated = useSelector((store) => selectIsAuthenticated(store));

    const isAdmin = useSelector(state => state.authenticate.roles?.includes(import.meta.env.VITE_ADMIN_ROLE));

    const location = useLocation();

    return (
        <>
            <List 
                placeholder={undefined} 
                onPointerEnterCapture={undefined} 
                onPointerLeaveCapture={undefined}
            >
                {isAuthenticated && isAdmin && (
                    <>
                        <a href="/admin/teams" className="text-initial">
                            <ListItem 
                                selected={location.pathname.startsWith('/admin/teams')}
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <ListItemPrefix 
                                    placeholder={undefined} 
                                    onPointerEnterCapture={undefined} 
                                    onPointerLeaveCapture={undefined}
                                >
                                    <UserGroupIcon className="h-5 w-5 text-deep-blush-400" />
                                </ListItemPrefix>
                                {t("Teams")}
                            </ListItem>
                        </a>
                        <a href="/admin/users" className="text-initial">
                            <ListItem 
                                selected={location.pathname.startsWith('/admin/users')}
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <ListItemPrefix 
                                    placeholder={undefined} 
                                    onPointerEnterCapture={undefined} 
                                    onPointerLeaveCapture={undefined}
                                >
                                    <UsersIcon className="h-5 w-5 text-deep-blush-400" />
                                </ListItemPrefix>
                                {t("Users")}
                            </ListItem>
                        </a>
                        <a href="/admin/roles" className="text-initial">
                            <ListItem 
                                selected={location.pathname.startsWith('/admin/roles')}
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <ListItemPrefix 
                                    placeholder={undefined} 
                                    onPointerEnterCapture={undefined} 
                                    onPointerLeaveCapture={undefined}
                                >
                                    <UserCircleIcon className="h-5 w-5 text-deep-blush-400" />
                                </ListItemPrefix>
                                {t("Roles")}
                            </ListItem>
                        </a>
                        <hr className="my-2 border-curious-blue-200" /> 
                    </>
                )}
                {isAuthenticated && (
                    <>
                        <a href="/files" className="text-initial">
                            <ListItem 
                                selected={location.pathname.startsWith('/files')}
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <ListItemPrefix placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <SpeakerWaveIcon className="h-5 w-5 text-deep-blush-400" />
                                </ListItemPrefix>
                                {t("Files")}
                            </ListItem>
                        </a>
                        <a href="/settings" className="text-initial">
                            <ListItem 
                                selected={location.pathname.startsWith('/settings')}
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <ListItemPrefix 
                                    placeholder={undefined} 
                                    onPointerEnterCapture={undefined} 
                                    onPointerLeaveCapture={undefined}
                                >
                                    <Cog6ToothIcon className="h-5 w-5 text-deep-blush-400" />
                                </ListItemPrefix>
                                {t("Settings")}
                            </ListItem>
                        </a>
                        <ListItem 
                            placeholder={undefined} 
                            onPointerEnterCapture={undefined} 
                            onPointerLeaveCapture={undefined}
                            onClick={(e) => handleLogout(e)}
                        >
                            <ListItemPrefix 
                                placeholder={undefined} 
                                onPointerEnterCapture={undefined} 
                                onPointerLeaveCapture={undefined}
                            >
                                <PowerIcon className="h-5 w-5 text-deep-blush-400" />
                            </ListItemPrefix>
                            {t("Logout")}
                        </ListItem>
                    </>
                )}
            </List>
        </>
    );
}
