import { ComponentProps, PropsWithChildren } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { OrderDirection } from "common/redux/orderby";

export interface ITableHeadProps extends PropsWithChildren, ComponentProps<"th"> {};

export const TableHead = (props:ITableHeadProps) => {
    
    const defaultProps = {
        className: "border-y border-curious-blue-100 bg-curious-blue-50/50 p-4 transition-colors",
    };

    const { children, ...rest } = props;

    return (
        <th {...{
            ...defaultProps,
            ...rest,
            ...{
                className: `${defaultProps.className} ${rest.className ??''}`
            }
        }}>
            <Typography
                variant="small"
                color="blue-gray"
                className="flex items-center justify-between gap-2 font-normal leading-none opacity-70" 
                placeholder={undefined} 
                onPointerEnterCapture={()=>{}} 
                onPointerLeaveCapture={()=>{}}
            >
                {children ?? <></>}
            </Typography>
        </th>
    );
};


export interface ISortableTableHead extends ITableHeadProps {
    orderByDirection?: OrderDirection;
};

export const SortableTableHead = (props:ISortableTableHead) => {

    const {orderByDirection, ...headProps} = props;

    return (
        <TableHead {...{
            ...headProps,
            ...{
                className: `${"hover:bg-curious-blue-50 cursor-default select-none"} ${headProps.className ??''}`,
                onClick: headProps.onClick
            }}
        }>
            {props.children}

            {orderByDirection === 'asc' 
                ? <ChevronUpIcon strokeWidth={2} className="h-4 w-4" />
                : orderByDirection === 'desc'
                ? <ChevronDownIcon strokeWidth={2} className="h-4 w-4" />
                : <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
            }
            
        </TableHead>
    );
};
