import { PropsWithChildren, forwardRef, useImperativeHandle } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { toNumber } from "packages/shared/utility/convert";

export interface IDropZoneDrop {
	onDrop?: <T extends File>(
		acceptedFiles: T[],
		fileRejections: FileRejection[],
		event: DropEvent
	) => void;
};

export interface IAudioProjectDropZoneRef {
    openSelect: () => void;
};

export const AudioProjectDropZone = forwardRef((props:PropsWithChildren & IDropZoneDrop, ref) => {

	const {
		getRootProps, 
		getInputProps, 
		open, 
		acceptedFiles,
		fileRejections
	} = useDropzone({
		noClick: true,
		noKeyboard: true,
		onDrop: props.onDrop,
		multiple: true,
		accept: {
			"audio/wav": [".wav"],
			"audio/mp3": [".mp3"]
		},
		maxSize: toNumber(import.meta.env.VITE_MAX_UPLOAD_BYTES),
	});

    useImperativeHandle(ref, () => ({
        openSelect: () => {
			open();
        }
    }));

	return (
		<>
			<div className="" {...getRootProps()}>
				<input {...getInputProps()} />
				{props.children}
			</div>
			{/*
			<div>
				Accepted
				{acceptedFiles.map(file => (
					<div>{JSON.stringify(file)}</div>
				))}
			</div>
			<div>
				Rejected
				{fileRejections.map(({ file, errors }) => (
					<>
						<div>{JSON.stringify(file)}</div>
						<div>{JSON.stringify(errors)}</div>
					</>
				))}
			</div>
			*/}
		</>
	);
});
