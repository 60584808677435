import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import { useDispatch } from 'app/hooks';
import { Typography, Button, Input, Alert } from '@material-tailwind/react';
import { FormError } from 'common/components/FormError';
import { useTranslation } from 'react-i18next';
import { IUserEmail } from 'packages/shared/validate/types/user';
import { requestResetPasswordAsync } from 'features/users/slice';
import { useState } from 'react';
import { isValidateErrors } from 'packages/shared/validate/types/validate';

export const ForgotPasswordForm = () => {

    const { register, reset, handleSubmit, setError, formState: { errors } } = useForm<IUserEmail>({});

	const { t } = useTranslation();

    const dispatch = useDispatch();

	const [ showAlert, setShowAlert ] = useState(false); 

	const onSubmit:SubmitHandler<IUserEmail> = (data, event) => {
		event?.preventDefault();

		dispatch(requestResetPasswordAsync(data))
		.unwrap()
		.then(_ => {
			reset();

			setShowAlert(true);

		}).catch(error => {
			if (isValidateErrors(error)) {
				error.map(e => setError(e.name as keyof IUserEmail, {
					type: 'api',
					message: t('invalid', { ns: 'form', name: e.name })
				}));

				return;
			}

			setError("root", {
				type: 'api',
				message: error?.message ?? "unknown error"
			});
		});
	};

	const onError:SubmitErrorHandler<IUserEmail> = (errors, event) => {
		Object.entries(errors).map(([name, error]) => {

			if (error.type === 'required') {

				setError(name as keyof IUserEmail, {
					type: 'required',
					message: t('required', { ns: 'form', name })
				});

				return;
			}
		});
	};

    return (
		<>
			<form className="m-auto mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit(onSubmit, onError)}>
				<div className="mb-1 flex flex-col gap-4">
					<Typography 
						variant="h6" 
						color="blue-gray" 
						className="-mb-3" 
						placeholder={undefined} 
						onPointerEnterCapture={() => {}} 
						onPointerLeaveCapture={() => {}}
					>
						{t("E-mail")}
					</Typography>
					<div className="relative flex w-full max-w-[24rem]">
						<Input
							onPointerEnterCapture={()=>{}} 
							onPointerLeaveCapture={()=>{}} 
							id="forgot-email"
							type="text"
							size="lg"
							error={!!errors.email}
							className="focus:outline-none focus:ring-0"
							label="e-mail"
							crossOrigin={undefined}
							{...register(`email`)} 
						/>

					</div>
					<FormError error={errors.email} />
				</div>
				<FormError error={errors.root} />

				<Button className="mt-6" 
					type="submit" 
					fullWidth 
					placeholder={undefined} 
					onPointerEnterCapture={()=>{}} 
					onPointerLeaveCapture={()=>{}}
				>
					{t("Submit")}
				</Button>
			</form>

			<Alert 
				color="green"
				open={showAlert}
			>
				{t("Check your e-mail for further instructions")} 
			</Alert>
		</>
    );
};
