import { Typography } from "@material-tailwind/react";
import { PropsWithChildren, ReactElement } from "react";

export interface IDataTableData<T extends {}> {
    data: T[];
    fixed?: boolean;
    caption?: ReactElement;
    header: (keys: (keyof T)[]) => ReactElement<HTMLTableRowElement>;
    children: (data:T[]) => ReactElement<HTMLTableSectionElement|HTMLTableSectionElement[]>;
};

export const DataTable = <T extends {}, >(
    props: IDataTableData<T>
) => {

    const keys = Object.keys(props.data?.[0] ?? {}) as (keyof T)[];

    return (
        <table className={"mt-4 text-left" + props.fixed ? "table-fixed w-full" : ""}>
            {props?.caption && <caption>{props.caption}</caption>}
            <thead>
                {props.header(keys)}
            </thead>
            {props.children(props.data)}
        </table>
    );
};

export const DataTableCell = ({children}:PropsWithChildren) => {
    return (
        <td className="p-4 border-b border-blue-gray-50 text-center">
            {children}
        </td>
    );
};

export const DataTableCellTypography = ({children}:PropsWithChildren) => {
    return (
        <DataTableCell>
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal opacity-70 truncate"
                placeholder={undefined} 
                onPointerEnterCapture={undefined} 
                onPointerLeaveCapture={undefined}
            >
                {children ?? <></>}
            </Typography>
        </DataTableCell>
    );
};
