import { PayloadAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { isDevelopment } from 'common/env';
import { reducer as app } from 'app/slice';
import { reducer as authenticate } from 'features/users/authenticate/slice';
import { reducer as files } from 'features/files/slice';
import { reducer as users } from 'features/users/slice';

export type DispatchType = typeof store.dispatch;

export type StoreState = ReturnType<typeof store.getState>;

export enum BroadcastChannelNames {
	Locale = "locale",
    Authentication = "authentication"
};

export const store = configureStore({
	reducer: {
		app,
		authenticate,
		users,
		files
	},
	devTools: isDevelopment,
	preloadedState: {

	},
});

export const thunkDispatch:ThunkDispatch<StoreState, unknown, PayloadAction> = store.dispatch;
