import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "app/hooks";
import { Hero, HeroTitle } from "common/components/Hero";
import { clearTeams, fetchAllTeamsAsync, selectTeamsCount, selectTeamsLimitOffsetSort } from "features/users/slice";
import { ITeamRequest } from "packages/shared/validate/types/team";
import { createRef, useEffect } from "react";
import { SortableTableHead, TableHead } from "common/components/TableHead";
import { Button, Switch } from "@material-tailwind/react";
import { ArchiveBoxIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { DataTable, DataTableCell, DataTableCellTypography } from "common/components/DataTable";
import { DateTime } from "luxon";
import { DateTimeLocalize } from "../DateTimeLocalize";
import { toNumber } from "packages/shared/utility/convert";
import { useNavigate, useParams } from "react-router-dom";
import { PageNav } from "common/components/PageNav";
import { GenericDialog, IGenericDialogRef } from "common/components/GenericDialog";
import { IOrderBy } from "common/redux/orderby";
import { TeamsForm } from "./TeamsForm";
import { useTranslation } from "react-i18next";

export const AdminTeams = () => {

	const { pageNr:pageNrParam } = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [pageNr, setPageNr] = useState(toNumber(pageNrParam, 1));

    const limit = toNumber(import.meta.env.VITE_PAGENAV_ADMIN_TEAMS_LIMIT);

    const [orderBy, setOrderBy] = useState<IOrderBy>({
        column: 'createdDate', 
        direction: 'desc'
    });
    
    const [includeArchived, setIncludeArchived] = useState(false);

	const teams = useSelector(state => selectTeamsLimitOffsetSort(state, limit, (pageNr-1) * limit, orderBy, includeArchived));

    const teamsCount = useSelector(state => selectTeamsCount(state, includeArchived));

    const dialogRef = createRef<IGenericDialogRef<ITeamRequest>>();

	useEffect(() => {

        const action = dispatch(fetchAllTeamsAsync());

        return () => {
            action.abort();

            dispatch(clearTeams());
        }
    }, []);

    const onSort = (event: MouseEvent, column:string) => {

        event.preventDefault();

        setOrderBy({
            column,
            direction: orderBy.column !== column 
                ? 'desc'
                : orderBy.direction === 'desc'  
                ? 'asc'
                : 'desc'
        });
    };

    const onOpenDialog = (event:MouseEvent<HTMLButtonElement>, title:string, data?:ITeamRequest) => {
        event.preventDefault();

        dialogRef.current?.initialize(title, data);
    };

    const { t } = useTranslation();

	const authenticatedUser = useSelector(store => store.authenticate);

    return (
        <>
            <Hero>
                <HeroTitle>Teams</HeroTitle>

                <GenericDialog
                    ref={dialogRef}
                >
                    {(setOpen, data) => <TeamsForm data={data} setDialogOpen={setOpen} />}
                </GenericDialog>

                <div className="flex p-2">
                    <div className="flex flex-row grow justify-start space-x-1 px-1">
                        {t("Manage teams")}
                    </div>
                    <div className="flex flex-row justify-end space-x-2 px-2">
                        <Button
                            className="flex items-center gap-2"
                            onClick={event => onOpenDialog(
                                event, 
                                t("Create Team"),
                            )}
                            placeholder={undefined} 
                            onPointerEnterCapture={()=>{}} 
                            onPointerLeaveCapture={()=>{}}
                        >
                            <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                            {t("Create Team")}
                        </Button>
                    </div>
                </div>

                <div className="flex items-center space-x-2 justify-end p-3">
                    <div>{t("Show Archived")}</div>
                    <Switch
                        ripple={false}
                        containerProps={{
                            className: "w-11 h-6",
                        }}
                        circleProps={{
                            className: "before:hidden left-0.5 border-none",
                        }} 
                        onPointerEnterCapture={undefined} 
                        onPointerLeaveCapture={undefined} 
                        crossOrigin={undefined}
                        defaultChecked={includeArchived}
                        onChange={e => {

                            navigate(`/admin/teams`);                        

                            setIncludeArchived(e.currentTarget.checked);
                        }}
                    />
                </div>

                <DataTable
                    data={teams} 
                    header={_ => (
                        <tr>
                            {/* Name */}
                            <SortableTableHead 
                                onClick={e => onSort(e, "name")}
                                orderByDirection={orderBy.column === "name" 
                                    ? orderBy.direction 
                                    : undefined
                                }
                            >
                                {t("Name")}
                            </SortableTableHead>

                            {/* Age */}
                            <SortableTableHead 
                                onClick={e => onSort(e, "knisperAge")}
                                orderByDirection={orderBy.column === "knisperAge" 
                                    ? orderBy.direction 
                                    : undefined
                                }
                            >
                                {t("Age")}
                            </SortableTableHead>

                            {/* Separation */}
                            <SortableTableHead 
                                onClick={e => onSort(e, "knisperSeparation")}
                                orderByDirection={orderBy.column === "knisperSeparation" 
                                    ? orderBy.direction 
                                    : undefined
                                }
                            >
                                {t("Separation")}
                            </SortableTableHead>

                            {/* Members */}
                            <SortableTableHead 
                                onClick={e => onSort(e, "members")}
                                orderByDirection={orderBy.column === "members" 
                                    ? orderBy.direction 
                                    : undefined
                                }
                            >
                                {t("Members")}
                            </SortableTableHead>

                            {/* Created Date */}
                            <SortableTableHead 
                                onClick={e => onSort(e, "createdDate")}
                                orderByDirection={orderBy.column === "createdDate" 
                                    ? orderBy.direction 
                                    : undefined
                                }
                            >
                                {t("Created Date")}
                            </SortableTableHead>

                            {/* Archived */}
                            <TableHead className="w-[32px]"></TableHead>
                            
                            {/* Buttons */}
                            <TableHead></TableHead>
                        </tr>
                    )}
                >
                    {records => (
                        <tbody>
                        {records.map((record, index) => (
                            <tr key={`admin-teams-row-${index}`} className={`${record.archived ? 'bg-curious-blue-100/50' : ''}`}>
                                <DataTableCellTypography>
                                    <span className={authenticatedUser.teamId === record.teamId ? "font-bold" : "font-normal"}>{record.name}</span>
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    {record.knisperAge}
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    {record.knisperSeparation}
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    {record.members}
                                </DataTableCellTypography>
                                <DataTableCellTypography>
                                    <DateTimeLocalize 
                                        dateTime={DateTime.fromISO(record.createdDate)} 
                                        format={"dd-MM-yyyy HH:mm:ss"}
                                    />                                    
                                </DataTableCellTypography>
                                <DataTableCell>
                                    {record.archived && (
                                        <ArchiveBoxIcon className="w-[20px] h-[20px] -mx-2" />
                                    )}
                                </DataTableCell>
                                <DataTableCell>
                                    <Button
                                        className="flex items-center gap-2"
                                        onClick={event => onOpenDialog(
                                                event, 
                                                t("Update Team"), 
                                                record
                                            )
                                        }
                                        placeholder={undefined} 
                                        onPointerEnterCapture={()=>{}} 
                                        onPointerLeaveCapture={()=>{}}
                                    >
                                        <UserGroupIcon className="stroke-white stroke-2 h-4 w-4" />
                                        {t("Edit")}
                                    </Button>
                                </DataTableCell>
                            </tr>
                        ))}
                        </tbody>
                    )}
                </DataTable>
                <PageNav 
                    pageNr={pageNr}
                    itemsPerPage={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_TEAMS_LIMIT)} 
                    itemsDisplayed={toNumber(import.meta.env.VITE_PAGENAV_ADMIN_TEAMS_RANGE)}
                    itemsTotal={teamsCount}
                    onPageChange={(pageNr) => {

                        navigate(`/admin/teams/${pageNr}`);

                        setPageNr(pageNr);
                    }}
                />
            </Hero>
        </>
    );
};
