import { HasProperty } from "packages/shared/utility/guard";

export const IsObject = (value: unknown): value is Record<string, unknown> => 
    typeof value === 'object' && !Array.isArray(value);

export const IsFunction = (value: unknown): value is Function =>
    typeof value === 'function';

export const IsNumber = (value: unknown): value is number =>
    typeof value === "number" && !Number.isNaN(value);

export const IsInteger = (value: unknown): value is number =>
    Number.isSafeInteger(value);
 
export const IsString = (value: unknown): value is string =>
    typeof value === "string";

export const IsDefined = <T>(value: T): value is NonNullable<T> => 
    value !== undefined && value !== null && !Number.isNaN(value);

export const IsAbortError = (error:unknown): error is Error => 
    HasProperty(error, "name") && error.name === "AbortError";
