import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import cookie from 'cookie';
import { store } from 'store';
import { refreshAccessTokenAsync } from 'features/users/authenticate/slice';

export const setAuthorizationHeader = (request:InternalAxiosRequestConfig) : InternalAxiosRequestConfig<any> => {

    const cookies = cookie.parse(document.cookie);

    const accessToken = cookies?.['access-token'] 
        ?? localStorage.getItem('access-token')
        ?? '';

    if (accessToken !== '') {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return request;
};

interface IQueuedAxiosRequest {
    resolve: (value?: any) => void;
    reject: (error?: any) => void;
    config: InternalAxiosRequestConfig;
};

const axiosRequestsQueue: IQueuedAxiosRequest[] = [];

let isRefreshingAccessToken = false; 

export const retryFromRefreshToken = async (error:AxiosError): Promise<AxiosResponse<any, any>> => {
    
    if (!isAxiosError(error) || error.config === undefined || ![HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.response?.status ?? 0)) {
        return Promise.reject(error);
    }

    if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true;

        try {
            await store
                .dispatch(refreshAccessTokenAsync())
                .unwrap();

            const internalRequest = setAuthorizationHeader(error.config);

            const retryResult = axios.request(internalRequest);

            axiosRequestsQueue.forEach((queued, index) => {

                axios
                    .request(internalRequest)
                    .then(response => {
                        queued.resolve(response)
                    })
                    .catch(error => queued.reject(error))

                axiosRequestsQueue.splice(index, 1);
            });

            return retryResult;
        }
        catch (error) {
            throw error;
        } 
        finally {
            isRefreshingAccessToken = false;
        }
    }

    return new Promise<
        AxiosResponse<any, any>
    >((resolve, reject) => {
        axiosRequestsQueue.push({ 
            config: error.config!, 
            resolve, 
            reject
        });
    });
};
