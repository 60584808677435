import axios, { CreateAxiosDefaults } from 'axios';
import { retryFromRefreshToken, setAuthorizationHeader } from './axios/interceptors';

const defaults:CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_API_HOST,
    responseType: "json",
    withCredentials: true,
    headers: {
        'Access-Control-Max-Age': '300' // 5min
    }
};

const backendApi = axios.create(defaults);

const backendApiAuthorize = axios.create(defaults);

backendApiAuthorize.interceptors.request.use(setAuthorizationHeader);

backendApiAuthorize.interceptors.response.use(
    async response => response, 
    retryFromRefreshToken
);

export {
    backendApi, 
    backendApiAuthorize
};
