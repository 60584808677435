import { Select, Option } from "@material-tailwind/react";
import { useDispatch, useSelector } from "app/hooks";
import { FormErrors } from "common/components/FormError";
import { Hero, HeroTitle } from "common/components/Hero";
import { DateTimeLocalize } from "features/users/authenticate/components/DateTimeLocalize";
import { updateAuthentication } from "features/users/authenticate/slice";
import { patchUserAsync } from "features/users/slice";
import { DateTime } from "luxon";
import { IUserRequest } from "packages/shared/validate/types/user";
import { isValidateErrors } from "packages/shared/validate/types/validate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ISettingsUser extends Pick<IUserRequest, 'timezone'> {}

export const SettingsScreen = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const timeZones = Intl.supportedValuesOf('timeZone');

    const user = useSelector(state => state.authenticate);

    const { register, setValue, setError, formState: { errors } } = useForm<ISettingsUser>({
        defaultValues: { 
            timezone: user.timezone 
        }
    });

    const { onChange, ...timezoneRegistration } = register("timezone");

    return (
        <Hero>
            <HeroTitle>{t("Settings")}</HeroTitle>

            <div className="flex p-2">
                <div className="flex flex-row grow justify-start space-x-1 px-1">
                    <div className="flex flex-col p-2 items-start">
                        <div>{t("Id")}:</div>
                        <div>{t("Name")}:</div>
                        <div>{t("E-mail")}:</div>
                        <div>{t("Roles")}:</div>
                        <div>{t("Created")}:</div>
                    </div>
                    <div className="flex flex-col p-2 items-start">
                        <div>{user.userId}</div>
                        <div>{user.name}</div>
                        <div>{user.email}</div>
                        <div>{user.roles?.join(', ')}</div>
                        <div>
                            <DateTimeLocalize 
                                dateTime={DateTime.fromISO(user.createdDate)} 
                                format={"dd-MM-yyyy HH:mm:ss"}
                            />  
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-end space-x-2 px-2">
                    {/*  */}
                </div>
            </div>

            <div className="flex flex-col p-2">
                <form>
                    <div className="mb-1 gap-6">
                        <Select 
                            label="timezone"
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            placeholder={undefined}
                            value={user.timezone}
                            onChange={(event) => {
                                setValue(timezoneRegistration.name, event!);
                                
                                dispatch(patchUserAsync({
                                    userId: user.userId,
                                    timezone: event!
                                }))
                                .unwrap()
                                .then(response => { 
                                    dispatch(updateAuthentication(response));
                                })
                                .catch(errors => {
                                    if (isValidateErrors(errors)) {
                                        errors.map(e => setError(e.name as keyof ISettingsUser, {
                                            type: 'api',
                                            message: t('invalid', { ns: 'form', name: e.name })
                                        }));
                                    }
                                });
                            }}
                            {...timezoneRegistration}
                        >
                            {timeZones.map(timezone => (
                                <Option key={`settings-timezone-${timezone}`} value={timezone}>{timezone}</Option>
                            ))}
                        </Select>
                    </div>
                    
                    <FormErrors errors={errors} />

                </form>
            </div>
         
        </Hero>
    );
};

export default SettingsScreen;
