import { ArrowUpTrayIcon, FolderPlusIcon } from '@heroicons/react/24/outline';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { Button, Select, Option, Card } from '@material-tailwind/react';
import { toHumanSize } from 'app/filesize';
import { useDispatch, useSelector } from 'app/hooks';
import { randomId } from 'common/random';
import { ComponentProps, RefObject } from 'react';
import { toNumber } from 'packages/shared/utility/convert';
import { addUploadError, clearUploadErrors, fetchAudioProjectByIdAsync, setActiveProjectId, uploadAudioFileAsync } from '../slice';
import { IAudioFileRequest } from 'packages/shared/validate/request/files/audiofile';
import { Duration } from 'luxon';
import { IGenericDialogRef } from 'common/components/GenericDialog';
import { IAudioProject } from 'packages/shared/entity/audioproject';
import { useTranslation } from 'react-i18next';
import { isPromiseRejected } from 'common/async';
import { v4 as uuidv4 } from 'uuid';

export interface IDragAndDropFileProps {
    inputProps?: ComponentProps<"input">;
    accept: Accept;
    maxBytes: number;
    onFileDrop: (file:File) => void;
};

interface IAudioFileDragAndDrop extends Omit<IDragAndDropFileProps, 'onFileDrop'> {
    projectDialogRef: RefObject<IGenericDialogRef<IAudioProject>>
};

export const AudioFileDragAndDrop = (props: IAudioFileDragAndDrop) => {

    const user = useSelector(store => store.authenticate);

    const activeProjectId = useSelector(store => store.files.activeProjectId);

    const dispatch = useDispatch();

    const onDrop = async <T extends File>(
        files:T[], 
        _:FileRejection[], 
        __:DropEvent
    ):Promise<void> => {

        dispatch(clearUploadErrors());

        const batchId = uuidv4();

        const uploads = files.map(file => {

            return new Promise<IAudioFileRequest>((resolve, reject) => {
                
                const uploadId = randomId();

                const userId = user.userId;
                
                const userName = user.name;

                dispatch(fetchAudioProjectByIdAsync({ 
                    audioprojectId: activeProjectId 
                }))
                .unwrap()
                .then(project => {

                    dispatch(uploadAudioFileAsync({
                        uploadId, 
                        file, 
                        audioprojectId: project.audioprojectId,
                        userId,
                        userName,
                        batchId
                    }))
                    .unwrap()
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
                })
                .catch(error => {
                    reject(error);
                });
            });
        });

        const results = await Promise.allSettled(uploads);

        results
            .filter(isPromiseRejected)
            .map(r => {
                dispatch(addUploadError(r.reason as Error)); 
            });

        //const fullfilled = results
        //    .filter(isPromiseFulfilled);
    };

    const { 
        getRootProps,
        acceptedFiles,
		fileRejections
    } = useDropzone({
        onDrop: onDrop,
        accept: props?.accept,
        maxSize: props?.maxBytes
    });

    const projects = useSelector(state => state.files.projects);

    const { t } = useTranslation();

    const uploadLimit = user.roles?.includes(import.meta.env.VITE_DEMO_ROLE) 
        ? t("Upload limit", { minutes: Duration.fromISOTime(import.meta.env.VITE_DEMO_CONVERSION_DURATION_LIMIT).toFormat("mm:ss") })
        : toHumanSize(props.maxBytes);

    return (
        <div className="grid grid-cols-[auto_250px] space-x-2 m-1">
            <Card {...getRootProps({ 
                    className: "p-2 border-dashed border-2 border-curious-blue-100"
                })} 
                placeholder={undefined} 
                onPointerEnterCapture={undefined} 
                onPointerLeaveCapture={undefined}
            >
                <div className="flex flex-col min-h-[125px] p-3 items-center justify-center">
                    <ArrowUpTrayIcon className="h-12 w-12 text-gray-700 m-auto" />

                    <p>{t("Drag files here")}</p>
                    <div className="text-sm text-gray-300">
                        {t("Max Limit", { limit: uploadLimit })}
                    </div>
                </div>

                <div className="flex items-center"> 
                    <hr className="flex-grow border-curious-blue-300" /> 
                    <span className="px-3 text-gray-500"> 
                        {t("or")}
                    </span>
                    <hr className="flex-grow border-t border-curious-blue-300" /> 
                </div>

                <div className="flex min-h-[75px] p-3 items-center justify-center">
                    <Button 
                        placeholder={undefined} 
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}}
                    >
                        {t("Browse")}
                    </Button>
                </div>
                {/*
                <div>
                    Accepted
                    {acceptedFiles.map(file => (
                        <div>{JSON.stringify(file)}</div>
                    ))}
                </div>
                <div>
                    Rejected
                    {fileRejections.map(({ file, errors }) => (
                        <>
                            <div>{JSON.stringify(file)}</div>
                            <div>{JSON.stringify(errors)}</div>
                        </>
                    ))}
                </div>
                */}
            </Card>
            <Card 
                className="p-2 border-2 border-curious-blue-100" 
                placeholder={undefined} 
                onPointerEnterCapture={undefined} 
                onPointerLeaveCapture={undefined}
            >
                <div className="flex min-h-[125px] p-3 items-center justify-center">
                    { projects.length > 0 && (
                        <Select 
                            label={t("Select project")} 
                            placeholder={undefined} 
                            onPointerEnterCapture={()=>{ }} 
                            onPointerLeaveCapture={()=>{ }}
                            onClick={(event) => { 
                                event.stopPropagation();
                            }}
                            onChange={(value?: string) => {
                                dispatch(setActiveProjectId(toNumber(value)));
                            }}
                            value={ activeProjectId.toString() }
                            className="self-center"
                        >
                        {
                            projects
                                .filter(p => p.archived === false)
                                .map(project => (
                                    <Option 
                                        key={`project-${project.audioprojectId}`} 
                                        value={`${project.audioprojectId}`}
                                        onClick={event => event.stopPropagation()}
                                    >
                                        {project.name}
                                    </Option>
                                )
                            )
                        }
                        </Select>
                    )}
                </div>
                <div className="flex items-center"> 
                    <hr className="flex-grow border-t border-curious-blue-300" /> 
                    <span className="px-3 text-gray-500"> 
                        {t("or")}
                    </span>
                    <hr className="flex-grow border-t border-curious-blue-300" /> 
                </div>
                <div className="min-h-[75px] p-3 flex items-center justify-center">
                    <Button
                        className="flex items-center gap-2"
                        onClick={() => {
                            props.projectDialogRef.current?.initialize(t('Create'));
                        }}
                        placeholder={undefined} 
                        onPointerEnterCapture={()=>{}} 
                        onPointerLeaveCapture={()=>{}}
                    >
                        <FolderPlusIcon className="stroke-white stroke-2 h-4 w-4" />
                        {t("Create Project")}
                    </Button>
                </div>
            </Card>
        </div>
    );
};
