import { ComponentProps } from 'react';
import '/static/assets/style/audus/knisper-logo.scss';

export interface SvgProps extends ComponentProps<"svg"> {}

export const KnisperLogo = (props: SvgProps) => {

    const defaultProps:ComponentProps<"svg"> = {
        className:"knisper-logo",
        version:"1.1",
        viewBox:"0 0 603 208.33",
        xmlns: "http://www.w3.org/2000/svg",
        xmlnsXlink: "http://www.w3.org/1999/xlink",
        id: "Laag_2"
    };

    return (
        <svg {...{
            ...defaultProps,
            ...props,
            ...{
                className: `${defaultProps.className} ${props.className ??''}`
            }
        }}>
            <defs>
                <style>{`
                    .cls-1 { fill:url(#linear-gradient); }
                    .cls-2 { fill:#008ecf; }
                    .cls-3 { fill:url(#linear-gradient-2); }
                    .cls-4 { fill:url(#linear-gradient-3); }
                    .cls-5 { fill:url(#linear-gradient-4); }
                    .cls-6 { fill:url(#linear-gradient-5); }
                    .cls-7 { fill:url(#linear-gradient-6); }
                    .cls-8 { fill:#ed6e9c;}
                `}</style>
                <linearGradient id="linear-gradient" x1="330.49" y1="15.31" x2="379.83" y2="199.44" gradientTransform="matrix(1, 0, 0, -1, 0, 207.89)" gradientUnits="userSpaceOnUse">
                    <stop offset="0.2" stopColor="#0056a4"/>
                    <stop offset="0.74" stopColor="#008ecf"/>
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="36.83" y1="38.99" x2="71.69" y2="73.85" gradientTransform="matrix(1, 0, 0, -1, 0, 112.84)" xlinkHref="#linear-gradient"/>
                <linearGradient id="linear-gradient-3" x1="29.38" y1="46.26" x2="49.7" y2="66.58" gradientTransform="matrix(1, 0, 0, -1, 0, 112.84)" xlinkHref="#linear-gradient"/>
                <linearGradient id="linear-gradient-4" x1="19.33" y1="50.93" x2="30.31" y2="61.9" gradientTransform="matrix(1, 0, 0, -1, 0, 112.84)" xlinkHref="#linear-gradient"/>
                <linearGradient id="linear-gradient-5" x1="58.82" y1="46.26" x2="79.14" y2="66.58" gradientTransform="matrix(1, 0, 0, -1, 0, 112.84)" xlinkHref="#linear-gradient"/>
                <linearGradient id="linear-gradient-6" x1="78.2" y1="145.99" x2="89.18" y2="156.96" gradientTransform="translate(0 -95.05)" xlinkHref="#linear-gradient"/>
            </defs>
            <g id="Laag_1-2" data-name="Laag 1-2">
                <path className="cls-1" shapeRendering="optimizeQuality" d="M127.88,40.13H145V94.25h2.45l22.32-25.68h22.32L160.9,102.2l32.41,36.08h-22l-23.85-27.52H145v27.52H127.88Zm77.05,28.74v69.71h17.12V103.11c0-12.84,6.42-20.48,16.82-20.48,9.17,0,14.37,5.2,14.37,15.59v40.36h17.12V97c0-17.12-10.4-29-26.29-29-12.23,0-17.73,5.2-19.87,10.09h-2.45V68.87Zm83.77,69.41h17.12V68.88H288.7Zm64.51,2.14c17.12,0,28.43-8.56,28.43-22.93s-11.92-19-27.21-21.71l-3.67-.61c-6.42-.92-10.09-2.75-10.09-7.34,0-4.28,3.67-6.73,10.09-6.73s11.31,2.75,12.84,9.78l15.9-5.2C376.14,74.67,366.05,67,350.76,67s-26.6,7.64-26.6,21.4,11.92,19,26,21.71l3.67.61c7.95,1.53,11.31,3.36,11.31,7.64S361.46,126,353.51,126s-14.37-3.06-16.2-12.84l-15.9,4.28c2.14,14.68,14.06,22.93,31.8,22.93Zm43.11-71.55V161.4h17.12V130h2.45c3.06,4.89,8.87,10.09,21.1,10.09,16.51,0,30.57-12.84,30.57-35.47V102.5C467.56,79.87,453.19,67,437,67c-12.23,0-18,5.2-21.1,10.4h-2.45V68.87Zm35.77,55.95c-10.7,0-18.65-7-18.65-20.48v-1.53c0-13.45,8.26-20.48,18.65-20.48s18.65,7,18.65,20.48v1.53C450.74,117.79,442.79,124.82,432.09,124.82ZM515,140.42c18.34,0,26.6-11,30.27-18.34l-14.06-7.64c-2.45,5.2-6.42,10.4-15.9,10.4s-16.82-6.73-17.12-16.2h49.22v-6.11c0-21.1-13.15-35.77-33-35.77S480.4,80.82,480.4,102.53v1.83c0,22,13.76,36.08,34.55,36.08ZM497.83,95.47c1.22-8.26,7-13.45,16.2-13.45s14.68,5.5,15.59,13.45H497.83Zm64.51,42.81h17.12V98.84c0-9.78,6.11-14.68,15-14.68H603V68.26h-8.26c-6.42,0-11.31,2.75-13.15,8.26h-2.45V68.88H562.32v69.4Zm-272.72-81A11.24,11.24,0,1,0,297.57,38a10.28,10.28,0,0,0-7.95,3.67,11.1,11.1,0,0,0-3.36,8A10.41,10.41,0,0,0,289.62,57.25Z" transform="translate(0 0)"/>
                <path className="cls-2" shapeRendering="optimizeQuality" d="M33.12,160.12c-18.88,0-33.12-15-33.12-34.87V97.8a4.5,4.5,0,0,1,9,0v27.45C9,140,19.37,151.12,33.12,151.12S57.73,140,57.73,125.25c0-15.87,9.61-22.58,18.09-28.49l.75-.52a62.16,62.16,0,0,0,15.11-15c3.34-4.73,4.89-10.26,6-14.3.14-.5.27-1,.4-1.42a40.4,40.4,0,0,0,1.4-11.24A45.26,45.26,0,1,0,9,54.26v20a4.5,4.5,0,0,1-9,0v-20a54.26,54.26,0,0,1,108.52,0A48.72,48.72,0,0,1,106.76,68c-.12.42-.25.87-.38,1.35-1.24,4.41-3.1,11.06-7.34,17.06a71.33,71.33,0,0,1-17.33,17.21l-.74.51c-8,5.55-14.24,9.93-14.24,21.11,0,19.55-14.76,34.87-33.61,34.87Z" transform="translate(0 0)"/>
                <path className="cls-3 line" shapeRendering="optimizeQuality" d="M58.76,27.93v57a4.5,4.5,0,0,1-9,0v-57a4.5,4.5,0,0,1,9,0Z" transform="translate(0 0)"/>
                <path className="cls-4 line" shapeRendering="optimizeQuality" d="M39.54,38a4.49,4.49,0,0,0-4.5,4.5v27.9a4.5,4.5,0,0,0,9,0V42.47A4.49,4.49,0,0,0,39.54,38Z" transform="translate(0 0)"/>
                <path className="cls-5 line" shapeRendering="optimizeQuality" d="M24.82,47.32a4.49,4.49,0,0,0-4.5,4.5V61a4.5,4.5,0,0,0,9,0V51.82A4.49,4.49,0,0,0,24.82,47.32Z" transform="translate(0 0)"/>
                <path className="cls-6 line" shapeRendering="optimizeQuality" d="M69,38a4.49,4.49,0,0,0-4.5,4.5v27.9a4.5,4.5,0,0,0,9,0V42.47A4.49,4.49,0,0,0,69,38Z" transform="translate(0 0)"/>
                <path className="cls-7 line" shapeRendering="optimizeQuality" d="M83.69,47.32a4.49,4.49,0,0,0-4.5,4.5V61a4.5,4.5,0,0,0,9,0V51.82A4.49,4.49,0,0,0,83.69,47.32Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M149.94,198.7H145.7V185.4a10.16,10.16,0,0,0-.36-2.81,6.6,6.6,0,0,0-1.22-2.32,6,6,0,0,0-4.49-2.12,6.46,6.46,0,0,0-5,1.75,7.62,7.62,0,0,0-1.64,2.47,10.14,10.14,0,0,0-.69,3v13.3H128v-33.6h4.24v13a8.36,8.36,0,0,1,3-2.65,10.13,10.13,0,0,1,4.37-1.1,10.51,10.51,0,0,1,4.52.83,9.2,9.2,0,0,1,3.56,2.77A11.87,11.87,0,0,1,150,185.4v13.3h0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M181.49,186.71a11.33,11.33,0,0,1-.16,1.84H159.18c1,3.84,5,6.73,9.88,6.73a10.83,10.83,0,0,0,8-3.29l3.93,1.67a15.06,15.06,0,0,1-11.9,5.47c-7.92,0-14.36-5.57-14.36-12.42s6-12.42,13.39-12.42,13.39,5.57,13.39,12.42ZM177,184.87a9.3,9.3,0,0,0-17.88,0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M212.88,174.71v24h-4.24v-4.35c-.22.27-.44.53-.68.79a12.72,12.72,0,0,1-4.1,2.87,13.49,13.49,0,0,1-5.13,1.11,14.47,14.47,0,0,1-5.22-.92,13.3,13.3,0,0,1-4.36-2.75,11.94,11.94,0,0,1,0-17.48,13.46,13.46,0,0,1,4.36-2.75,14.45,14.45,0,0,1,5.21-.92,13.57,13.57,0,0,1,5.13,1.11,12.67,12.67,0,0,1,4.1,2.87,10.13,10.13,0,0,1,.68.79v-4.35h4.24Zm-4.24,12a8.21,8.21,0,0,0-3.1-6.31,10.34,10.34,0,0,0-6.8-2.26,9.74,9.74,0,0,0-6.46,2.57,8,8,0,0,0,0,12,9.45,9.45,0,0,0,3,1.86,9.67,9.67,0,0,0,3.5.71,10.34,10.34,0,0,0,6.8-2.26,8.16,8.16,0,0,0,3.1-6.3Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M235.22,175l-1,4.1a7.3,7.3,0,0,0-8.11.79,7.38,7.38,0,0,0-1.69,2.48,9.51,9.51,0,0,0-.65,3.08l0,.44V198.7h-4.24v-24h4.24v3.93c0-.06.06-.13.1-.19a8.08,8.08,0,0,1,3.06-2.9,9.54,9.54,0,0,1,2.1-.82,12.25,12.25,0,0,1,2.22-.32,10.66,10.66,0,0,1,4,.59Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M278.43,186.71a11.33,11.33,0,0,1-.16,1.84H256.12c1,3.84,5,6.73,9.88,6.73a10.83,10.83,0,0,0,8-3.29l3.93,1.67a15.06,15.06,0,0,1-11.9,5.47c-7.92,0-14.36-5.57-14.36-12.42s6-12.42,13.39-12.42,13.39,5.57,13.39,12.42ZM274,184.87a9.3,9.3,0,0,0-17.88,0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M303.58,174.71l-9.09,24h-5.15l-9.1-24h4.48l7.19,19,7.19-19Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M332.11,186.71a11.33,11.33,0,0,1-.16,1.84H309.8c1,3.84,5,6.73,9.88,6.73a10.83,10.83,0,0,0,8-3.29l3.93,1.67a15.06,15.06,0,0,1-11.9,5.47c-7.92,0-14.36-5.57-14.36-12.42s6-12.42,13.39-12.42,13.39,5.57,13.39,12.42Zm-4.46-1.84a9.3,9.3,0,0,0-17.88,0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M353.08,175l-1,4.1a7.3,7.3,0,0,0-8.11.79,7.38,7.38,0,0,0-1.69,2.48,9.51,9.51,0,0,0-.65,3.08l0,.44V198.7h-4.24v-24h4.24v3.93c0-.06.06-.13.1-.19a8.08,8.08,0,0,1,3.06-2.9,9.54,9.54,0,0,1,2.1-.82,12.25,12.25,0,0,1,2.22-.32,10.66,10.66,0,0,1,4,.59Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M382,174.73l-13,28.27-2.45,5.33H361.9L366.65,198l-10.71-23.28h4.59l8.28,19,2-4.86,6.52-14.17H382Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M434.75,174.73l-11,24h-3.91L416,189.38l-3.82,9.35h-3.91l-11-24h4.59L410.22,193l3.48-7.57-4.91-10.69h4.59L416,181.5l2.61-6.77h4.59l-4.91,10.69,3.47,7.57,8.39-18.26h4.61Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M460.58,198.7h-4.24V185.4a10.16,10.16,0,0,0-.36-2.81,6.6,6.6,0,0,0-1.22-2.32,6,6,0,0,0-4.49-2.12,6.46,6.46,0,0,0-5,1.75,7.62,7.62,0,0,0-1.64,2.47,10.14,10.14,0,0,0-.69,3v13.3h-4.24v-33.6h4.24v13a8.36,8.36,0,0,1,3-2.65,10.13,10.13,0,0,1,4.37-1.1,10.51,10.51,0,0,1,4.52.83,9.2,9.2,0,0,1,3.56,2.77,11.87,11.87,0,0,1,2.23,7.48v13.3h0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M466,168a2.93,2.93,0,1,1,2.93,2.93A2.93,2.93,0,0,1,466,168Zm.82,30.67v-24h4.24v24Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M495.12,192a6,6,0,0,1-2.12,4.8,10.33,10.33,0,0,1-6.87,2.32h-.58c-4.68-.2-8.46-2.85-9.61-6.75l4.09-1c.81,2.73,3.49,3.81,5.72,3.9a6.2,6.2,0,0,0,4.29-1.21,2.39,2.39,0,0,0,.85-1.94c-.07-2.22-2.42-3.15-5.26-4.06-.41-.13-.78-.25-1.1-.37l-.54-.19c-2.15-.74-7.2-2.46-6.83-7,.24-3,2.95-6.17,8.33-6.17,4.22,0,7.33,1.78,8.77,5l-3.93,1.44c-.29-.65-1.16-2.61-4.84-2.61-.16,0-3.89,0-4.1,2.6-.12,1.41,1.48,2.25,4.09,3.14l.63.22c.27.1.59.2.94.31,2.57.83,7.93,2.54,8.08,7.59Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M527.84,186.71a12,12,0,0,1-3.81,8.74,13.3,13.3,0,0,1-4.36,2.75,14.47,14.47,0,0,1-5.22.92,13.53,13.53,0,0,1-5.13-1.11,12.83,12.83,0,0,1-4.1-2.87c-.24-.25-.46-.52-.68-.79v14H500.3V174.7h4.24v4.35c.22-.27.44-.54.68-.79a12.83,12.83,0,0,1,4.1-2.87,13.49,13.49,0,0,1,5.13-1.11,14.24,14.24,0,0,1,5.21.92A13.3,13.3,0,0,1,524,178a12,12,0,0,1,3.81,8.74Zm-4.21,0a8.2,8.2,0,0,0-2.71-6,9.77,9.77,0,0,0-6.46-2.57,10.4,10.4,0,0,0-6.8,2.26,8,8,0,0,0,0,12.61,10.36,10.36,0,0,0,6.8,2.26,9.63,9.63,0,0,0,3.5-.71,9.21,9.21,0,0,0,3-1.86,8.26,8.26,0,0,0,2.71-6Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M558.45,186.71a11.33,11.33,0,0,1-.16,1.84H536.14c1,3.84,5,6.73,9.88,6.73a10.83,10.83,0,0,0,8-3.29l3.93,1.67a15.06,15.06,0,0,1-11.9,5.47c-7.92,0-14.36-5.57-14.36-12.42s6-12.42,13.39-12.42,13.39,5.57,13.39,12.42ZM554,184.87a9.3,9.3,0,0,0-17.88,0Z" transform="translate(0 0)"/>
                <path className="cls-8" d="M579.42,175l-1,4.1a7.3,7.3,0,0,0-8.11.79,7.38,7.38,0,0,0-1.69,2.48,9.51,9.51,0,0,0-.65,3.08l0,.44V198.7h-4.24v-24H568v3.93c0-.06.06-.13.1-.19a8.08,8.08,0,0,1,3.06-2.9,9.54,9.54,0,0,1,2.1-.82,12.25,12.25,0,0,1,2.22-.32,10.66,10.66,0,0,1,4,.59Z" transform="translate(0 0)"/>
            </g>
        </svg>
    );
};

export const KnisperIcon = (props: SvgProps) => {

    const defaultProps = {
        className:"knisper-icon",
        version:"1.1",
        viewBox:"0 0 49 49"
    };

    return (
        <svg {...{
            ...defaultProps,
            ...props,
            ...{
                className: `${defaultProps.className} ${props.className ??''}`
            }
        }}>
            <g>
                <path shapeRendering="optimizeQuality" className="line" d="M18.9,17.4v2.5c0,0.4-0.2,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.3c-0.3,0-0.7-0.1-0.9-0.4
                    c-0.3-0.2-0.4-0.6-0.4-0.9v-2.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.4s0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.1
                    c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.4C18.8,17,18.9,17.2,18.9,17.4z"/>
                <path shapeRendering="optimizeQuality" className="line" d="M22.3,15.3v7c0,0.4-0.2,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.3c-0.3,0-0.7-0.1-0.9-0.3c-0.3-0.2-0.4-0.6-0.4-0.9
                    v-7c0-0.4,0.2-0.7,0.4-0.9c0.3-0.2,0.6-0.4,0.9-0.3c0.3,0,0.7,0.1,0.9,0.3C22.1,14.6,22.3,14.9,22.3,15.3z"/>
                <path shapeRendering="optimizeQuality" className="line" d="M25.7,11.8V26c0,0.3-0.2,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4
                    c-0.3-0.2-0.4-0.6-0.4-0.9V11.8c0-0.3,0.2-0.7,0.4-0.9c0.2-0.3,0.5-0.4,0.9-0.4c0.3,0,0.7,0.1,0.9,0.4
                    C25.6,11.1,25.7,11.4,25.7,11.8z"/>
                <path shapeRendering="optimizeQuality" className="line" d="M29.2,14.3V23c0,0.3-0.2,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.4s-0.7-0.1-0.9-0.4c-0.3-0.2-0.4-0.6-0.4-0.9v-8.6
                    c0-0.3,0.2-0.7,0.4-0.9c0.3-0.2,0.6-0.4,0.9-0.4s0.7,0.1,0.9,0.4C29,13.7,29.2,14,29.2,14.3z"/>
                <path shapeRendering="optimizeQuality" className="line" d="M32.6,17.8v2c0,0.4-0.2,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4c-0.3-0.2-0.4-0.6-0.4-0.9
                    v-2c0-0.4,0.2-0.7,0.4-0.9c0.3-0.2,0.6-0.4,0.9-0.4c0.3,0,0.7,0.1,0.9,0.4C32.4,17.2,32.6,17.5,32.6,17.8z"/>
                <path d="M39.5,18.2c0,3-0.9,5.9-2.4,8.4s-3.9,4.5-6.5,5.7c-0.6,0.3-1.2,0.8-1.5,1.4c-0.4,0.6-0.6,1.3-0.6,1.9v1
                    c0,2.4-0.9,4.9-2.5,6.6c-1.6,1.8-3.9,2.9-6.2,3.1c-2.4,0.2-4.8-0.6-6.6-2c-1.8-1.6-3.1-3.7-3.5-6.1V38l-0.1-5.8V30
                    c0-0.7,0.3-1.3,0.7-1.7c0.5-0.5,1.1-0.7,1.6-0.7c0.6,0,1.3,0.3,1.6,0.7c0.5,0.5,0.7,1.1,0.7,1.7v2.4l0.1,4.9c0.2,1.2,0.9,2.3,1.8,3
                    c1,0.8,2.1,1.1,3.3,1c1.2-0.1,2.3-0.7,3.1-1.6c0.8-0.9,1.3-2,1.4-3.3v-1c0-1.6,0.5-3.2,1.4-4.6c0.9-1.4,2.1-2.4,3.5-3.1
                    c1.7-0.8,3.2-2,4.3-3.6s1.7-3.4,1.8-5.2c0.1-1.8-0.2-3.8-1-5.5s-2-3.2-3.6-4.3s-3.6-1.5-5.4-1.6c-1.8-0.1-3.7,0.4-5.3,1.3
                    s-3,2.2-4,3.9s-1.5,3.5-1.5,5.4v4.1c0,0.7-0.3,1.3-0.7,1.7c-0.5,0.5-1.1,0.7-1.6,0.7c-0.6,0-1.3-0.3-1.6-0.7
                    c-0.4-0.5-0.7-1.2-0.7-1.7v-4.1c0-2,0.3-4.1,1.1-6c0.8-1.8,1.8-3.6,3.2-5c1.5-1.5,3.1-2.6,4.9-3.4s3.8-1.2,5.8-1.2s4,0.4,5.8,1.2
                    c1.8,0.8,3.5,1.9,4.9,3.4s2.5,3.2,3.2,5.1C39.2,14.2,39.6,16.2,39.5,18.2z"/>
            </g>
        </svg>
    );
};
