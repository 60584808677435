import { useDispatch, useSelector } from "app/hooks";
import { Input } from "@material-tailwind/react";
import { FormError } from "common/components/FormError";
import { IGenericDialogWrapperProps } from "common/components/GenericDialog";
import { DialogForm } from "common/components/DialogForm";
import { createAudioProjectAsync, patchAudioProjectAsync } from "../../slice";
import { IAudioProject } from "packages/shared/entity/audioproject";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface IAudioProjectFormProps extends IGenericDialogWrapperProps<IAudioProject> {
    buttonText?: string;
};

export const AudioProjectForm = (props:IAudioProjectFormProps) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [ name, setName ] = useState(""); 

    const [ isDisabled, setIsDisabled ] = useState<boolean|undefined>(undefined); 

    const projects = useSelector(state => state.files.projects);

    useEffect(() => {
        setIsDisabled(!name || !!projects.find(p => p.name.toLowerCase() === name.trim().toLowerCase()));
    }, [name]);

    return (
        <DialogForm 
            data={props.data}
            buttonDisabled={isDisabled}
            onCreate={(data, setValidationErrors) => {
                
                dispatch(createAudioProjectAsync(data))
                    .unwrap()
                    .then(_ => { 

                        navigate('/files/1');

                        props.setDialogOpen(false);
                    })
                    .catch(error => { 
                        setValidationErrors(error);
                    });
            }} 
            onUpdate={(data, setValidationErrors) => {
                
                dispatch(patchAudioProjectAsync(data))
                    .unwrap()
                    .then(_ => { 
                        props.setDialogOpen(false);
                    })
                    .catch(error => {
                        setValidationErrors(error);
                    });
            }}
        >
        {(register, setValue, errors) => {

            return (
                <>
                    <Input
                        onPointerEnterCapture={() => {}} 
                        onPointerLeaveCapture={() => {}} 
                        id="project-name"
                        size="lg"
                        error={false}
                        className="focus:outline-none focus:ring-0"
                        autoComplete="false"
                        label="Project name"
                        crossOrigin={undefined}
                        {...register("name")} 
                        onChange={(e) => { setName(e.currentTarget.value); }}
                    />
                    <FormError error={errors.name} />
                </>
            )
        }}
        </DialogForm>
    );
};
