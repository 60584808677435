import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FieldErrors } from "react-hook-form";

export const FormError = (props:{ error?:any }) => {

	return props.error !== undefined 
		? (
			<div className="flex p-1">
				<div className="flex flex-row justify-start space-x-2 px-1">
					<ExclamationTriangleIcon className="my-auto w-5 h-5 stroke-red-400" />
				</div>
				<div className="flex grow justify-start px-2 text-xs my-auto text-red-400">
					{props.error?.message}
				</div>
			</div>
		)
		: null;
};

interface IFormErrorsProps {
	errors: any[]|FieldErrors;
};

export const FormErrors = (props: IFormErrorsProps) => {

	const hasMultipleErrors = Object.keys(props.errors)?.length > 1;

	return Object.keys(props.errors).length > 0
		? (
			<div className="flex p-1 justify-center">
				<div className="flex flex-row justify-start space-x-2 px-1">
					<ExclamationTriangleIcon className="my-auto w-5 h-5 stroke-red-400" />
				</div>
				<div className="flex justify-start px-2 text-xs my-auto text-red-400">
					<ul 
						className={"pl-5" + (hasMultipleErrors
							? "list-disc " 
							: "list-none")}
					>
					{Object.entries(props.errors).map(([key, error], i) => (
					    <li className="p-1" key={`error-${key}-${i}`}>{error?.message}</li>
					))}
					</ul>
				</div>
			</div>
		)
		: null;
};
