export enum PromiseState {
    Pending   = "pending",
    Fulfilled = "fulfilled",
    Rejected  = "rejected"
};

export const isPromiseRejected = <T,>(result:PromiseSettledResult<T>) : result is PromiseRejectedResult => {
    return result.status === 'rejected';
};

export const isPromiseFulfilled = <T,>(result:PromiseSettledResult<T>) : result is PromiseFulfilledResult<T> => {
    return result.status === 'fulfilled';
};
