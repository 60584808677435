import typia from "typia";
import { IFile } from "../../../entity/file";
import { IAudioFile } from "../../../entity/audiofile";
import { IAudioConversionRequest } from "./audioconversion";
import { IUserRequest } from "../../types/user";
export const isAudioFile = (input: any): input is IAudioFile => {
    const $io0 = (input: any): boolean => "number" === typeof input.audiofileId && "number" === typeof input.userId && "number" === typeof input.audioprojectId && "number" === typeof input.teamId && "string" === typeof input.filename && ("object" === typeof input.duration && null !== input.duration && true) && (null === input.channels || "number" === typeof input.channels) && (null === input.bitrate || "number" === typeof input.bitrate) && (null === input.samplerate || "number" === typeof input.samplerate) && (null === input.samples || "number" === typeof input.samples) && (null === input.lossless || "boolean" === typeof input.lossless) && (null === input.archived || "boolean" === typeof input.archived) && (Array.isArray(input.conversions) && input.conversions.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && "string" === typeof input.filenameOriginal && "number" === typeof input.size && "string" === typeof input.type && ("object" === typeof input.modifiedDate && null !== input.modifiedDate && true) && ("object" === typeof input.createdDate && null !== input.createdDate && true);
    const $io2 = (input: any): boolean => "number" === typeof input.audioprojectId && "number" === typeof input.audioconversionId && "number" === typeof input.audiofileId && ("object" === typeof input.duration && null !== input.duration && true) && "number" === typeof input.userId && "number" === typeof input.teamId && input.createdDate instanceof Date && "string" === typeof input.knisperState && (null === input.knisperHandle || "string" === typeof input.knisperHandle) && ("30" === input.knisperAge || "40" === input.knisperAge || "50" === input.knisperAge || "60" === input.knisperAge || "70" === input.knisperAge) && "number" === typeof input.knisperSeparation && "boolean" === typeof input.archived && "number" === typeof input.size && (null === input.resultUri || "string" === typeof input.resultUri);
    return "object" === typeof input && null !== input && $io0(input);
};
export interface IAudioFileUpload extends Omit<IFile, 'createdDate' | 'modifiedDate'> {
    uploadId: number;
    userId: IUserRequest['userId'];
    userName: IUserRequest['name'];
    progress: number;
    loaded: number;
    createdDate: string;
    modifiedDate: string;
}
;
export const isAudioFileUpload = (input: any): input is IAudioFileUpload => {
    const $io0 = (input: any): boolean => "number" === typeof input.uploadId && ("number" === typeof input.userId && 1 <= input.userId) && ("string" === typeof input.userName && /^[A-Za-z0-9]+(?:[ _-]{1,}[A-Za-z0-9]+)*$/.test(input.userName)) && "number" === typeof input.progress && "number" === typeof input.loaded && "string" === typeof input.createdDate && "string" === typeof input.modifiedDate && "string" === typeof input.filenameOriginal && "number" === typeof input.size && "string" === typeof input.type;
    return "object" === typeof input && null !== input && $io0(input);
};
export const validateAudioFileUpload = (input: any): typia.IValidation<IAudioFileUpload> => {
    const errors = [] as any[];
    const __is = (input: any): input is IAudioFileUpload => {
        const $io0 = (input: any): boolean => "number" === typeof input.uploadId && ("number" === typeof input.userId && 1 <= input.userId) && ("string" === typeof input.userName && /^[A-Za-z0-9]+(?:[ _-]{1,}[A-Za-z0-9]+)*$/.test(input.userName)) && "number" === typeof input.progress && "number" === typeof input.loaded && "string" === typeof input.createdDate && "string" === typeof input.modifiedDate && "string" === typeof input.filenameOriginal && "number" === typeof input.size && "string" === typeof input.type;
        return "object" === typeof input && null !== input && $io0(input);
    };
    if (false === __is(input)) {
        const $report = (typia.createValidate as any).report(errors);
        ((input: any, _path: string, _exceptionable: boolean = true): input is IAudioFileUpload => {
            const $vo0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ["number" === typeof input.uploadId || $report(_exceptionable, {
                    path: _path + ".uploadId",
                    expected: "number",
                    value: input.uploadId
                }), "number" === typeof input.userId && (1 <= input.userId || $report(_exceptionable, {
                    path: _path + ".userId",
                    expected: "number & Minimum<1>",
                    value: input.userId
                })) || $report(_exceptionable, {
                    path: _path + ".userId",
                    expected: "(number & Minimum<1>)",
                    value: input.userId
                }), "string" === typeof input.userName && (/^[A-Za-z0-9]+(?:[ _-]{1,}[A-Za-z0-9]+)*$/.test(input.userName) || $report(_exceptionable, {
                    path: _path + ".userName",
                    expected: "string & IsAlphaNumericWithSpace",
                    value: input.userName
                })) || $report(_exceptionable, {
                    path: _path + ".userName",
                    expected: "(string & IsAlphaNumericWithSpace)",
                    value: input.userName
                }), "number" === typeof input.progress || $report(_exceptionable, {
                    path: _path + ".progress",
                    expected: "number",
                    value: input.progress
                }), "number" === typeof input.loaded || $report(_exceptionable, {
                    path: _path + ".loaded",
                    expected: "number",
                    value: input.loaded
                }), "string" === typeof input.createdDate || $report(_exceptionable, {
                    path: _path + ".createdDate",
                    expected: "string",
                    value: input.createdDate
                }), "string" === typeof input.modifiedDate || $report(_exceptionable, {
                    path: _path + ".modifiedDate",
                    expected: "string",
                    value: input.modifiedDate
                }), "string" === typeof input.filenameOriginal || $report(_exceptionable, {
                    path: _path + ".filenameOriginal",
                    expected: "string",
                    value: input.filenameOriginal
                }), "number" === typeof input.size || $report(_exceptionable, {
                    path: _path + ".size",
                    expected: "number",
                    value: input.size
                }), "string" === typeof input.type || $report(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })].every((flag: boolean) => flag);
            return ("object" === typeof input && null !== input || $report(true, {
                path: _path + "",
                expected: "IAudioFileUpload",
                value: input
            })) && $vo0(input, _path + "", true) || $report(true, {
                path: _path + "",
                expected: "IAudioFileUpload",
                value: input
            });
        })(input, "$input", true);
    }
    const success = 0 === errors.length;
    return {
        success,
        errors,
        data: success ? input : undefined
    } as any;
};
export interface IAudioFileRequest extends Omit<IAudioFile, 'modifiedDate' | 'createdDate' | 'duration' | 'conversions'> {
    modifiedDate: string;
    createdDate: string;
    duration: string;
    // mapped
    userName?: string;
    conversions: Array<IAudioConversionRequest>;
}
;
export const isAudioFileRequest = (input: any): input is IAudioFileRequest => {
    const $io0 = (input: any): boolean => "string" === typeof input.modifiedDate && "string" === typeof input.createdDate && "string" === typeof input.duration && (undefined === input.userName || "string" === typeof input.userName) && (Array.isArray(input.conversions) && input.conversions.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && "number" === typeof input.teamId && (null === input.archived || "boolean" === typeof input.archived) && "number" === typeof input.userId && "number" === typeof input.audioprojectId && "number" === typeof input.audiofileId && "string" === typeof input.filenameOriginal && "number" === typeof input.size && "string" === typeof input.type && "string" === typeof input.filename && (null === input.channels || "number" === typeof input.channels) && (null === input.bitrate || "number" === typeof input.bitrate) && (null === input.samplerate || "number" === typeof input.samplerate) && (null === input.samples || "number" === typeof input.samples) && (null === input.lossless || "boolean" === typeof input.lossless);
    const $io1 = (input: any): boolean => "string" === typeof input.createdDate && "string" === typeof input.duration && (undefined === input.userName || "string" === typeof input.userName) && "number" === typeof input.teamId && ("30" === input.knisperAge || "40" === input.knisperAge || "50" === input.knisperAge || "60" === input.knisperAge || "70" === input.knisperAge) && "number" === typeof input.knisperSeparation && "boolean" === typeof input.archived && "number" === typeof input.userId && "number" === typeof input.audioprojectId && "number" === typeof input.audiofileId && "number" === typeof input.size && "number" === typeof input.audioconversionId && "string" === typeof input.knisperState && (null === input.knisperHandle || "string" === typeof input.knisperHandle) && (null === input.resultUri || "string" === typeof input.resultUri);
    return "object" === typeof input && null !== input && $io0(input);
};
export const validateAudioFileRequest = (input: any): typia.IValidation<IAudioFileRequest> => {
    const errors = [] as any[];
    const __is = (input: any): input is IAudioFileRequest => {
        const $io0 = (input: any): boolean => "string" === typeof input.modifiedDate && "string" === typeof input.createdDate && "string" === typeof input.duration && (undefined === input.userName || "string" === typeof input.userName) && (Array.isArray(input.conversions) && input.conversions.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && "number" === typeof input.teamId && (null === input.archived || "boolean" === typeof input.archived) && "number" === typeof input.userId && "number" === typeof input.audioprojectId && "number" === typeof input.audiofileId && "string" === typeof input.filenameOriginal && "number" === typeof input.size && "string" === typeof input.type && "string" === typeof input.filename && (null === input.channels || "number" === typeof input.channels) && (null === input.bitrate || "number" === typeof input.bitrate) && (null === input.samplerate || "number" === typeof input.samplerate) && (null === input.samples || "number" === typeof input.samples) && (null === input.lossless || "boolean" === typeof input.lossless);
        const $io1 = (input: any): boolean => "string" === typeof input.createdDate && "string" === typeof input.duration && (undefined === input.userName || "string" === typeof input.userName) && "number" === typeof input.teamId && ("30" === input.knisperAge || "40" === input.knisperAge || "50" === input.knisperAge || "60" === input.knisperAge || "70" === input.knisperAge) && "number" === typeof input.knisperSeparation && "boolean" === typeof input.archived && "number" === typeof input.userId && "number" === typeof input.audioprojectId && "number" === typeof input.audiofileId && "number" === typeof input.size && "number" === typeof input.audioconversionId && "string" === typeof input.knisperState && (null === input.knisperHandle || "string" === typeof input.knisperHandle) && (null === input.resultUri || "string" === typeof input.resultUri);
        return "object" === typeof input && null !== input && $io0(input);
    };
    if (false === __is(input)) {
        const $report = (typia.createValidate as any).report(errors);
        ((input: any, _path: string, _exceptionable: boolean = true): input is IAudioFileRequest => {
            const $vo0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ["string" === typeof input.modifiedDate || $report(_exceptionable, {
                    path: _path + ".modifiedDate",
                    expected: "string",
                    value: input.modifiedDate
                }), "string" === typeof input.createdDate || $report(_exceptionable, {
                    path: _path + ".createdDate",
                    expected: "string",
                    value: input.createdDate
                }), "string" === typeof input.duration || $report(_exceptionable, {
                    path: _path + ".duration",
                    expected: "string",
                    value: input.duration
                }), undefined === input.userName || "string" === typeof input.userName || $report(_exceptionable, {
                    path: _path + ".userName",
                    expected: "(string | undefined)",
                    value: input.userName
                }), (Array.isArray(input.conversions) || $report(_exceptionable, {
                    path: _path + ".conversions",
                    expected: "Array<IAudioConversionRequest>",
                    value: input.conversions
                })) && input.conversions.map((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $report(_exceptionable, {
                    path: _path + ".conversions[" + _index1 + "]",
                    expected: "IAudioConversionRequest",
                    value: elem
                })) && $vo1(elem, _path + ".conversions[" + _index1 + "]", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".conversions[" + _index1 + "]",
                    expected: "IAudioConversionRequest",
                    value: elem
                })).every((flag: boolean) => flag) || $report(_exceptionable, {
                    path: _path + ".conversions",
                    expected: "Array<IAudioConversionRequest>",
                    value: input.conversions
                }), "number" === typeof input.teamId || $report(_exceptionable, {
                    path: _path + ".teamId",
                    expected: "number",
                    value: input.teamId
                }), null === input.archived || "boolean" === typeof input.archived || $report(_exceptionable, {
                    path: _path + ".archived",
                    expected: "(boolean | null)",
                    value: input.archived
                }), "number" === typeof input.userId || $report(_exceptionable, {
                    path: _path + ".userId",
                    expected: "number",
                    value: input.userId
                }), "number" === typeof input.audioprojectId || $report(_exceptionable, {
                    path: _path + ".audioprojectId",
                    expected: "number",
                    value: input.audioprojectId
                }), "number" === typeof input.audiofileId || $report(_exceptionable, {
                    path: _path + ".audiofileId",
                    expected: "number",
                    value: input.audiofileId
                }), "string" === typeof input.filenameOriginal || $report(_exceptionable, {
                    path: _path + ".filenameOriginal",
                    expected: "string",
                    value: input.filenameOriginal
                }), "number" === typeof input.size || $report(_exceptionable, {
                    path: _path + ".size",
                    expected: "number",
                    value: input.size
                }), "string" === typeof input.type || $report(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                }), "string" === typeof input.filename || $report(_exceptionable, {
                    path: _path + ".filename",
                    expected: "string",
                    value: input.filename
                }), null === input.channels || "number" === typeof input.channels || $report(_exceptionable, {
                    path: _path + ".channels",
                    expected: "(null | number)",
                    value: input.channels
                }), null === input.bitrate || "number" === typeof input.bitrate || $report(_exceptionable, {
                    path: _path + ".bitrate",
                    expected: "(null | number)",
                    value: input.bitrate
                }), null === input.samplerate || "number" === typeof input.samplerate || $report(_exceptionable, {
                    path: _path + ".samplerate",
                    expected: "(null | number)",
                    value: input.samplerate
                }), null === input.samples || "number" === typeof input.samples || $report(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(null | number)",
                    value: input.samples
                }), null === input.lossless || "boolean" === typeof input.lossless || $report(_exceptionable, {
                    path: _path + ".lossless",
                    expected: "(boolean | null)",
                    value: input.lossless
                })].every((flag: boolean) => flag);
            const $vo1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ["string" === typeof input.createdDate || $report(_exceptionable, {
                    path: _path + ".createdDate",
                    expected: "string",
                    value: input.createdDate
                }), "string" === typeof input.duration || $report(_exceptionable, {
                    path: _path + ".duration",
                    expected: "string",
                    value: input.duration
                }), undefined === input.userName || "string" === typeof input.userName || $report(_exceptionable, {
                    path: _path + ".userName",
                    expected: "(string | undefined)",
                    value: input.userName
                }), "number" === typeof input.teamId || $report(_exceptionable, {
                    path: _path + ".teamId",
                    expected: "number",
                    value: input.teamId
                }), "30" === input.knisperAge || "40" === input.knisperAge || "50" === input.knisperAge || "60" === input.knisperAge || "70" === input.knisperAge || $report(_exceptionable, {
                    path: _path + ".knisperAge",
                    expected: "(\"30\" | \"40\" | \"50\" | \"60\" | \"70\")",
                    value: input.knisperAge
                }), "number" === typeof input.knisperSeparation || $report(_exceptionable, {
                    path: _path + ".knisperSeparation",
                    expected: "number",
                    value: input.knisperSeparation
                }), "boolean" === typeof input.archived || $report(_exceptionable, {
                    path: _path + ".archived",
                    expected: "boolean",
                    value: input.archived
                }), "number" === typeof input.userId || $report(_exceptionable, {
                    path: _path + ".userId",
                    expected: "number",
                    value: input.userId
                }), "number" === typeof input.audioprojectId || $report(_exceptionable, {
                    path: _path + ".audioprojectId",
                    expected: "number",
                    value: input.audioprojectId
                }), "number" === typeof input.audiofileId || $report(_exceptionable, {
                    path: _path + ".audiofileId",
                    expected: "number",
                    value: input.audiofileId
                }), "number" === typeof input.size || $report(_exceptionable, {
                    path: _path + ".size",
                    expected: "number",
                    value: input.size
                }), "number" === typeof input.audioconversionId || $report(_exceptionable, {
                    path: _path + ".audioconversionId",
                    expected: "number",
                    value: input.audioconversionId
                }), "string" === typeof input.knisperState || $report(_exceptionable, {
                    path: _path + ".knisperState",
                    expected: "string",
                    value: input.knisperState
                }), null === input.knisperHandle || "string" === typeof input.knisperHandle || $report(_exceptionable, {
                    path: _path + ".knisperHandle",
                    expected: "(null | string)",
                    value: input.knisperHandle
                }), null === input.resultUri || "string" === typeof input.resultUri || $report(_exceptionable, {
                    path: _path + ".resultUri",
                    expected: "(null | string)",
                    value: input.resultUri
                })].every((flag: boolean) => flag);
            return ("object" === typeof input && null !== input || $report(true, {
                path: _path + "",
                expected: "IAudioFileRequest",
                value: input
            })) && $vo0(input, _path + "", true) || $report(true, {
                path: _path + "",
                expected: "IAudioFileRequest",
                value: input
            });
        })(input, "$input", true);
    }
    const success = 0 === errors.length;
    return {
        success,
        errors,
        data: success ? input : undefined
    } as any;
};
