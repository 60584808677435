import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import { useDispatch } from 'app/hooks';
import { Typography, Button, Input, Progress, IconButton } from '@material-tailwind/react';
import { useState, MouseEvent } from 'react';
import { isValidateErrors } from 'packages/shared/validate/types/validate';
import { FormError } from 'common/components/FormError';
import { useTranslation } from 'react-i18next';
import { IUserResetPassword } from 'packages/shared/validate/types/user';
import { resetPasswordAsync } from 'features/users/slice';
import { zxcvbn } from 'common/zxcvbn';
import { toNumber } from 'packages/shared/utility/convert';
import { colors } from '@material-tailwind/react/types/generic';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export const ResetPasswordForm = () => {

    const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm<IUserResetPassword>({});

	const { t } = useTranslation();

    const dispatch = useDispatch();

	const navigate = useNavigate();

	const { resetToken } = useParams();

	const onSubmit:SubmitHandler<IUserResetPassword> = (data, event) => {
		event?.preventDefault();

		dispatch(resetPasswordAsync({
			...data,
			resetToken
		}))
		.unwrap()
		.then(_ => {
			
        	navigate(`/signin`);

		}).catch(error => {
			if (isValidateErrors(error)) {
				error.map(e => setError(e.name as keyof IUserResetPassword, {
					type: 'api',
					message: t('invalid', { ns: 'form', name: e.name })
				}));

				return;
			}

			setError("root", {
				type: 'api',
				message: error?.message ?? "unknown error"
			});
		});
	};

	const onError:SubmitErrorHandler<IUserResetPassword> = (errors, event) => {
		Object.entries(errors).map(([name, error]) => {

			if (error.type === 'required') {

				setError(name as keyof IUserResetPassword, {
					type: 'required',
					message: t('required', { ns: 'form', name })
				});

				return;
			}
		});
	};

	const [score, setScore] = useState(0);

	const handleChange = (event: MouseEvent<HTMLInputElement>) => {

		clearErrors(event.currentTarget.name as keyof IUserResetPassword);

		setScore(zxcvbn(event.currentTarget.value).score);
	}

	const minPasswordScore = toNumber(import.meta.env.VITE_PASSWORD_SCORE_MIN) ?? 4;

	const passwordScoreErrors = [
		{ message: "Short", color: "gray" },
		{ message: "Weak", color: "red" },
		{ message: "Fair", color: "orange" },
		{ message: "Moderate", color: "amber" },
		{ message: "Good", color: "light-green" },
		{ message: "Strong", color: "green" }
	];

	const [showPassword, setShowPassword] = useState(false);

	const handleViewPassword = (_:MouseEvent<HTMLButtonElement>) => {
		setShowPassword(!showPassword);
	};

    return (
		<form 
			className="m-auto mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"  
			onSubmit={handleSubmit(onSubmit, onError)}
		>
			<div className="mb-1 flex flex-col gap-4">
				<Typography 
					variant="h6" 
					color="blue-gray" 
					className="-mb-3" 
					placeholder={undefined} 
					onPointerEnterCapture={() => {}} 
					onPointerLeaveCapture={() => {}}
				>
					{t("New Password")}
				</Typography>
				<div className="relative flex w-full max-w-[24rem]">
					<Input
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}} 
						type={showPassword ? "text" : "password"}
						size="lg"
						error={!!errors.password?.[0]}
						className="focus:outline-none focus:ring-0"
						label="password"
						crossOrigin={undefined}
						{...register(`password.0`, {
							onChange: handleChange,
							validate: () => {

								const message = passwordScoreErrors?.[score]?.message;

								return score < minPasswordScore
									? t("Password is invalid", { reason: !!message ? `, "${message}"` : ""})
									: true
							}
						})} 
					/>
					<IconButton 
						onClick={handleViewPassword}
						className="!absolute right-1.5 top-1.5 rounded"
						size="sm" 
						placeholder={undefined} 
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}}
					>
					{showPassword 
						? <EyeSlashIcon className="stroke-white w-full h-full" />
						: <EyeIcon className="stroke-white w-full h-full" />
					}
    				</IconButton>
				</div>
				<div>
					<Progress 
						placeholder={undefined} 
						onPointerEnterCapture={undefined} 
						onPointerLeaveCapture={undefined}
						color={passwordScoreErrors?.[score]?.color as colors ?? "gray"}
						value={(100/4)*score}
					/>
				</div>
				<FormError error={errors.root} />

				<Typography 
					variant="h6" 
					color="blue-gray" 
					className="-mb-3" 
					placeholder={undefined} 
					onPointerEnterCapture={()=>{}} 
					onPointerLeaveCapture={()=>{}}
				>
					{t("Confirm Password")}
				</Typography>
				<div className="relative flex w-full max-w-[24rem]">
					<Input
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}} 
						id="reset-password-1"
						type={showPassword ? "text" : "password"}
						size="lg"
						error={!!errors.password?.[1]}
						className="focus:outline-none focus:ring-0"
						label="password"
						crossOrigin={undefined}
						containerProps={{
							className: "min-w-0",
						}}
						{...register(`password.1`, {
							required: t("Can't be empty")
						})} 
					/>
				</div>
				<FormError error={errors.password?.[1]} />	
			</div>
			<FormError error={errors.root} />

			<Button className="mt-6" 
				type="submit" 
				fullWidth 
				placeholder={undefined} 
				onPointerEnterCapture={()=>{}} 
				onPointerLeaveCapture={()=>{}}
			>
				{t("Submit")}
			</Button>

		</form>
    );
};
