import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-tailwind/react';
import { store } from 'store';
import { App } from 'App';
import './index.scss';

/**
 * Please be aware of this material-tailwind / typescript bug :-(
 * @see https://github.com/creativetimofficial/material-tailwind/issues/651
 * @see https://github.com/creativetimofficial/material-tailwind/issues/350
 * @see https://github.com/creativetimofficial/material-tailwind/issues/528
 */

export const knipserTheme = {
    button: {
        defaultProps: {
            color: "primary",
        },
        valid: {
            colors: [
                /*"white",
                "blue-gray",
                "gray",
                "brown",
                "deep-orange",
                "orange",
                "amber",
                "yellow",
                "lime",
                "light-green",
                "green",
                "teal",
                "cyan",
                "light-blue",
                "blue",
                "indigo",
                "deep-purple",
                "purple",
                "pink",
                "red",*/
                //
                "primary"
            ]
        },
        styles: {
            variants: {
                filled: {
                    primary: {
                        backgroud: "bg-curious-blue-600",
                        color: "text-white",
                        shadow: "shadow-md shadow-curious-blue-600/20",
                        hover: "hover:shadow-lg hover:shadow-curious-blue-600/40 hover:text-deep-blush-400",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    }
                },
                text: {
                    primary: {
                        color: "text-curious-blue-600",
                        hover: "hover:bg-curious-blue-600/10",
                        active: "active:bg-curious-blue-600/30",
                    },
                },
            }
        }
    },
    iconButton: {
        defaultProps: {
            color: "primary",
        },
        valid: {
            colors: ["primary"]
        },
        styles: {
            variants: {
                filled: {
                    primary: {
                        backgroud: "bg-curious-blue-600",
                        color: "text-white",
                        shadow: "shadow-md shadow-curious-blue-600/20",
                        hover: "hover:shadow-lg hover:shadow-curious-blue-600/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                },
                text: {
                    primary: {
                        color: "text-curious-blue-600",
                        hover: "hover:bg-curious-blue-600/10",
                        active: "active:bg-curious-blue-600/30",
                    },
                }
            }
        }
    },
    switch: {
        defaultProps: {
            className: "h-full w-full left-0 !bg-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent",
            color: "primary",
        },
        valid: {
            colors: ["primary"]
        },
        styles: {
            colors: {
                primary: {
                    input: "bg-curious-blue-200 hover:bg-cusious-blue-100 focus:bg-curious-blue-200/50 checked:bg-deep-blush-500 hover:checked:bg-deep-blush-300 focus:checked:bg-deep-blush-300/50",
                    circle: "peer-checked:border-deep-blush-500",
                    before: "peer-checked:before:border-deep-blush-500 border-none",
                },
            }
        }
    },
    list: {
        defaultProps: {
            ripple: true,
            className: "font-customRegular",
        },
        styles: {
            base: {
                list: {
                    display: "flex",
                    flexDirection: "flex-col",
                    gap: "gap-1",
                    minWidth: "min-w-[240px]",
                    p: "p-2",
                    fontSize: "text-base",
                    fontWeight: "font-normal",
                    color: "text-curious-blue-700",
                },
                item: {
                    initial: {
                        borderRadius: "rounded-lg",
                        textAlign: "text-start",
                        lightHeight: "leading-tight",
                        transition: "transition-all",
                        bg: "hover:bg-curious-blue-200/20 hover:bg-opacity-80 focus:bg-curious-blue-200/20 focus:bg-opacity-80 active:bg-curious-blue-200/20 active:bg-opacity-80",
                        color: "hover:text-deep-blush-400 focus:text-deep-blush-500 active:text-deep-blush-900",
                        outline: "outline-none"
                    },
                    selected: {
                        bg: "bg-curious-blue-200/40",
                        color: "text-deep-blush-400",
                    }
                }
            },
        },
    },
    navbar: {
        styles: {
            base: {
                navbar: {
                    initial: {
                        display: "block",
                        width: "w-full",
                        maxWidth: "max-w-screen-2xl",
                        borderRadius: "rounded-xl",
                        py: "py-4",
                        px: "px-8",
                    },
                    shadow: {
                        boxShadow: "shadow-md shadow-deep-blush-200/25",
                    },
                }
            }
        }
    },
    card: {
        defaultProps: {
            variant: "filled",
            color: "white",
            shadow: true,
            className: "shadow-deep-blush-200/25",
        },
    }
};

ReactDOM
    .createRoot(document.getElementById("root")!)
    .render(
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider value={knipserTheme}>
                    <App />
                </ThemeProvider>
            </Provider>
        </React.StrictMode>,
    );
