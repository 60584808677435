import { useSelector } from "app/hooks";
import { DateTime } from "luxon";

interface IDateTimeLocalize {
    dateTime: DateTime;
    format: string;
};

export const DateTimeLocalize = ({dateTime, format}:IDateTimeLocalize) => {
    
    const locale = useSelector(store => store.app.locale);

    const timeZone = useSelector(store => store.authenticate.timezone);

    return (
        <>
            {dateTime.setLocale(locale).setZone(timeZone).toFormat(format)}
        </>
    )
};
