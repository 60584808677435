import { Fragment, MouseEvent, RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "app/hooks";
import { fetchAudioProjectFilesAsync, selectProjectFiles, downloadProjectConversionsAsync, downloadFile, IAudioFileUploadArgs, downloadAudioConversionAsync, selectUploading, patchAudioFileAsync, clearUploadErrors } from "features/files/slice";
import { randomId } from "common/random";
import { IAudioFileRequest, isAudioFileRequest, isAudioFileUpload } from "packages/shared/validate/request/files/audiofile";
import { IconButton, Tooltip, Typography,  Button, Progress } from "@material-tailwind/react";
import { ArchiveBoxIcon, ArrowDownTrayIcon, ArrowPathIcon, ArrowUpTrayIcon, FolderIcon, FolderOpenIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DropEvent, FileRejection } from "react-dropzone";
import { toNumber } from "packages/shared/utility/convert";
import { AudioProjectDropZone, IAudioProjectDropZoneRef } from "./AudioFileDropZone";
import { DateTime } from "luxon";
import { IAudioConversionRequest } from "packages/shared/validate/request/files/audioconversion";
import { filesize, partial } from "filesize";
import { IOrderBy } from "common/redux/orderby";
import { shallowEqual } from "react-redux";
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { IGenericDialogRef } from "common/components/GenericDialog";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useTranslation } from "react-i18next";
import { DateTimeLocalize } from "features/users/authenticate/components/DateTimeLocalize";
import { v4 as uuidv4 } from 'uuid';

export interface IAudioProjectTableRowComponent {
	audioprojectId: number;
	orderBy: IOrderBy;
	archiveFileRef: RefObject<IGenericDialogRef<IAudioFileRequest>>;
	includeArchived: boolean;
	onUpload?: (args:IAudioFileUploadArgs) => void;
	onArchive?: (event:MouseEvent) => void;
	onEdit?: (event:MouseEvent) => void;
};

export const AudioProjectTableRowComponent = (props:IAudioProjectTableRowComponent) => {
	
	const dispatch = useDispatch();

	const authenticatedUser = useSelector(store => store.authenticate);

	const project = useSelector(store => store.files.projects.filter(p => p.audioprojectId === props.audioprojectId)[0])

    const isAdmin = useSelector(state => state.authenticate.roles?.includes(import.meta.env.VITE_ADMIN_ROLE));

	useEffect(() => {
		
		const action = dispatch(fetchAudioProjectFilesAsync({
			audioprojectId: props.audioprojectId
		}));
		
		return () => {

			action.abort();
		}
	}, []);

	const projectFiles = useSelector(state => selectProjectFiles(state, toNumber(props.audioprojectId), props.orderBy, props.includeArchived), shallowEqual);

	const onDropHandle = <T extends File>(
		files:T[], 
		_:FileRejection[], 
		__:DropEvent
	) => {

		dispatch(clearUploadErrors());

		const batchId = uuidv4();

		files.forEach(file => {
			
			const uploadId = randomId();

			const {userId, name:userName} = authenticatedUser;

			props.onUpload?.({
				uploadId, 
				file, 
				audioprojectId: props.audioprojectId, 
				userId, 
				userName,
				batchId
			});
		});
	};

	const uploadClick = (event:MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		dropZoneRef.current?.openSelect();
	};

	const downloadAllClick = (event:MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		dispatch(downloadProjectConversionsAsync({
			audioprojectId: props.audioprojectId
		}))
		.unwrap()
		.then(response => {
			downloadFile(response);
		})
		.catch(console.error);
	};

	const downloadConversionClick = (
		event:MouseEvent<HTMLButtonElement>, 
		conversion:IAudioConversionRequest
	) => {
        event.preventDefault();

        dispatch(downloadAudioConversionAsync(conversion))
            .unwrap()
            .then(response => { 
                downloadFile(response);
            })
            .catch(console.error);
    };

	const archiveProjectHandle = (event:MouseEvent<HTMLButtonElement>) => {
		props.onArchive?.(event);
	};

	const dropZoneRef = useRef<IAudioProjectDropZoneRef>();

	const locale = useSelector(store => store.app.locale);

	const downloading = useSelector(store => store.files.downloading);

	const [downloadable, setDownloadable] = useState(false);

	useEffect(() => {

		const conversionsPrepared = projectFiles.reduce<IAudioConversionRequest[]>((result, file) => {

			if (isAudioFileRequest(file)) {
				result = result.concat(file.conversions.filter(c => c.knisperState === 'prepared'));
			}

			return result;

		}, []);

		setDownloadable(conversionsPrepared.length > 0);

	}, [projectFiles]);

	const { setNodeRef } = useDroppable({
		id: `droppable-audioproject-${props.audioprojectId}`,
		data: {
			audioprojectId: props.audioprojectId
		}
	});

	const { t } = useTranslation();

	const uploading = useSelector(state => selectUploading(state, props.audioprojectId));

	const isAdminOwnTeam = authenticatedUser.roles?.includes("admin") && project.teamId == authenticatedUser.teamId;

	return (
		<tbody ref={setNodeRef}>
			<tr className={`${project.archived ? 'bg-curious-blue-100/50' : ''}`}>
				<td colSpan={isAdmin ? 5 : 4} className="border-y border-blue-gray-100 bg-curious-blue-50/25 p-4">
					<AudioProjectDropZone 
						ref={dropZoneRef}
						onDrop={onDropHandle}
					>
						<div className="flex">
							<div className="flex flex-row grow justify-start space-x-1 px-1 items-center">
								{project.archived 
									? <FolderIcon className="inline-block h-[24px] w-[24px]" />
									: <FolderOpenIcon className="inline-block h-[24px] w-[24px]" />
								}
								<div className="inline-block px-2 font-sans text-sm leading-normal text-blue-gray-900">
									<span className={isAdminOwnTeam ? "font-bold" : "font-normal"}>{project.name}</span>
								</div>
							</div>
							<div className="flex flex-row justify-end space-x-2 px-2">
								<Tooltip
									content={
										<div className="w-50">
											<Typography 
												color="white" 
												className="font-medium" 
												placeholder={undefined} 
												onPointerEnterCapture={()=>{}} 
												onPointerLeaveCapture={()=>{}}
											>
												{t("Upload")}
											</Typography>
											<Typography
												variant="small"
												color="white"
												className="font-normal opacity-80" 
												placeholder={undefined} 
												onPointerEnterCapture={()=>{}} 
												onPointerLeaveCapture={()=>{}}
											>
												{t("Hold shift to select multiple files at once.")}<br />
												<strong>{t("Tip:")}</strong>{t("You can also drag & drop files onto a project")}
											</Typography>
										</div>
									}
								>
									<IconButton 
										onClick={uploadClick}
										size="sm"
										disabled={project.archived} 
										placeholder={undefined} 
										onPointerEnterCapture={()=>{}} 
										onPointerLeaveCapture={()=>{}}
									>
										<ArrowUpTrayIcon className="stroke-white stroke-2 w-full h-full" />
									</IconButton>
								</Tooltip>
								<Tooltip
									content={
										<div className="w-50">
											<Typography 
												color="white" 
												className="font-medium" 
												placeholder={undefined} 
												onPointerEnterCapture={()=>{}} 
												onPointerLeaveCapture={()=>{}}
											>
												{t("Download all")}
											</Typography>
											<Typography
												variant="small"
												color="white"
												className="font-normal opacity-80"
												placeholder={undefined} 
												onPointerEnterCapture={()=>{}} 
												onPointerLeaveCapture={()=>{}}
											>
												{t("Download all converted files as zip.")}
											</Typography>
										</div>
									}
								>
									<IconButton 
										onClick={downloadAllClick}
										size="sm"
										disabled={uploading.length > 0 || !downloadable || downloading.find(x => x.id === `project-${props.audioprojectId}`) !== undefined} 
										placeholder={undefined} 
										onPointerEnterCapture={()=>{}} 
										onPointerLeaveCapture={()=>{}}
									>
										<ArrowDownTrayIcon className="stroke-white w-full h-full" />
									</IconButton>
								</Tooltip>
								<Tooltip
									content={isAdmin 
											? <>{project.archived 
												? t("Unarchive") 
												: t("Archive")
											}</> 
											: <div className="w-50">
												<Typography 
													color="white" 
													className="font-medium" 
													placeholder={undefined} 
													onPointerEnterCapture={()=>{}} 
													onPointerLeaveCapture={()=>{}}
												>
													{t("Delete")}
												</Typography>
												<Typography
													variant="small"
													color="white"
													className="font-normal opacity-80" 
													placeholder={undefined} 
													onPointerEnterCapture={()=>{}} 
													onPointerLeaveCapture={()=>{}}
												>
													{t("This action can't be undone!")}
												</Typography>
											</div>
									}
								>
									<IconButton
										onClick={archiveProjectHandle}
										size="sm"
										disabled={!isAdmin && project.archived} 
										placeholder={undefined} 
										onPointerEnterCapture={()=>{}} 
										onPointerLeaveCapture={()=>{}}
									>
										<TrashIcon className="stroke-white w-full h-full" />
									</IconButton>
								</Tooltip>
								<Tooltip
									content={
										<div className="w-50">
											<Typography 
												color="white" 
												className="font-medium" 
												placeholder={undefined} 
												onPointerEnterCapture={()=>{}} 
												onPointerLeaveCapture={()=>{}}
											>
												{t("Rename")}
											</Typography>
										</div>
									}
								>
									<IconButton
										onClick={props.onEdit}
										size="sm"
										disabled={project.archived} placeholder={undefined} onPointerEnterCapture={()=>{}} onPointerLeaveCapture={()=>{}}
									>
										<PencilSquareIcon className="stroke-white w-full h-full" />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</AudioProjectDropZone>
				</td>
			</tr>
			{projectFiles.map((file, index) => {

				if (isAudioFileRequest(file)) {
					return (
						<Fragment key={`audiofile-${index}`}>
							<AudioFileComponent 
								file={file} 
								onDownload={downloadConversionClick} 
								archiveFileRef={props.archiveFileRef}
							/>
						</Fragment>
					);
				}

				if (isAudioFileUpload(file)) {

					const toHumanSizeExponent = partial({ 
						standard: "jedec", 
						locale,
						exponent: filesize(file.size, {
							standard: "jedec", 
							output: "exponent"
						}),
						output: "string"
					});

					return (
						<tr key={`audioupload-${index}`}>
							<td className="p-4 border-b border-blue-gray-50">
								<Typography
									variant="small"
									color="blue-gray"
									className="font-normal opacity-70 truncate" 
									placeholder={undefined} 
									onPointerEnterCapture={undefined} 
									onPointerLeaveCapture={undefined}
								>
									{file.filenameOriginal}
								</Typography>
							</td>
							<td className="p-4 border-b border-blue-gray-50">
								<Typography
									variant="small"
									color="blue-gray"
									className="font-normal opacity-70 truncate" 
									placeholder={undefined} 
									onPointerEnterCapture={undefined} 
									onPointerLeaveCapture={undefined}
								>
								{authenticatedUser.name}
								</Typography>
							</td>
							<td className="p-4 border-b border-blue-gray-50">
								<Typography
									variant="small"
									color="blue-gray"
									className="font-normal opacity-70 truncate" 
									placeholder={undefined} 
									onPointerEnterCapture={undefined} 
									onPointerLeaveCapture={undefined}
								>
									<span>
										<DateTimeLocalize 
											dateTime={DateTime.fromISO(file.createdDate)} 
											format={"dd-MM-yyyy HH:mm:ss"}
										/>
									</span>
								</Typography>
							</td>
							<td className="p-4 border-b border-blue-gray-50" colSpan={isAdmin ? 2 : 1}>
								<Progress 
									value={file.progress} 
									size="sm" 
									placeholder={undefined} 
									onPointerEnterCapture={()=>{}} 
									onPointerLeaveCapture={()=>{}}
								/>
								<Typography
									variant="small"
									color="blue-gray"
									className="font-normal opacity-70 truncate" 
									placeholder={undefined} 
									onPointerEnterCapture={undefined} 
									onPointerLeaveCapture={undefined}
								>
									<span className="px-1">{toHumanSizeExponent(file.loaded)}</span>/
                    				<span className="px-1">{toHumanSizeExponent(file.size)}</span>
                    				<span>({Math.round(file.progress)} %)</span>
								</Typography>
							</td>
						</tr>
					);
				}

				return null;
			})}
		</tbody>
	);
};

export interface IDragHandle {
	isDragging: boolean;
	listeners?: SyntheticListenerMap; 
};

export const DragHandle = (props:IDragHandle) => {
	
	return (
		<div className={`h-[22px] w-[12px] ${props.isDragging ?  "cursor-grabbing" : "cursor-grab"}`} {...props.listeners}>
			<svg
				aria-hidden="true"
				focusable="false"
				data-prefix="fas"
				data-icon="grip-vertical"
				role="img"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 320 512"
				className="h-full w-full flex-no-shrink fill-blue-gray-300"
			>
				<path
					d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
		  		>
				</path>
			</svg>
		</div>
	);
};

export interface IAudioFileComponentProps {
	file: IAudioFileRequest;
	archiveFileRef: RefObject<IGenericDialogRef<IAudioFileRequest>>;
	onDownload: (
		event:MouseEvent<HTMLButtonElement>, 
		conversion:IAudioConversionRequest
	) => void;
};

export const AudioFileComponent = (props:IAudioFileComponentProps) => {

	const firstConversion = props.file.conversions?.[0];
	
	const knipserState = firstConversion?.knisperState;

	//const user = useSelector(store => store.authenticate);

	//const locale = useSelector(store => store.app.locale);

	const isAdmin = useSelector(state => state.authenticate.roles?.includes(import.meta.env.VITE_ADMIN_ROLE));

	const dispatch = useDispatch();

	const archiveFileHandle = (event:MouseEvent<HTMLButtonElement>) => {

		event.preventDefault();

		if (isAdmin) {

			dispatch(patchAudioFileAsync({
				audiofileId: props.file.audiofileId,
				archived: !props.file.archived
			}));

			return;
		}

		props.archiveFileRef.current?.initialize(t('Archive'), props.file);
	};

	const downloading = useSelector(store => store.files.downloading);

	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: `drag-audiofile-${props.file.audiofileId}`,
		data: props.file
	});

	const style = {
		transform: CSS.Translate.toString(transform)
	};

	const { t } = useTranslation();

	return (
		<tr ref={setNodeRef} style={style} {...attributes} className="bg-white select-none">
			<td className="p-4 border-b border-blue-gray-50">
				<div className="overflow-hidden flex">
					<div className="px-2">
						<DragHandle isDragging={false} listeners={listeners}/>
					</div>
					<Tooltip
						content={props.file.filenameOriginal}
					>
						<Typography
							variant="small"
							color="blue-gray"
							className="font-normal opacity-70 truncate" 
							placeholder={undefined} 
							onPointerEnterCapture={undefined} 
							onPointerLeaveCapture={undefined}
						>
							{props.file.filenameOriginal}
						</Typography>
					</Tooltip>
				</div>
			</td>
			{isAdmin && (
				<td className="p-4 border-b border-blue-gray-50">
				{props.file.archived && (
					<ArchiveBoxIcon className="w-[20px] h-[20px] -mx-2" />
				)}
				</td>
			)}
			<td className="p-4 border-b border-blue-gray-50">
				<Typography
					variant="small"
					color="blue-gray"
					className="font-normal opacity-70 truncate" 
					placeholder={undefined} 
					onPointerEnterCapture={undefined} 
					onPointerLeaveCapture={undefined}
				>
					<span>{props.file.userName}</span>
				</Typography>
			</td>
			<td className="p-4 border-b border-blue-gray-50">
				<Typography
					variant="small"
					color="blue-gray"
					className="font-normal opacity-70 truncate" 
					placeholder={undefined} 
					onPointerEnterCapture={undefined} 
					onPointerLeaveCapture={undefined}
				>
					<span>
						<DateTimeLocalize 
                            dateTime={DateTime.fromISO(props.file.createdDate)} 
                            format={"dd-MM-yyyy HH:mm:ss"}
                        />
					</span>
				</Typography>
			</td>
			<td className="p-4 border-b border-blue-gray-50">
				<div className="flex flex-row justify-end space-x-2">
					{knipserState === 'prepare' 
						? (
							<Button
								size="sm"
								color="purple"
								className="flex items-center gap-3 cursor-default"
								placeholder={undefined} 
								onPointerEnterCapture={()=>{}} 
								onPointerLeaveCapture={()=>{}}
								ripple={false}
							>
								<ArrowPathIcon className="animate-spin h-4 w-4" />
								{t("Processing")}
							</Button>
						) 
						: knipserState === 'prepared' 
						? (
							<Button
								size="sm"
								color="green"
								className="flex items-center gap-3"
								placeholder={undefined}
								onPointerEnterCapture={()=>{}} 
								onPointerLeaveCapture={()=>{}}
								onClick={e => props.onDownload(e, firstConversion)}
								disabled={downloading.find(x => x.id === `conversion-${firstConversion.audioconversionId}`) !== undefined}
							>
								<ArrowDownTrayIcon className="h-4 w-4" />
								{t("Download")}
							</Button>
						)
						: null
					}
					<IconButton
						onClick={archiveFileHandle}
						size="sm"
						disabled={false} 
						placeholder={undefined} 
						onPointerEnterCapture={()=>{}} 
						onPointerLeaveCapture={()=>{}}
						className="flex-shrink-0"
					>
						<TrashIcon className="stroke-white w-full h-full" />
					</IconButton>
				</div>
			</td>
		</tr>
	);
};

export default AudioProjectTableRowComponent;
