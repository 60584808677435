import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BroadcastChannelNames, StoreState } from '../store';
import usTranslation from '../../static/localization/us/translation.json';
import usFormTranslation from '../../static/localization/us/form.json';
import { broadcastEvents } from 'common/event/broadcast';
import { IOrderBy, orderByKey } from 'common/redux/orderby';

const locales = ["us"] as const;

export type Locales = typeof locales[number];

export const isLocale = (value:unknown): value is Locales => 
    typeof value === "string" && locales.includes(value as Locales);

interface AppState {
    locale: string;
};

const defaultState:AppState = {
    locale: "us" // @todo env
};

export const navigatorLocale = () : string => {
    const languages = new LanguageDetector().detect(['navigator']);

    const language = Array.isArray(languages)
        ? languages[0]
        : languages
        ?? 'zz';

    return language.replace(/-[^-]+$/, '');
};

const slice = createSlice({
    name: "app",
    initialState: (): AppState => {

        if (i18next.isInitialized) {
            return {
                ...defaultState, 
                ...{
                    locale: i18next.language
                }
            };            
        }

        i18next
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
                debug: false,
                initImmediate: false,
                fallbackLng: "us",
                defaultNS: "translation",
                supportedLngs: locales,
                keySeparator: false,
                interpolation: {
                    escapeValue: false,
                },
                resources: {
                    us: { 
                        translation: usTranslation,
                        form: usFormTranslation
                    }
                },
                detection: {
                    order: ['localStorage', 'navigator'],
                    lookupLocalStorage: 'locale',
                    caches: ['localStorage'],
                }
            });

        return {
            ...defaultState, 
            ...{
                locale: i18next.language
            }
        };
    },
    reducers: {
        setLocale: (state, action: PayloadAction<string>) => {

            if (state?.locale === action.payload) {
                return;
            }

            if (!isLocale(action.payload)) {
                // @todo error handling
                console.error('Invalid locale ' + action.payload)
                return; 
            }

            i18next.changeLanguage(action.payload, () => {
                state.locale = i18next.language;

                broadcastEvents.dispatchEvent(
                    new MessageEvent(
                        BroadcastChannelNames.Locale, 
                        { 
                            data: action.payload
                        }
                    )
                );
            });
        },
    },
});

export const limitSelector = (state: StoreState, limit:number) => limit;

export const offsetSelector = (state: StoreState, limit:number, offset:number) => offset; 

export const orderSelector = (state: StoreState, limit:number, offset:number, orderBy:IOrderBy) => orderBy;

export const archivedSelector = (state: StoreState, limit:number, offset:number, orderBy:IOrderBy, includeArchived:boolean) => includeArchived;

export const countArchivedSelector = (state: StoreState, includeArchived: boolean) => includeArchived

export const countHandle = <T extends { archived: boolean }>(data:T[], includeArchived: boolean):number => {

    if (data === undefined || data.length === 0) {
        return 0;
    }

    if (!includeArchived) {
        data = data.filter(x => x.archived === false);
    }

    return data.length;
};

export const limitOffsetOrderHandle = <
    T extends { archived: boolean }
>(data:T[], limit:number, offset:number, orderBy:IOrderBy, includeArchived: boolean) => {
        
    if (data === undefined) {
        return [];
    }

    const length = data.length;
    if (data.length === 0) {
        return [];
    }

    const start = Math.min(length - 1, offset);
    const end   = Math.min(length, offset + limit);

    if (!includeArchived) {
        data = data.filter(x => x.archived === false);
    }

    return data
        .slice(start, end)
        .sort((current, next) => orderByKey(
            current, 
            next, 
            orderBy.column as keyof typeof current, 
            orderBy.direction === 'desc'
        ))
};

export const { setLocale } = slice.actions;

export const { reducer } = slice;

export { i18next };
