import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Hero, HeroTitle } from "./Hero";

export const NotFound = () => {

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <Hero>
            <HeroTitle>{t("Not Found!")}</HeroTitle>
            
            <div>{location.pathname}</div>

        </Hero>
    );
};

export default NotFound;
