import { ReactElement, ReactNode, isValidElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'app/hooks';
import { IsFunction } from 'common/type/guards';
import { selectIsAuthenticated } from '../slice';

interface ProtectedRouteProps {
    deny: ReactNode,
    allow?:((roles?:string[]) => ReactElement)|ReactElement|string[]|string;
};

export const ProtectedRoute = (props:ProtectedRouteProps) => {

	const isAuthenticated = useSelector((store) => selectIsAuthenticated(store));

    const userRoles = useSelector(store => store.authenticate.roles);

    const location = useLocation();

    if (!isAuthenticated) {
        return props.deny;
    }

    if (props.allow === undefined) {
        return <Outlet />;
    }

    if (isValidElement<any>(props.allow)) {
        return props.allow;
    }

    if (IsFunction(props.allow)) {
        return props.allow(userRoles);
    }

    const allowedRoles = Array.isArray(props.allow) 
        ? props.allow 
        : [props.allow];

    if (allowedRoles.some(x => (userRoles as string[]|undefined)?.includes(x))) {
        return <Outlet />;
    }

    return <Navigate to="/" replace state={{ from: location }} />;
};

export default ProtectedRoute;
