import { IsFunction, IsObject } from '../type/guards';
import { OmitCallSignature } from '../type/types';

const IsEventListenerObject = (input: unknown): input is EventListenerObject => 
    IsObject(input) && IsFunction(input?.handleEvent);

interface IBroadcastMessage<T = any> {
    type: string,
    data: T
};

class BroadcastChannelEvent {

    private _channel:BroadcastChannel;

    private _listeners = new Map<EventListener|EventListenerObject, boolean|AddEventListenerOptions|undefined>();

    get name(): string {
        return this._channel.name;
    }

    constructor (name:string) {
        this._channel = new BroadcastChannel(name);

        this._channel.addEventListener("message", (event:MessageEvent<IBroadcastMessage>) => {

            this._listeners.forEach((_, listener) => {

                if (IsEventListenerObject(listener)) {
                    listener.handleEvent(
                        new MessageEvent(
                            event.data.type, 
                            { 
                                data: event.data.data 
                            }
                        )
                    );
                }
                else if (IsFunction(listener)) {
                    listener.call(
                        this, 
                        new MessageEvent(
                            event.data.type, 
                            { 
                                data: event.data.data
                            }
                        )
                    );
                }
            });
        });

        this._channel.addEventListener("messageerror", (event) => {
            throw new Error(`Broadcast failed for ${event.type}`);
        });
    }

    public addEventListener(listener: EventListener|EventListenerObject|null, options?: boolean|AddEventListenerOptions) {
        if(listener === null) {
            return;
        }
        
        if (this._listeners.has(listener)) {
            throw new Error("Listener already registered");
        }

        this._listeners.set(listener, options);
    }

    public removeEventListener(listener: EventListener|EventListenerObject|null, _?: boolean|EventListenerOptions) {
        if(listener === null) {
            return;
        }

        this._listeners.delete(listener);
    }    
    
    public dispatchEvent(event: Event): boolean {

        // we cannot send DOM related objects outside document
        this._channel.postMessage({
            type: event.type,
            data: (event instanceof MessageEvent) 
                ? event.data
                : (event instanceof CustomEvent) 
                    ? event.detail
                    : undefined 
        });

        return true;
    }

    public destroy() {
        this._channel.close();
    }
};

export interface MessageEventListener<T = any> extends OmitCallSignature<EventListener> {
    (event: MessageEvent<T>): void;
};

export interface MessageEventListenerObject<T = any> extends EventListenerObject {
    handleEvent(event: MessageEvent<T>): void;
};

export class BroadcastEventTarget implements EventTarget {

    private _channels = new Map<string, BroadcastChannelEvent>();

    constructor() {}

    private getChannel(name:string): BroadcastChannelEvent|undefined {

        if (!this._channels.has(name)) {
            this._channels.set(name, new BroadcastChannelEvent(name));
        }

        return this._channels.get(name);
    }

    public addEventListener<T = any>(type: string, callback: MessageEventListener<T> | MessageEventListenerObject<T> | null, options?: boolean | AddEventListenerOptions | undefined) {
        this.getChannel(type)
            ?.addEventListener(callback as EventListener, options);
    }

    public removeEventListener<T = any>(type: string, callback: MessageEventListener<T> | MessageEventListenerObject<T> | null, options?: boolean | EventListenerOptions | undefined) {
        this.getChannel(type)
            ?.removeEventListener(callback as EventListener, options);
    }

    public dispatchEvent(event: Event): boolean {
        return this.getChannel(event.type)
            ?.dispatchEvent(event) 
            ?? false;
    }
};

export const broadcastEvents = new BroadcastEventTarget();
