import { ComponentProps } from "react";

export interface HeroTitleProps extends ComponentProps<"h1"> {}

export const HeroTitle = (props:HeroTitleProps) => {

    return (
        <h1 className="text-4xl font-bold tracking-tight text-curious-blue-600 sm:text-6xl font-customBold">
            {props.children}
        </h1>
    );
};

export interface HeroCaptionProps extends ComponentProps<"p"> {}

export const HeroCaption = (props:HeroCaptionProps) => {

    return (
        <p className="mt-4 text-lg leading-8 text-gray-600">
            {props.children}
        </p>
    );
};

export interface HeroProps extends ComponentProps<"div"> {}

export const Hero = (props:HeroProps) => {

    return (
        <div className="relative isolate px-6 lg:px-8">
            <div className="mx-auto py-24 max-w-3xl 2xl:max-w-4xl">
                <div className="text-center">
                    {props.children}
                </div>
            </div>
        </div>
    );
};
